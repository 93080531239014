var render = function render(){var _vm=this,_c=_vm._self._c;return _c('masked-input',{directives:[{name:"focus",rawName:"v-focus",value:(_vm.isAutoSelect),expression:"isAutoSelect"}],staticClass:"form-control custom-input",class:[
    _vm.position === 'right'
      ? 'text-right'
      : _vm.position === 'center'
      ? 'text-center'
      : '',
    _vm.isShowValidation ? 'custom-input-danger' : ''
  ],attrs:{"type":"text","value":_vm.numberValue,"mask":_vm.inputMask,"guide":false,"placeholderChar":"#"},on:{"input":_vm.input},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.pressEnter.apply(null, arguments)},"change":function($event){return _vm.change.apply(null, arguments)},"focus":function($event){return _vm.focus.apply(null, arguments)},"blur":function($event){return _vm.blur.apply(null, arguments)}}})
}
var staticRenderFns = []

export { render, staticRenderFns }