function getInvoices(params) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "get",
      `shops/${this._api.$store.state.activeShop}/invoices`,
      params,
      (response) => {
        resolve(response.body);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function getInvoice(id) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "get",
      `invoices/${id}`,
      { sort: "id ASC" },
      (response) => {
        resolve(response.body);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function getInvoiceNumber() {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "get",
      `shops/${this._api.$store.state.activeShop}/invoices/number`,
      {},
      (response) => {
        resolve(response.body);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function createInvoice(params) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "post",
      `shops/${this._api.$store.state.activeShop}/invoices`,
      { ...params, utc: -new Date().getTimezoneOffset() },
      (response) => {
        resolve(response.body);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function updateInvoice(id, params) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "patch",
      `invoices/${id}`,
      { ...params },
      (response) => {
        resolve(response.body);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function deleteInvoice(id) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "delete",
      `invoices/${id}`,
      {},
      (response) => {
        resolve(response.body);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function addInvoicePosition(docId, newPos) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "post",
      `invoices/${docId}/invoice-histories`,
      newPos,
      (response) => {
        resolve(response.body);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function updateInvoicePosition(docId, posId, newPos) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "patch",
      `invoices/${docId}/invoice-histories/${posId}`,
      newPos,
      (response) => {
        resolve(response.body);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function deleteInvoicePosition(docId, posId) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "delete",
      `invoices/${docId}/invoice-histories/${posId}`,
      {},
      (response) => {
        resolve(response.body);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function bindDocumentPayment(id, params) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "put",
      `invoices/${id}/cash`,
      params,
      (response) => {
        resolve(response.body);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function deleteBindDocumentPayment(id, params) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "delete",
      `invoices/${id}/cash`,
      params,
      (response) => {
        resolve(response.body);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function payInvoice(id, params) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "post",
      `invoices/${id}/cash`,
      params,
      (response) => {
        resolve(response.body);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export {
  getInvoices,
  getInvoice,
  getInvoiceNumber,
  createInvoice,
  updateInvoice,
  deleteInvoice,
  addInvoicePosition,
  updateInvoicePosition,
  deleteInvoicePosition,
  bindDocumentPayment,
  deleteBindDocumentPayment,
  payInvoice
}