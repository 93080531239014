function getApiItems() {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "get",
      "company/api",
      {},
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
function getTemplateApi() {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "get",
      "company/api/template",
      {},
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
function getApi(apiId) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "get",
      `company/api/${apiId}`,
      {},
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
function createApi(params) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "post",
      `company/api`,
      params,
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
function updateApi(apiId, params) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "patch",
      `company/api/${apiId}`,
      params,
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
function deleteApi(apiId) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "delete",
      `company/api/${apiId}`,
      {},
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
function resetApiPassword(apiId) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "put",
      `company/api/${apiId}/password`,
      {},
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
export { getApiItems, getTemplateApi, getApi, createApi, updateApi, deleteApi, resetApiPassword };