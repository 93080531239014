<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M9.44812 0.878575C9.27696 0.872423 9.10338 0.891248 8.93175 0.93668C8.47405 1.05783 8.09708 1.35394 7.86823 1.75778L7.38367 2.63278C7.27598 2.82124 7.34323 3.07722 7.53169 3.18492L9.86064 4.53107C9.92795 4.57145 9.99526 4.5847 10.0626 4.5847C10.103 4.5847 10.1297 4.58476 10.1701 4.5713C10.2778 4.54437 10.3587 4.47701 10.4125 4.38278L10.9105 3.50778C11.1393 3.10394 11.2067 2.63299 11.0856 2.1753C10.9644 1.71761 10.6683 1.34063 10.2645 1.11179C10.0121 0.968757 9.73339 0.888829 9.44812 0.878575ZM6.85835 3.9019C6.72794 3.91189 6.60279 3.98235 6.53549 4.10014L2.47021 11.154C2.01252 11.9348 1.75685 12.8099 1.703 13.6983L1.59521 15.4349C1.58175 15.583 1.66252 15.7309 1.79713 15.8117C1.86444 15.8521 1.93175 15.8656 1.99905 15.8656C2.07982 15.8656 2.16049 15.8387 2.22779 15.7983L3.68175 14.8426C4.42213 14.3445 5.05482 13.6848 5.49905 12.904L9.578 5.85014C9.6857 5.66168 9.61818 5.40596 9.42972 5.29827C9.24126 5.19058 8.98554 5.25783 8.87785 5.4463L4.79916 12.5002C4.40877 13.1598 3.87015 13.7387 3.224 14.1695L2.44339 14.6809L2.49703 13.7522C2.53741 12.9849 2.76631 12.231 3.15669 11.5579L7.23564 4.50399C7.34334 4.31553 7.27608 4.05981 7.08762 3.95212C7.01695 3.91173 6.9366 3.89591 6.85835 3.9019ZM5.36444 15.0579C5.13559 15.0579 4.96059 15.2329 4.96059 15.4617C4.96059 15.6906 5.13559 15.8656 5.36444 15.8656H10.7491C10.9779 15.8656 11.1529 15.6906 11.1529 15.4617C11.1529 15.2329 10.9779 15.0579 10.7491 15.0579H5.36444ZM12.7683 15.0579C12.6612 15.0579 12.5585 15.1004 12.4827 15.1762C12.407 15.2519 12.3644 15.3546 12.3644 15.4617C12.3644 15.5688 12.407 15.6716 12.4827 15.7473C12.5585 15.823 12.6612 15.8656 12.7683 15.8656C12.8754 15.8656 12.9781 15.823 13.0538 15.7473C13.1296 15.6716 13.1721 15.5688 13.1721 15.4617C13.1721 15.3546 13.1296 15.2519 13.0538 15.1762C12.9781 15.1004 12.8754 15.0579 12.7683 15.0579Z"
      :class="'icon-' + iconColor"
    />
  </svg>
</template>
<script>
export default {
  name: "IconPen",
  props: {
    iconColor: {
      type: String,
      default: "default"
    }
  }
}
</script>
<style lang="scss" scoped>
.icon-default {
  fill: var(--main-color-60);
}
.icon-primary {
  fill: var(--color-primary);
}
</style>