function setModifiesPrices(params, documentId, isWork) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "put",
      documentId
        ? `documents/${documentId}/positions/prices`
        : isWork
        ? `shops/${this._api.$store.state.activeShop}/works/prices`
        : `shops/${this._api.$store.state.activeShop}/products/prices`,
      params,
      (response) => {
        resolve(response.body);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function updateProducts(params) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "patch",
      `shops/${this._api.$store.state.activeShop}/modifies`,
      params,
      (response) => {
        resolve(response.body);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function deleteModifies(ids) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "delete",
      "modifies",
      {modifyIds: ids},
      (response) => {
        resolve(response.body);
      },
      (error) => {
        reject(error);
      }
    );
  });
}
function restoreModifies(ids) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "put",
      "modifies/restore",
      {modifyIds: ids},
      (response) => {
        resolve(response.body);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function updateProductGroup(id, group) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "patch",
      `nomenclature-groups/${id}`,
      group,
      (response) => {
        resolve(response.body);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function setWorksPrice(params) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "put",
      `shops/${this._api.$store.state.activeShop}/works/price`,
      params,
      (response) => {
        resolve(response.body);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function updateWorks(params) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "patch",
      `shops/${this._api.$store.state.activeShop}/works`,
      params,
      (response) => {
        resolve(response.body);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function deleteWorks(ids) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "delete",
      `shops/${this._api.$store.state.activeShop}/works`,
      {modifyIds: ids},
      (response) => {
        resolve(response.body);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export {
  setModifiesPrices,
  updateProducts,
  deleteModifies,
  restoreModifies,
  updateProductGroup,
  setWorksPrice,
  updateWorks,
  deleteWorks
};
