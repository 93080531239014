function getCustomFields(types = ["order", "counteragent"]) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "get",
      "fields/custom-fields",
      { types },
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
function getCustomField(id) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "get",
      `fields/custom-fields/${id}`,
      {},
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function createShopModifies(modifies) {
  modifies.forEach(mod => mod.isWork = false)
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "put",
      "modifies",
      {
        modifies,
        shopId: this._api.$store.state.activeShop
      },
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function createCounteragents(
  counteragents,
  typeCounteragentId,
  isSkipDuplicate = false
) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "put",
      "counteragents",
      {
        counteragents,
        typeCounteragentId,
        isSkipDuplicate
      },
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function createOrders(orders, cashRegisterId) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "put",
      `https://api.livesklad.com/v2/shops/${this._api.$store.state.activeShop}/orders`,
      {
        orders,
        cashRegisterId
      },
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function createTypeDevices(elements) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "put",
      "type-devices/all",
      { elements },
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function createWorks(modifies) {
  modifies.forEach(mod => mod.isWork = true)
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "put",
      "modifies",
      {
        modifies,
        shopId: this._api.$store.state.activeShop
      },
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function updateShopModifies(modifies, field) {
  // modifies.forEach(mod => {
  //   mod.fullName = mod.name
  //   delete mod.name
  // });
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "patch",
      `shops/${this._api.$store.state.activeShop}/products/update`,
      { modifies, isArticle: field === "article" },
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function updateShopWorks(modifies) {
  // modifies.forEach(mod => {
  //   mod.fullName = mod.name
  //   delete mod.name
  // });
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "patch",
      `shops/${this._api.$store.state.activeShop}/works/update`,
      { modifies },
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

export {
  getCustomFields,
  getCustomField,
  createShopModifies,
  createCounteragents,
  createOrders,
  createTypeDevices,
  createWorks,
  updateShopModifies,
  updateShopWorks
}