<template>
  <div>
    <p
      v-show="showTitle"
      class="input-name pull-left"
      style="margin-right: 20px;line-height:25px;"
    >
      Гарантия
    </p>
    <v-switcher2
      type="small"
      :active="guaranteeType"
      @change="changeType"
      :options="guaranteeTypes"
      btnWidth="35"
      :showActive="alwaysInput || guaranteeType != 0"
    >
      <masked-input
        v-select
        :key="guaranteeType"
        v-show="alwaysInput || guaranteeType != 0"
        slot="middle"
        type="text"
        :disabled="guaranteeType === 0"
        class="form-control custom-input input-guarantee text-right pull-left"
        :value="guarantee"
        @input="changeGuarantee"
        :mask="maskNumber"
        :maxlength="3"
        :guide="false"
        placeholderChar="#"
      >
      </masked-input>
    </v-switcher2>
  </div>
</template>

<script>
import createNumberMask from "text-mask-addons/dist/createNumberMask";
export default {
  name: "v-guarantee",

  props: {
    guaranteeInDay: {
      required: false,
      type: Number,
      default: 0
    },
    guaranteeInMonth: {
      required: false,
      type: Number,
      default: 0
    },
    showTitle: {
      required: false,
      type: Boolean,
      default: true
    },
    alwaysInput: Boolean
  },

  data() {
    return {
      guaranteeType: 0,
      guarantee: (0.0).toString(),
      guaranteeTypes: ["нет", "дн.", "мес."],

      maskNumber: createNumberMask({
        prefix: "",
        thousandsSeparatorSymbol: "",
        allowDecimal: false
      })
    };
  },
  computed: {
    beginGuaranteeType() {
      if (
        this.$props.guaranteeInDay !== 0 &&
        this.$props.guaranteeInDay != null &&
        this.$props.guaranteeInDay != undefined
      ) {
        return 1;
      } else if (
        this.$props.guaranteeInMonth !== 0 &&
        this.$props.guaranteeInMonth != null &&
        this.$props.guaranteeInMonth != undefined
      ) {
        return 2;
      } else {
        return 0;
      }
    },
    beginGuarantee() {
      if (
        this.$props.guaranteeInDay != 0 &&
        this.$props.guaranteeInDay != null &&
        this.$props.guaranteeInDay != undefined
      ) {
        return this.$props.guaranteeInDay.toString();
      } else if (
        this.$props.guaranteeInMonth != 0 &&
        this.$props.guaranteeInMonth != null &&
        this.$props.guaranteeInMonth != undefined
      ) {
        return this.$props.guaranteeInMonth.toString();
      } else {
        return (0.0).toString();
      }
    }
  },
  watch: {
    beginGuaranteeType: function() {
      this.guaranteeType = this.beginGuaranteeType;
    },
    beginGuarantee: function() {
      this.guarantee = this.beginGuarantee;
    }
  },
  methods: {
    string2Number(value) {
      return typeof value == "string"
        ? Number(value.replace(/\s/g, "")) || 0
        : value;
    },
    changeType(value) {
      this.guaranteeType = value;
      this.change();
    },
    changeGuarantee(value) {
      this.guarantee = value;
      this.change();
    },
    change() {
      var guaranteeInDay = 0;
      var guaranteeInMonth = 0;

      if (this.guaranteeType == 1) {
        guaranteeInDay = this.string2Number(this.guarantee);
      } else if (this.guaranteeType == 2) {
        guaranteeInMonth = this.string2Number(this.guarantee);
      } else if (this.guaranteeType == 0) {
        guaranteeInDay = 0;
      }

      this.$emit("update:guaranteeInDay", guaranteeInDay);
      this.$emit("update:guaranteeInMonth", guaranteeInMonth);
      this.$emit("change", guaranteeInDay, guaranteeInMonth);
    }
  },
  created() {
    this.guaranteeType = this.beginGuaranteeType;
    this.guarantee = this.beginGuarantee;
  }
};
</script>

<style lang="scss" scoped>
.input-guarantee,
.custom-input.input-guarantee.form-control {
  width: 60px;
  height: 25px;
  margin-left: 10px;
}
</style>
