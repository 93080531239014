<template>
  <vue-scroll
    ref="vs"
    :ops="ops"
    @handle-scroll="handleScroll"
    @handle-resize="handleResize"
  >
    <slot></slot>
  </vue-scroll>
</template>
<script>
import Vue from "vue";
import vueScroll from "vuescroll";
import _ from "lodash";
Vue.use(vueScroll);
Vue.prototype.$vuescrollConfig = {
  vuescroll: {
    mode: "native",
    // vuescroll's size(height/width) should be a percent(100%)
    // or be a number that is equal to its parentNode's width or
    // height ?
    sizeStrategy: "percent",
    // pullRefresh or pushLoad is only for the slide mode...
    pullRefresh: {
      enable: false,
      tips: {
        deactive: "Pull to Refresh",
        active: "Release to Refresh",
        start: "Refreshing...",
        beforeDeactive: "Refresh Successfully!"
      }
    },
    pushLoad: {
      enable: false,
      tips: {
        deactive: "Push to Load",
        active: "Release to Load",
        start: "Loading...",
        beforeDeactive: "Load Successfully!"
      }
    },
    paging: false,
    zooming: true,
    snapping: {
      enable: false,
      width: 100,
      height: 100
    },
    // some scroller options
    scroller: {
      /** Enable bouncing (content can be slowly moved outside and jumps back after releasing) */
      bouncing: true,
      /** Enable locking to the main axis if user moves only slightly on one of them at start */
      locking: true,
      /** Minimum zoom level */
      minZoom: 0.5,
      /** Maximum zoom level */
      maxZoom: 3,
      /** Multiply or decrease scrolling speed **/
      speedMultiplier: 1,
      /** This configures the amount of change applied to deceleration when reaching boundaries  **/
      penetrationDeceleration: 0.03,
      /** This configures the amount of change applied to acceleration when reaching boundaries  **/
      penetrationAcceleration: 0.08,
      /** Whether call e.preventDefault event when sliding the content or not */
      preventDefault: true
    }
  },
  scrollPanel: {
    // when component mounted.. it will automatically scrolls.
    initialScrollY: false,
    initialScrollX: false,
    // feat: #11
    scrollingX: true,
    scrollingY: true,
    speed: 100,
    easing: undefined,
    padding: false
  },
  //
  rail: {
    background: "#01a99a",
    opacity: 0,
    size: "7px",
    gutterOfSide: "1px"
  },
  bar: {
    showDelay: 500,
    background: "rgba(53, 65, 76, 0.3)",
    keepShow: true,
    opacity: 1,
    hover: false
  }
};
// Note: from 4.6.6, you need to import the .css file
import "vuescroll/dist/vuescroll.css";
export default {
  name: "v-scroll-2",
  props: {
    mode: {
      type: String,
      default: "native"
    },
    scrollingX: {
      type: Boolean,
      default: false
    },
    scrollingY: {
      type: Boolean,
      default: true
    },
    isWatchArea: Boolean,
    topAreaValue: {
      type: Number,
      default: 0.05
    },
    bottomAreaValue: {
      type: Number,
      default: 0.95
    },
    topPosition: Number,
    disabled: Boolean,
    isHideBar: Boolean,
    scrollWidth: {
      type: Number,
      default: 7
    },
    backgroundRail: String,
    backgroundBar: {
      type: String,
      default: "transparent"
    },
    initialScrollY: {
      type: [String, Boolean, Number],
      default: false
    },
    initialScrollX: {
      type: [String, Boolean, Number],
      default: false
    },
    gutterOfSide: {
      type: String,
      default: undefined
    },
    gutterOfEnds: {
      type: String,
      default: undefined
    },
  },
  data() {
    return {
      ops: {
        scrollPanel: {
          initialScrollY: this.$props.initialScrollY,
          initialScrollX: this.$props.initialScrollX,
          scrollingX: this.$props.scrollingX,
          scrollingY: this.$props.scrollingY
        },
        rail: {
          size: this.scrollWidth + 'px',
          background: this.backgroundRail,
          opacity: this.backgroundRail ? 1 : 0,
          gutterOfSide: this.gutterOfSide,
          gutterOfEnds: this.gutterOfEnds
        },
        bar: {
          background: this.backgroundBar
        }
      },
      activeArea: "top",
      position: 0,
      scrollToTop: _.debounce(() => {
        this.$emit("scroll-to-top");
      }, 100),
      scrollToLoad: _.debounce(() => {
        this.$emit("scroll-to-load");
      }, 100),
      scrollToBottom: _.debounce(() => {
        this.$emit("scroll-to-bottom");
      }, 100),
      resize: _.debounce(process => {
        this.$emit("resize", process);
      }, 100)
    };
  },
  watch: {
    topPosition(value) {
      if (value !== undefined && this.position !== value) {
        this.scrollTo({ y: value }, false);
      }
    }
  },
  methods: {
    scrollTo(...params) {
      this.$refs.vs.scrollTo(...params);
    },
    scrollBy(...params) {
      this.$refs.vs.scrollBy(...params);
    },
    scrollIntoView(...params) {
      this.$refs.vs.scrollIntoView(...params, false);
    },
    convertBarSize(barSize) {
      return +barSize.replace(/[^\d.]/, "")
    },
    handleScroll(vertical, horizontal) {
      if (vertical) {
        this.position = vertical.scrollTop;
        this.$emit("scrollTop", vertical.scrollTop);
        if (!this.disabled) {
          if (vertical.directionY === "down" && (vertical.process >= 0.99 || typeof vertical.barSize === "string" && this.convertBarSize(vertical.barSize) > 95)) {
            this.scrollToBottom();
          } else if (
            vertical.directionY === "down" &&
            vertical.process >= 0.4
          ) {
            this.scrollToLoad();
          } else if (vertical.directionY === "up" && vertical.process <= 0.05 || typeof vertical.barSize === "string" && this.convertBarSize(vertical.barSize) > 95) {
            this.scrollToTop();
          }
        }
        if (this.$props.isWatchArea) {
          if (vertical.directionY === "down") {
            if (
              vertical.process > this.bottomAreaValue &&
              this.activeArea !== "bottom"
            ) {
              this.$emit("scroll-area", "bottom", this.activeArea);
              this.activeArea = "bottom";
            } else if (
              vertical.process > this.topAreaValue &&
              this.activeArea === "top"
            ) {
              this.$emit("scroll-area", "middle", this.activeArea);
              this.activeArea = "middle";
            }
          } else {
            if (
              vertical.process < this.topAreaValue &&
              this.activeArea !== "top"
            ) {
              this.$emit("scroll-area", "top", this.activeArea);
              this.activeArea = "top";
            } else if (
              vertical.process < this.bottomAreaValue &&
              this.activeArea === "bottom"
            ) {
              this.$emit("scroll-area", "middle", this.activeArea);
              this.activeArea = "middle";
            }
          }
        }
      }
      if (horizontal) {
        this.$emit("scrollHorizontal", horizontal);
      }
    },
    handleResize(vertical) {
      this.resize(vertical.process);
      if (!this.disabled && isNaN(vertical.process)) {
        this.scrollToLoad();
        this.scrollToBottom();
      }
    }
  },
  created() {
    this.$emit("scrollTop", 0);
  }
};
</script>
