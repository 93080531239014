<template>
  <div class="switcher">
    <slot name="header">
      <p
        class="text-center small-text bold-text"
        style="margin-bottom: 0;"
        :style="placeholderStyle"
      >
        {{ placeholder }}
      </p>
    </slot>
    <div
      class="input-group-btn switcher-container pull-left"
      :style="{ width: this.$props.widths[0] || 'auto' }"
    >
      <button
        class="btn btn-switcher"
        @click="change(0)"
        :class="[' btn-' + color, isFirstActive ? '' : ' btn-inverse', getType]"
        :style="{ width: this.$props.widths[1] || 'auto' }"
        :disabled="disabled"
      >
        <slot name="0">1</slot>
      </button>
      <button
        class="btn btn-switcher"
        @click="change(1)"
        :class="[
          ' btn-' + color,
          isSecondActive ? '' : ' btn-inverse',
          getType
        ]"
        :style="{ width: this.$props.widths[2] || 'auto' }"
        :disabled="disabled"
      >
        <slot name="1">2</slot>
      </button>
      <button
        class="btn btn-switcher"
        @click="change(2)"
        :class="[' btn-' + color, isLastActive ? '' : ' btn-inverse', getType]"
        v-if="count > 2"
        :style="{ width: this.$props.widths[3] || 'auto' }"
        :disabled="disabled"
      >
        <slot name="2">3</slot>
      </button>
    </div>
    <input
      v-if="showInput"
      type="text"
      class="switcher-input form-control custom-input pull-left"
      :value="swInputValue"
      @input="updateValue($event.target.value)"
    />
    <div v-if="showActive" class="switcher-active-label pull-right">
      <slot :name="'active' + active"></slot>
    </div>
  </div>
</template>
<style lang="scss">
.switcher {
  position: relative;
  display: inline-block;
  width: auto;
  .switcher-container {
    width: auto;
  }
  .btn-switcher {
    margin-left: 0;
    height: 30px;
    padding: 2px 12px;
    &:focus,
    &:active {
      outline: 0;
    }
    &:first-of-type {
      border-radius: 4px 0 0 4px;
    }
    &:last-of-type {
      border-radius: 0 4px 4px 0;
    }
    &.btn-small {
      height: 25px;
      padding: 0;
      width: 30px;
      &.pages {
        width: 40px;
      }
    }
  }
  .switcher-placeholder {
    font-size: 9px;
    margin-bottom: 0;
  }
  .switcher-active-label {
    margin-left: 5px;
    line-height: 25px;
  }
  .switcher-input {
    width: 60px;
    font-weight: 600;
    text-align: center;
    height: 25px;
    margin-left: 5px;
  }
}
</style>
<script>
export default {
  name: "switcher2",
  data() {
    return {
      swInputValue: this.value || ""
    };
  },
  props: {
    placeholder: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default: "info"
    },
    options: Array,
    uniq: String,
    name: String,
    count: {
      type: Number,
      default: 2
    },
    active: [Number, Object],
    type: {
      type: String,
      default: "normal"
    },
    showActive: {
      type: Boolean,
      default: false
    },
    value: String,
    showInput: {
      type: Boolean,
      default: false
    },
    widths: {
      type: Array,
      default: function() {
        return ["auto", "auto", "auto", "auto"];
      }
    },
    disabled: Boolean
  },
  computed: {
    isFirstActive() {
      return typeof this.active == "object" && this.options
        ? this.options.indexOf(this.active) === 0
        : this.active === 0;
    },
    isSecondActive() {
      return typeof this.active == "object" && this.options
        ? this.options.indexOf(this.active) === 1
        : this.active === 1;
    },
    isLastActive() {
      return typeof this.active == "object" && this.options
        ? this.options.indexOf(this.active) === 2
        : this.active === 2;
    },
    getType() {
      if (this.$props.type === "small") {
        return "btn-small";
      } else if (this.$props.type === "pages") {
        return "btn-small pages";
      } else {
        return "";
      }
    },
    placeholderStyle() {
      if (this.$props.showInput) {
        var pstyle = {
          textAlign: "left",
          marginLeft: this.$props.showActive ? "40px" : "40px"
        };
      } else {
        pstyle = {
          textAlign: "center"
        };
      }
      return pstyle;
    }
  },
  watch: {
    active: function(newValue) {
      this.$emit("changed", newValue);
    }
  },
  methods: {
    change(newValue) {
      this.$emit("change", this.options ? this.options[newValue] : newValue);
    },
    updateValue(value) {
      this.$emit("input", value.trim());
    }
  },
  mounted() {}
};
</script>
