<template functional>
  <div :id="props.id" :class="props.classes">
    <div
      v-if="props.withSwitcher"
      class="tab-switcher-wrapper"
      :style="{
        marginBottom: !props.withBackground ? props.marginBottom : '',
        width: !props.width ? undefined : typeof props.width === 'number' ? props.width + 'px' : props.width
      }"
    >
      <div class="tab-switcher" :class="props.withLine ? 'tab-switcher--with-line' : ''">
        <div
          v-for="page in props.pages"
          :key="page.id"
          class="tab-switcher__item"
          :class="{ active: props.activePage && page.id === props.activePage.id }"
          @mousedown.left="props.activePage && page.id === props.activePage.id ? undefined : listeners.open(page, $event)"
        >
          <i v-if="page.icon" class="icon" :class="page.icon"></i>{{ page[props.descriptionField] }}
        </div>
      </div>
      <div class="tab-switcher-end"></div>
      <slot name="head"></slot>
    </div>
    <div class="tab-content" :class="props.withBackground ? 'tab-content--bg' : ''">
      <slot></slot>
      <slot v-if="props.activePage" :name="props.activePage.id"></slot>
      <slot name="after"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "v-tabs",
  model: {
    prop: "activePage",
    event: "open"
  },
  props: {
    id: String,
    classes: String,
    pages: Array,
    activePage: {
      type: Object,
      require: true
    },
    marginBottom: {
      type: String,
      default: '25px'
    },
    withBackground: Boolean,
    withSwitcher: {
      type: Boolean,
      default: true
    },
    width: [String, Number],
    withLine: Boolean,
    descriptionField: {
      type: String,
      default: "description"
    }
  }
};
</script>

<style lang="scss" scoped>
.tab-switcher {
  display: flex;
  font-size: 14px;
  line-height: 14px;
  // padding-bottom: 12px;
  &--with-line {
    border-bottom: 1px solid var(--main-bg-80);
  }
  &-wrapper {
    display: flex;
    align-items: center;
  }
  &__item {
    cursor: pointer;
    color: inherit;
    padding-top: 5px;
    padding-bottom: 11px;
    position: relative;
    &.active {
      font-weight: 700;
      // color: #00b700;
      // border-bottom: 2px solid #00b700;
      &::after {
        display: block;
        content: "";
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;
        height: 2px;
        background-color: var(--color-primary);
        border-radius: 1px;
      }
    }
    .icon {
      margin-right: 5px;
    }
    & + & {
      margin-left: 25px;
    }
  }
}
// .tab-switcher {
//   display: flex;
//   // align-items: center;
//   border-bottom: 1px solid #ccc;
//   position: sticky;
//   left: 15px;
//   &-wrapper {
//     display: flex;
//     align-items: center;
//   }
//   &__item {
//     display: inline-block;
//     padding: 10px 10px;
//     cursor: pointer;
//     font-weight: 600;
//     font-size: 14px;
//     .icon {
//       margin-right: 5px;
//     }
//     &.active {
//       color: #00b700;
//       border-bottom: 2px solid #00b700;
//     }
//   }
// }
.tab-content--bg {
  background-color: #FFFFFF;
  border: 1px solid var(--main-color-20);
  border-right: none;
  border-left: none;
  border-top: none;
  padding: 30px 15px;
  position: relative;
}
</style>
