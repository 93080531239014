<template>
  <div id="app" :style="$store.state.isMobileDevice ? {maxHeight: height + 'px'} : null">
    <router-view @showThemes="isShowThemes = !isShowThemes"></router-view>
    <notifications
      ref="notifications"
      classes="vue-notification"
      animation-type="velocity"
      style="right: 10px;top:5px;"
      width="350px"
      :animation="animation"
    >
      <template slot="body" slot-scope="props">
        <div
          class="notification vue-notification"
          :class="props.item.type ? props.item.type : ''"
        >
          <a class="close pull-right" @click="closeNotify(props.close, $event)">
            <i class="fa fa-fw fa-close"></i>
          </a>
          <div class="notification-title">{{ props.item.title }}</div>
          <div v-html="props.item.text" class="notification-content"></div>
        </div>
      </template>
    </notifications>
    <call-notifications v-if="$store.state.socketId"></call-notifications>
    <api-events v-if="$store.state.socketId"></api-events>
    <themes :isShow.sync="isShowThemes"></themes>
  </div>
</template>
<script>
import Vue from "vue";
import apiEvents from "./components/socket/api-events";
import moment from "moment";
import "font-awesome/css/font-awesome.css";
import "simple-line-icons/css/simple-line-icons.css";
import callNotifications from "./components/livesklad/calls-window";
import themes from './components/livesklad/settings/themes.vue';
import { prepareThemes, setTheme } from "./services/colors-api";
import { createSocket } from "./plugins/socket";
import { loadParams, loadThemes } from "./services/_themes";
moment.locale("ru");
function plural(word, num) {
  var forms = word.split('_');
  return num % 10 === 1 && num % 100 !== 11
    ? forms[0]
    : num % 10 >= 2 && num % 10 <= 4 && (num % 100 < 10 || num % 100 >= 20)
    ? forms[1]
    : forms[2];
}
function relativeTimeWithPlural(number, withoutSuffix, key) {
  var format = {
    ss: withoutSuffix ? 'секунда_секунды_секунд' : 'секунду_секунды_секунд',
    mm: withoutSuffix ? 'минута_минуты_минут' : 'минуту_минуты_минут',
    hh: 'час_часа_часов',
    dd: 'день_дня_дней',
    ww: 'неделя_недели_недель',
    MM: 'месяц_месяца_месяцев',
    yy: 'год_года_лет',
  };
  if (key === 'm') {
    return withoutSuffix ? 'минута' : 'минуту';
  } else {
    return number + ' ' + plural(format[key], +number);
  }
}
moment.updateLocale("ru", {
  relativeTime: {
    future: "%s",
    past: "-%s",
    s: "%d секунд",
    ss: relativeTimeWithPlural,
    m: "%d минута",
    mm: relativeTimeWithPlural,
    h: "%d час",
    hh: relativeTimeWithPlural,
    d: "%d день",
    dd: relativeTimeWithPlural,
    M: "%d месяц",
    MM: relativeTimeWithPlural,
    y: "%d год",
    yy: relativeTimeWithPlural
  }
});
var numeral = require("numeral");
export default {
  name: "app",
  components: {
    callNotifications,
    apiEvents,
    themes,
  },
  data() {
    return {
      isShowThemes: false,
      height: document.documentElement.clientHeight || window.innerHeight,
      /* Both 'enter' and 'leave' can be either an object or a function */
      animation: {
        enter(element) {
          /*
           *  "element" - is a notification element
           *    (before animation, meaning that you can take it's initial height, width, color, etc)
           */
          let height = element.clientHeight;

          return {
            // Animates from 0px to "height"
            height: [height, 0],

            // Animates from 0 to random opacity (in range between 0.5 and 1)
            opacity: [1, 0]
          };
        },
        leave: {
          height: 0,
          opacity: 0
        }
      },
    };
  },
  watch: {
    '$store.state.activeTheme'() {
      this.setTheme()
    },
    '$store.state.user.id'() {
      if (!this.$store.state.user.id && this.isShowThemes) this.isShowThemes = false
    },
  },
  methods: {
    getRandomInt(max) {
      return 1 + Math.floor(Math.random() * max);
    },
    closeNotify(close, event) {
      if (this.$store.state.isShowUpdateMessage) {
        this.$store.commit("setIsShowUpdateMessage", false);
      }
      close(event);
    },
    clickOutEvent(evt) {
      var $dd = this.$refs.notifications.$el;
      if (evt && $dd && evt.target !== $dd && !$dd.contains(evt.target)) {
        this.$notify({
          clean: true
        });
      }
      if (this.$store.state.isShowUpdateMessage) {
        this.$store.commit("setIsShowUpdateMessage", false);
      }
    },
    setTheme() {
      setTheme(this.$store.state.activeTheme, this.$store.state.font, this.$store.state.fontSize)
    },
    loadThemes() {
      let params = {}
      loadParams()
        .then(body => {
          params = body.data
        })
        .then(() => loadThemes())
        .then(body => {
          params.items = prepareThemes(body.data.filter(({id}) => id === params.checkedThemeId || id === params.darkThemeId || id === params.lightThemeId))
          params.items.forEach(theme => {
            if (theme.id === params.checkedThemeId) theme.isChecked = true
            else if (theme.id === params.darkThemeId) theme.isDark = true
            else if (theme.id === params.lightThemeId) theme.isLight = true
          });
          this.$store.commit("setFont", params.font.name)
          this.$store.commit("setFontSize", params.font.size)
          if (params.items.length) {
            this.$store.commit("setTheme", params.items.length > 1
              ? params.items
              : params.items[0])
          }
        })
    }
  },
  created() {
    // Регистрация текущей локали при старте приложения
    if (numeral.options.currentLocale === "en") {
      if (!numeral.locales["руб"]) {
        numeral.register("locale", this.currency || "руб", {
          delimiters: {
            thousands: " ",
            decimal: "."
          },
          abbreviations: {
            thousand: "тыс.",
            million: "млн.",
            billion: "млрд.",
            trillion: "трл."
          },
          ordinal: function(number) {
            return number === 1 ? "er" : "ème";
          },
          currency: {
            symbol: this.currency || "руб"
          }
        });
      }
      numeral.locale(this.currency || "руб");
    }
    if (
      !this.$socket &&
      this.$store.state?.user?.accessToken &&
      (!window.location.href.includes('/login')
        || !window.location.href.includes('token='))
    ) {
      const {socket, socketId} = createSocket(this.$store.state.user.accessToken)
      Vue.prototype.$socket = socket
      this.$store.commit("setSocketId", socketId)
    }
    if (this.$store.state.activeTheme) this.setTheme()
    else if (this.$store.state?.user?.accessToken) this.loadThemes()
  },
  mounted() {
    setTimeout(
      () => document.addEventListener("click", this.clickOutEvent),
      10
    );
  },
  destroyed() {
    document.removeEventListener("click", this.clickOutEvent);
  }
};
</script>
<style src="./vendors/themify-icons/css/themify-icons.css"></style>
<style lang="scss">
body {
  height: 100vh;
  margin: 0;
}
#app {
  height: 100%;
}
.vue-notification.notification {
  font-size: 13px;
  margin: 3px 10px;
  padding: 10px 10px 10px 45px;
  border-radius: 3px;
  border-left: 0;
  color: #fff;
  cursor: pointer;

  box-shadow: 1px 1px 5px #999;
  &:hover {
    box-shadow: 1px 1px 5px #000;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    filter: alpha(opacity=100);
  }
  &.error {
    background-color: #f66;
    border-left-color: #e51001;
    background-position: 10px;
    background-repeat: no-repeat;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=) !important;
  }
  &.warn {
    background-position: 10px;
    background-repeat: no-repeat;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=) !important;
  }
  &.success {
    background-position: 10px;
    background-repeat: no-repeat;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==) !important;
  }
  &.update {
    background-position: 10px;
    background-repeat: no-repeat;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=) !important;
  }
}
.under-notifications {
  z-index: 10000;
  position: fixed;
}
</style>
