function getNomenclatureRemains(id, params) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "get",
      `nomenclatures/${id}/remains`,
      params,
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
export { getNomenclatureRemains };