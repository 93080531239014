import Vue from "vue";
let BackendService = Vue.prototype.$backendService
function loadThemes() {
  return new Promise((resolve, reject) => {
    if (!BackendService) BackendService = Vue.prototype.$backendService;
    BackendService._api.callAPI(
      "get",
      "params/themes",
      {},
      (response) => {
        resolve(response.body);
      },
      (error) => {
        reject(error);
      }
    );
  });
}
function createTheme(params) {
  return new Promise((resolve, reject) => {
    BackendService._api.callAPI(
      "post",
      "params/themes",
      params,
      (response) => {
        resolve(response.body);
      },
      (error) => {
        reject(error);
      }
    );
  });
}
function updateTheme(id, params) {
  return new Promise((resolve, reject) => {
    BackendService._api.callAPI(
      "patch",
      `params/themes/${id}`,
      params,
      (response) => {
        resolve(response.body);
      },
      (error) => {
        reject(error);
      }
    );
  });
}
function deleteTheme(id) {
  return new Promise((resolve, reject) => {
    BackendService._api.callAPI(
      "delete",
      `params/themes/${id}`,
      {},
      (response) => {
        resolve(response.body);
      },
      (error) => {
        reject(error);
      }
    );
  });
}
function saveThemeSettings(params) {
  return new Promise((resolve, reject) => {
    BackendService._api.callAPI(
      "put",
      `params/themes/setting`,
      params,
      (response) => {
        resolve(response.body);
      },
      (error) => {
        reject(error);
      }
    );
  });
}
function loadParams() {
  return new Promise((resolve, reject) => {
    if (!BackendService) BackendService = Vue.prototype.$backendService;
    BackendService._api.callAPI(
      "get",
      "params/themes/setting",
      {},
      (response) => {
        resolve(response.body);
      },
      (error) => {
        reject(error);
      }
    );
  });
}
export {
  loadThemes,
  createTheme,
  updateTheme,
  deleteTheme,
  saveThemeSettings,
  loadParams
}