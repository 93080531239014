<template>
  <masked-input
    class="form-control"
    :class="[
      isCustom ? 'custom-input' : '',
      isShowValidation ? 'custom-input-danger' : '',
    ]"
    name="phone"
    :value="numberValue"
    @input="input"
    :mask="getPhoneMask"
    :guide="false"
    :placeholder="
      placeholder !== undefined
        ? placeholder
        : '+7 (xxx) xxx-xx-xx, ... , x-xx-xx'
    "
    :maxLength="maxLength"
    placeholderChar="_"
  ></masked-input>
</template>

<script>
import { getPhoneMask } from "./_mask-function";
export default {
  name: "v_input_phone",

  props: {
    value: {
      required: true,
      default: "",
    },
    isCustom: {
      type: Boolean,
      default: true,
    },
    placeholder: String,
    isShowValidation: Boolean,
    maxLength: Number
  },

  data() {
    return {
      numberValue: this.$props.value,
      getPhoneMask: getPhoneMask
    };
  },
  watch: {
    value: function (value) {
      if (value != this.numberValue) this.numberValue = this.$props.value;
    },
  },
  methods: {
    focus() {
      this.$el.focus();
    },
    input(value) {
      let number = value?.replace(/\D/gm, "");
      if (
        number &&
        number.length === 11 &&
        number[0] === "8" &&
        (number[1] === "3" ||
          number[1] === "4" ||
          number[1] === "7" ||
          number[1] === "8" ||
          number[1] === "9")
      ) {
        this.numberValue = "+7" + value.slice(1);
        let elem = this.getInputElement();
        let position = this.doGetCaretPosition(elem);
        setTimeout(() => {
          this.doSetCaretPosition(elem, position ? position + 1 : 0);
        }, 50);
      } else {
        this.numberValue = value;
      }
      if (this.value !== undefined && this.value === value) return;
      this.$emit("input", this.numberValue);
    },
    change(value) {
      this.$emit("change", value);
    },
    getInputElement() {
      return this.$el;
    },
    /*
     **  Returns the caret (cursor) position of the specified text field.
     **  Return value range is 0-elem.length.
     */
    doGetCaretPosition(elem) {
      // Initialize
      var iCaretPos = 0;

      // IE Support
      if (document.selection) {
        // Set focus on the element
        elem.focus();

        // To get cursor position, get empty selection range
        var oSel = document.selection.createRange();

        // Move selection start to 0 position
        oSel.moveStart("character", -elem.value.length);

        // The caret position is selection length
        iCaretPos = oSel.text.length;
      }

      // Firefox support
      else if (elem.selectionStart || elem.selectionStart == "0")
        iCaretPos = elem.selectionStart;

      // Return results
      return iCaretPos;
    },

    /*
     **  Sets the caret (cursor) position of the specified text field.
     **  Valid positions are 0-elem.length.
     */
    doSetCaretPosition(elem, iCaretPos) {
      // IE Support
      if (document.selection) {
        // Set focus on the element
        elem.focus();

        // Create empty selection range
        var oSel = document.selection.createRange();

        // Move selection start and end to 0 position
        oSel.moveStart("character", -elem.value.length);

        // Move selection start and end to desired position
        oSel.moveStart("character", iCaretPos);
        oSel.moveEnd("character", 0);
        oSel.select();
      }

      // Firefox support
      else if (elem.selectionStart || elem.selectionStart == "0") {
        elem.selectionStart = iCaretPos;
        elem.selectionEnd = iCaretPos;
        elem.focus();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
