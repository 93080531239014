import Vue from "vue";
import * as salaries from "./_salaries";
import * as apiSettings from "./_api-settings";
import * as customers from "./_customers";
import * as shopSettings from "./_shop-settings";
import * as notifications from "./_notifications";
import * as telegram from "./_telegram";
import * as workProduct from "./_work-products";
import * as invoices from "./_invoices";
import * as importFuncs from "./_import";
import * as transactions from "./_transactions";
import * as cashItems from "./_cash-items";
import * as columns from "./_columns";
import * as filters from "./_filters";
import * as tariff from "./_tariff";
import * as nomenclature from "./_nomenclature";

export default class BackendService {
  constructor({ store, router }) {
    this._api = new Vue({ store, router });

    ////////////////////////// НАЧИСЛЕНИЯ ////////////////////////////
    Object.keys(salaries).forEach(func => (this[func] = salaries[func]));
    ///////////////////////// РАБОТА С АПИ ///////////////////////////
    Object.keys(apiSettings).forEach(func => (this[func] = apiSettings[func]));
    ////////////////////// СОТРУДНИКИ И РОЛИ /////////////////////////
    Object.keys(customers).forEach(func => (this[func] = customers[func]));
    /////////////////////////// TELEGRAM /////////////////////////////
    Object.keys(telegram).forEach(func => (this[func] = telegram[func]));
    ///////////////////////// НОМЕНКЛАТУРЫ ///////////////////////////
    Object.keys(workProduct).forEach(func => (this[func] = workProduct[func]));
    ///////////////////////////// СЧЕТА //////////////////////////////
    Object.keys(invoices).forEach(func => (this[func] = invoices[func]));
    //////////////////////////// ИМПОРТ //////////////////////////////
    Object.keys(importFuncs).forEach(func => (this[func] = importFuncs[func]));
    ////////////////////////// ТРАНЗАКЦИИ ////////////////////////////
    Object.keys(transactions).forEach(func => (this[func] = transactions[func]));
    ////////////////////////// ЛОКАЦИИ ////////////////////////////
    Object.keys(shopSettings).forEach(
      func => (this[func] = shopSettings[func])
    );
    ////////////////////////// УВЕДОМЛЕНИЯ ///////////////////////////
    Object.keys(notifications).forEach(
      func => (this[func] = notifications[func])
    );
    ////////////////////////// УВЕДОМЛЕНИЯ ///////////////////////////
    Object.keys(cashItems).forEach(
      func => (this[func] = cashItems[func])
    );
    ///////////////////////// ФУНКЦИИ ТАБЛИЦ /////////////////////////
    Object.keys(columns).forEach(func => (this[func] = columns[func]));
    //////////////////////// ФУНКЦИИ ФИЛЬТРОВ ////////////////////////
    Object.keys(filters).forEach(func => (this[func] = filters[func]));
    //////////////////////// ФУНКЦИИ ФИЛЬТРОВ ////////////////////////
    Object.keys(tariff).forEach(func => (this[func] = tariff[func]));
    //////////////////////// ФУНКЦИИ НОМЕНКЛАТУРЫ ////////////////////////
    Object.keys(nomenclature).forEach(func => (this[func] = nomenclature[func]));
  }

  //////////////////////////////////////////////////////////////////
  ///////////////////////////// ЗАКАЗ //////////////////////////////
  //////////////////////////////////////////////////////////////////

  getMoveOrderShops(orderId) {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "get",
        `orders/${orderId}/give/shops`,
        {},
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  //////////////////////////////////////////////////////////////////
  /////////////////// РАБОТЫ И ЗАПЧАСТИ В ЗАКАЗЕ ///////////////////
  //////////////////////////////////////////////////////////////////

  changeFullDiscount(orderId, discount) {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "patch",
        `orders/${orderId}/discount`,
        { count: discount },
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  //////////////////////////////////////////////////////////////////
  /////////////////////////// МОДИФИКАЦИИ //////////////////////////
  //////////////////////////////////////////////////////////////////

  getShopModifies(names) {
    // Полная замена
    // !!! Там теперь приходит объект цен, переделать обработчик в поступлении
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "post",
        `shops/${this._api.$store.state.activeShop}/modifies`,
        { names },
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getProductByBarcode(filter, params = {}) {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "get",
        `shops/${this._api.$store.state.activeShop}/search/barcode`,
        { filter, ...params },
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getModifyById(modifyId) {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "get",
        `shops/${this._api.$store.state.activeShop}/modifies/${modifyId}`,
        {},
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  //////////////////////////////////////////////////////////////////
  ////////////////////////////// ЦЕНЫ //////////////////////////////
  //////////////////////////////////////////////////////////////////

  addPrice(priceName) {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "post",
        `prices`,
        { name: priceName },
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getPrices() {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "get",
        `prices`,
        {},
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }
  changePrice(id, params) {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "patch",
        `prices/${id}`,
        params,
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }
  deletePrice(id) {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "delete",
        `prices/${id}`,
        {},
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  changeDefaultPrice(obj) {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "patch",
        `prices`,
        obj,
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  //////////////////////////////////////////////////////////////////
  /////////////////// РЕДАКТИРОВАНИЕ ДОКУМЕНТОВ ////////////////////
  //////////////////////////////////////////////////////////////////

  updateDocNode(docId, text) {
    // Пока только в инвентаризации
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "put",
        `documents/${docId}/node`,
        { text },
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  updateDocPosition(docId, posId, obj) {
    // Пока только в поступлении
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "patch",
        `documents/${docId}/product-histories/${posId}`,
        obj,
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  addDocPosition(docId, obj) {
    // Пока только в инвентаризации
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "post",
        `documents/${docId}/product-histories`,
        obj,
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////// ИНВЕНТАРИЗАЦИЯ //////////////////////////
  //////////////////////////////////////////////////////////////////

  getDocPositions(docId, pageNumber = 1, pageSize = 20, sort) {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "get",
        `documents/${docId}/product-histories`,
        { pageSize: pageSize, page: pageNumber, sort },
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  fillInventory(docId, isPositive, page = 1, pageSize = 20) {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "post",
        `documents/${docId}/shopCounts`,
        {
          isPositive,
          page,
          pageSize
        },
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  computeShopRemainsInventory(docId, page, pageSize = 20) {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "post",
        `documents/${docId}/shopRemains`,
        {
          page,
          pageSize
        },
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  changePriceInventory(docId, priceId, page, pageSize) {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "post",
        `documents/${docId}/price`,
        {
          priceId,
          page,
          pageSize
        },
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  //////////////////////////////////////////////////////////////////
  /////////////////////// РАБОТА С КОРЗИНОЙ ////////////////////////
  //////////////////////////////////////////////////////////////////

  getCart(params) {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "get",
        `shops/${this._api.$store.state.activeShop}/carts`,
        params ? params : {},
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getCartHistory(params) {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "get",
        `shops/${this._api.$store.state.activeShop}/carts/history`,
        params ? params : {},
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  addToCart(positions) {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "post",
        `shops/${this._api.$store.state.activeShop}/carts`,
        {
          products: Array.isArray(positions) ? positions : [positions],
          utc: -new Date().getTimezoneOffset()
        },
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  checkCartWithSeller(cartIds) {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "get",
        "carts/delivery",
        { cartIds: Array.isArray(cartIds) ? cartIds : [cartIds] },
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  createSellerOrder(carts, email, phone, name) {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "post",
        "carts/delivery",
        { carts, email, phone, name },
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  //////////////////////////////////////////////////////////////////
  /////////////////////// РАБОТА С ПОДПИСКОЙ ///////////////////////
  //////////////////////////////////////////////////////////////////

  promisePayment() {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "post",
        `company/trust-payment`,
        {},
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  //////////////////////////////////////////////////////////////////
  /////////////////////////// ТЕЛЕФОНИЯ ////////////////////////////
  //////////////////////////////////////////////////////////////////

  createPhoneInfo(telephony, params) {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "post",
        `telephony/${telephony}/phones`,
        params,
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  updatePhoneInfo(telephony, phoneId, params) {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "patch",
        `telephony/${telephony}/phones/${phoneId}`,
        params,
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  deletePhone(telephony, phoneId) {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "delete",
        `telephony/${telephony}/phones/${phoneId}`,
        {},
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  connectTelephony(telephony, params) {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "put",
        `telephony/${telephony}`,
        params,
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getPhone(telephony, phoneId) {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "get",
        `telephony/${telephony}/phones/${phoneId}`,
        {},
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getPhones(telephony) {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "get",
        `telephony/${telephony}/phones`,
        {},
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getTelephonyInfo(telephony) {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "get",
        `telephony/${telephony}`,
        {},
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  sendDataToMango({ name, phone }) {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "post",
        "telephony/mango/register",
        { name, phone },
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  //////////////////////////////////////////////////////////////////
  ////////////////////////// СТАТИСТИКА ////////////////////////////
  //////////////////////////////////////////////////////////////////

  getStatistic({ year, month, utc }) {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "get",
        "company/bookkeeping",
        { year, month, utc },
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  //////////////////////////////////////////////////////////////////
  /////////////////////////// КОМПАНИЯ /////////////////////////////
  //////////////////////////////////////////////////////////////////

  getCompany() {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "get",
        "company",
        {},
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  updateCompany(params) {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "patch",
        "company",
        params,
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  deleteStatus(statusId) {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "delete",
        `company/statuses/${statusId}`,
        {},
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }
  getStatuses(params) {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "get",
        "company/statuses",
        params,
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  sortStatuses(statusIds) {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "put",
        "company/statuses/sort",
        { statusIds },
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  updateSequence(statusId, { typeOrderId, statusIds, deadline }) {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "put",
        `company/statuses/${statusId}/sequence`,
        { typeOrderId, statusIds, deadline },
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  updateStatus(
    statusId,
    { name, color, comment, synonym, rolesAccess, deadline, isPayRequired, type }
  ) {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "patch",
        `company/statuses/${statusId}`,
        { name, color, comment, synonym, rolesAccess, deadline, isPayRequired, type },
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  updateStatusDeadline(statusId, { deadline, typeOrderId }) {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "put",
        `company/statuses/${statusId}/sequence`,
        { deadline, typeOrderId },
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getTypeOrders() {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "get",
        "type-orders",
        {},
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  updateTypeOrder(typeOrderId, { statusId }) {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "patch",
        `type-orders/${typeOrderId}`,
        { statusId },
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////// РАБОТЫ //////////////////////////////
  //////////////////////////////////////////////////////////////////

  getWorks(params = {}, isArchive) {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "get",
        `shops/${this._api.$store.state.activeShop}/works${
          isArchive ? "/archive" : ""
        }`,
        params,
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  createWork(params = {}) {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "post",
        `shops/${this._api.$store.state.activeShop}/works`,
        params,
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getWork(workId) {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "get",
        `shops/${this._api.$store.state.activeShop}/works/${workId}`,
        {},
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  updateWork(workId, params) {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "patch",
        `shops/${this._api.$store.state.activeShop}/works/${workId}`,
        params,
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  deleteWork(workId, params = {}) {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "delete",
        `shops/${this._api.$store.state.activeShop}/works/${workId}`,
        params,
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  restoreWork(workId) {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "put",
        `shops/${this._api.$store.state.activeShop}/works/${workId}`,
        {},
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getPositions(link, params = {}, method = "get") {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        method,
        link,
        params,
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  sendLog(method, params) {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "POST",
        "company/log",
        {
          method,
          params
        },
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  readChangelog() {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "put",
        "customers/news",
        {},
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  checkAmo() {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "get",
        "company/amocrm",
        {},
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  sendAmoCode(params) {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "put",
        "company/amocrm",
        params,
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  clearAmo() {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "delete",
        "company/amocrm",
        {},
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getFiscalRegisters(sn) {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "get",
        `shops/${this._api.$store.state.activeShop}/fiscal-registers`,
        { sn },
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getCallRecord(callId) {
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        "get",
        `calls/${callId}/record`,
        {},
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }
  getReport(type, params = {}, balanceType) {
    // balanceType = debit | credit
    return new Promise((resolve, reject) => {
      this._api.callAPI(
        type === "nomenclatures" ? "post" : "get",
        type === "order"
        ? "company/reports/orders"
        : type === "sale"
        ? "company/reports/sales"
        : type === "nomenclatures"
        ? "company/reports/modifies/consumption"
        : type === "how-know"
        ? "company/reports/how-knows"
        : type === "verification"
        ? "company/reports/verification"
        : type === "balance"
        ? `company/reports/counteragents/${balanceType}`
        : type === "custom-remains"
        ? "company/reports/products"
        : type === "custom-cancellation"
        ? "company/reports/orders/products"
        : type === "orders-time-control"
        ? "company/reports/orders/time"
        : "company/reports/orders/time",
        params,
        response => {
          resolve(response.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }
}
