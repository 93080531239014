<template>
  <masked-input
    v-focus="autofocus"
    type="text"
    class="form-control custom-input text-right"
    :class="isShowValidation ? 'custom-input-danger' : ''"
    :value="numberValue"
    @input="input"
    :mask="inputMask"
    @keyup.enter.native="pressEnter"
    @change.native="change"
    @focus.native="focus"
    @blur.native="blur"
    :guide="false"
    placeholderChar="#"
  ></masked-input>
</template>

<script>
import createNumberMask from "text-mask-addons/dist/createNumberMask";
export default {
  name: "v_input_money",

  props: {
    value: {
      required: true,
      default: ""
    },
    allowNull: {
      type: Boolean,
      default: true
    },
    allowNegative: Boolean,
    isShowValidation: Boolean,
    autofocus: Boolean,
    integerLimit: {
      type: Number,
      default: 10
    }
  },

  data() {
    return {
      numberValue: this.number2str(this.$props.value)
    };
  },
  computed: {
    inputMask() {
      let allowNegative = this.allowNegative;
      let integerLimit = this.integerLimit;
      return function(rawValue) {
        let numberMask = createNumberMask({
          prefix: "",
          thousandsSeparatorSymbol: " ",
          allowDecimal: true,
          integerLimit: integerLimit,
          requireDecimal: true,
          allowNegative: allowNegative
        });
        if (~rawValue.indexOf(",")) {
          // var start = maskedInput.selectionStart,
          // end = maskedInput.selectionEnd;
          rawValue = rawValue.replace(",", ".");
          // maskedInput.setSelectionRange(start, end);
        }
        return numberMask(rawValue);
      };
    }
  },
  watch: {
    value: function(value) {
      // Оба к виду округленного числа
      if (
        this.str2number(value) !== this.str2number(this.numberValue) ||
        this.numberValue.replace(/\s/g, "") === "."
      ) {
        this.numberValue = this.number2str(value);
      }
    }
  },
  methods: {
    str2number(price) {
      if (typeof price === "string") {
        if (price.replace(/\s/g, "") === ".") {
          return 0;
        } else if (price === "") {
          return undefined;
        } else {
          return this.roundMoney(price.replace(/\s/g, ""));
        }
      } else if (typeof price === "number") {
        return this.roundMoney(price);
      } else {
        return price;
      }
    },
    number2str(number) {
      if (number === undefined || number === null || number === "") {
        return number;
      }
      return this.numeral(number);
    },
    input(value) {
      this.numberValue = value;
      if (
        this.value !== undefined &&
        (this.value === value || this.value === this.str2number(value))
      )
        return;
      this.$emit("input", this.str2number(value));
    },
    change(e) {
      var str = e.target.value;
      if (
        !this.$props.allowNull &&
        (str === undefined || str === null || str === "")
      ) {
        this.$emit("change", 0);
        this.$emit("input", 0);
        this.numberValue = this.number2str(0);
      } else {
        this.$emit("change", this.str2number(str));
      }
    },
    pressEnter() {
      this.$emit("keyupEnter");
    },
    focus(e) {
      e.target.select();
      this.$emit("focus");
    },
    blur() {
      this.$emit("blur");
    }
  }
};
</script>

<style lang="scss" scoped></style>
