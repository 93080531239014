import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Resource from "vue-resource";

// COMPONENTS
import vSimpleSwitcher from "./components/livesklad/resources/v_switcher.vue";
import vSwitcher2 from "./components/livesklad/resources/v_switch.vue";
import vSwitcher from "./components/livesklad/resources/v_switcher_new.vue";
import vCount from "./components/livesklad/resources/v_count.vue";
import vPagination from "./components/livesklad/resources/v_pagination.vue";
import vScroll2 from "./components/livesklad/resources/vue/v_scroll_2.vue";
import vGuarantee from "./components/livesklad/resources/v_guarantee.vue";
import vInputMoney from "./components/livesklad/resources/v_input_money.vue";
import vInputMoney2 from "./components/livesklad/resources/v_input_money_2.vue";
import vInputNumber from "./components/livesklad/resources/v_input_number.vue";
import vInputNumber2 from "./components/livesklad/resources/v_input_number_2.vue";
import vInputRub from "./components/livesklad/resources/v_input_rub.vue";
import vInputPhone from "./components/livesklad/resources/v_input_phone.vue";
import vScrollTableWrapper from "./components/livesklad/resources/v_scroll_table_wrapper.vue";
import vContentEditable from "./components/livesklad/resources/v_content_editable.vue";
import VuePrinter from "./components/livesklad/resources/v-printer.js";

import VueBarcodeScanner from "./plugins/VueBarcodeScanner";

import maskedInput from "vue-text-mask";

import mixin from "./components/livesklad/mixins/mixin.js";

import VueClipboard from "vue-clipboard2";

import vNotifications from "vue-notification";
import velocity from "velocity-animate";
import VTooltip from "v-tooltip";

import BackendService from "./services/backend-service";
import KkmServer from "./services/kkm-server";

import CryptoJS from "crypto-js";
import { i18n } from './i18n'

Vue.config.productionTip = false;

Vue.component("v-scroll-2", vScroll2);
Vue.component("v-simple-switcher", vSimpleSwitcher);
Vue.component("v-switcher", vSwitcher); // ЗАМЕНИТЬ ПОТОМ ОБА СВИЧЕРА НА НЕГО
Vue.component("v-switcher2", vSwitcher2);
Vue.component("v-count", vCount);
Vue.component("v-pagination", vPagination);
Vue.component("v-guarantee", vGuarantee);
Vue.component("v-input-money", vInputMoney);
Vue.component("v-input-money-2", vInputMoney2);
Vue.component("v-input-number", vInputNumber);
Vue.component("v-input-number-2", vInputNumber2);
Vue.component("v-input-rub", vInputRub);
Vue.component("v-input-phone", vInputPhone);
Vue.component("v-scroll-table-wrapper", vScrollTableWrapper);

Vue.component("masked-input", maskedInput);

Vue.component("v-content-editable", vContentEditable);

Vue.use(vNotifications, {
  velocity
});

Vue.use(VueBarcodeScanner);

Vue.use(Resource);

Vue.mixin(mixin);

Vue.use(VueClipboard);

Vue.use(VTooltip);

const backendService = new BackendService({ store, router });
Vue.prototype.$backendService = backendService;

const kkmServer = new KkmServer({ store, router });
Vue.prototype.$kkmServer = kkmServer;

const vuePrinter = new VuePrinter();
Vue.prototype.$vuePrinter = vuePrinter;

// Регистрируем глобальную пользовательскую директиву v-focus
Vue.directive("focus", {
  // Когда привязанный элемент вставлен в DOM...
  inserted: function(el, binding) {
    if (binding.value === undefined || binding.value) {
      // Переключаем фокус на элемент
      el.focus();
    }
  }
});
Vue.directive("select", {
  // Когда привязанный элемент вставлен в DOM...
  inserted: function(el) {
    // Переключаем фокус на элемент
    el.select();
  }
});
Vue.directive("autoselect", {
  inserted: function(el, binding) {
    if (binding.value) {
      el.select();
    }
  },
  update(el, binding) {
    if (el !== document.activeElement && binding.value && !binding.oldValue) {
      el.focus();
      Vue.nextTick(() => {
        el.select();
      });
    }
  }
});

Vue.http.options.root = "https://api.livesklad.com" + process.env.VUE_APP_API;

// Подключение jQuery ( jquery, $ )
global.$ = window.$ = $;
let getSign = function(params, url, isGet) {
  if (url.includes("http") && !url.includes("livesklad")) return;
  const fullUrl =
    url.replace(/.*https:\/\/api\.livesklad\.com/, "") +
    (url[url.length - 1] !== "/" ? "/" : "");
  const s = Object.keys(params)
    .sort((a, b) => (a > b ? 1 : -1))
    .reduce((res, key) => {
      if (key === "sign" || typeof params[key] === "undefined") {
        return res;
      } else if (params[key] === null) {
        return res + key;
      } else if (Array.isArray(params[key])) {
        const length = isGet
          ? params[key].filter(val => val !== undefined).length
          : params[key].length
        return (
          res +
          (!isGet || length ? `${key}${length}` : "")
        );
      } else if (typeof params[key] === "object") {
        return (
          res +
          (!isGet || Object.keys(params[key]).length
            ? `${key}${Object.keys(params[key]).length}`
            : "")
        );
      } else {
        return res + key + params[key];
      }
    }, "");
  return Buffer.from(
    CryptoJS.HmacSHA1(fullUrl + CryptoJS.MD5(s), "user").toString()
  ).toString("base64");
};
Vue.http.interceptors.push((request, next) => {
  if (~request.url.indexOf("blob:http")) {
    request.root = "blob:http://localhost:8080";
    request.url = request.url.replace("blob:http://localhost:8080/", "");
    if (request.method.toUpperCase() === "GET") {
      request.responseType = "blob";
    }
  } else if (~request.url.indexOf("images.livesklad.com") || ~request.url.indexOf("yandexcloud")) {
    request.headers.set("Content-Type", "application/octet-stream");
    if (request.method.toUpperCase() === "GET") {
      request.responseType = "blob";
    }
  } else if (request.url.indexOf("suggestions.dadata.ru") !== -1) {
    request.headers.set("Content-Type", "application/json");
    request.headers.set("Accept", "application/json");
    request.headers.set(
      "Authorization",
      "Token cd611876574145a399680fc5c587d71196ba25cc"
    );
  } else if (
    !request.url.includes("seller.livesklad.com") &&
    (request.url.includes("livesklad.com") ||
    !request.url.includes("http"))
  ) {
    if (request.url.includes("customers/changePassword")) {
      request.headers.set("authorization", store.state.restoreToken);
    } else {
      request.headers.set("authorization", store.state.user.accessToken);
    }
    if (
      store.state.user.accessToken &&
      store.state.user.accessToken.length &&
      store.state.user.accessToken.length > 0 &&
      request.url.indexOf("login") === -1 &&
      request.url.indexOf("registration") === -1
    ) {
      if (request.method.toUpperCase() === "GET") {
        request.params.version = process.env.VUE_APP_VERSION;
        request.headers.set(
          "sign",
          getSign(
            { ...request.params, token: store.state.user.accessToken },
            ~request.root.indexOf("v1")
              ? `/v1/${request.url}`
              : ~request.root.indexOf("v2")
              ? `/v2/${request.url}`
              : `/${request.url}`,
            true
          )
        );
      } else if (
        request.method.toUpperCase() === "POST" ||
        request.method.toUpperCase() === "PUT" ||
        request.method.toUpperCase() === "PATCH" ||
        request.method.toUpperCase() === "DELETE"
      ) {
        request.body.version = process.env.VUE_APP_VERSION;
        request.headers.set(
          "sign",
          getSign(
            { ...request.body, token: store.state.user.accessToken },
            ~request.root.indexOf("v1")
              ? `/v1/${request.url}`
              : ~request.root.indexOf("v2")
              ? `/v2/${request.url}`
              : `/${request.url}`
          )
        );
      }
    }
  }
  next();
});

window.carrotQuestUpdateFunc = function () {
  var params = JSON.parse(localStorage.getItem("vuex"));
  if (params && params.user && params.user.userHashId) {
    window.carrotquest.auth(
      params.user.userHashId,
      CryptoJS.HmacSHA256(
        params.user.userHashId,
        "userauthkey-27607-b77683b88badaacca9d70e6082de11fc71399b682e3f1e1c9e1235718f93cf"
      )
    );
  }
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
