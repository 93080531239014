import { io } from "socket.io-client";
import { v4 as uuidv4 } from 'uuid'

function getRandomInt(max) {
  return 1 + Math.floor(Math.random() * max);
}

export const createSocket = function(token) {
  const socketId = uuidv4()
  const socket = io("wss://wss.livesklad.com", {
    path: "/events/",
    transports: ['websocket'],
    auth: { token }
  });

  socket.on("disconnect", reason => {
    if (!socket.active && reason != 'io client disconnect') {
      socket.connect();
      setTimeout(() => {
        socket.connect();
      }, getRandomInt(29) * 1000)
    }
  });
  return { socket, socketId }
}