function getCustomersNotifications(params) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "get",
      "notifications/customers",
      params,
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function getClientsNotifications(params) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "get",
      "notifications/counteragents",
      params,
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function getNotificationTemplate() {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "get",
      "notifications/template",
      {},
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function getNotificationMarks(type) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "get",
      `notifications/${type}/marks`,
      {},
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function createNotification(params) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "post",
      "notifications",
      params,
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function updateNotification(itemId, params) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "patch",
      `notifications/${itemId}`,
      params,
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function deleteNotification(itemId) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "delete",
      `notifications/${itemId}`,
      {},
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function getNotification(id) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "get",
      `notifications/${id}`,
      {},
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function getNotificationOrderTemplate(orderId, type) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "get",
      `orders/${orderId}/notifications`,
      { type: type, utc: -new Date().getTimezoneOffset()},
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function getNotificationCounteragentTemplate(counteragentId, type) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "get",
      `counteragents/${counteragentId}/notifications`,
      {
        type,
        shopId: this._api.$store.state.activeShop,
        utc: -new Date().getTimezoneOffset()
      },
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function sendOrderTelegram(orderId, text, isSilent) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "post",
      `orders/${orderId}/telegram`,
      { text, isSilent },
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function sendOrderEmail(orderId, text, documents) {
  let docs = documents.filter(({isChecked}) => isChecked).map(({id}) => id);
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "post",
      `orders/${orderId}/email`,
      { text: text, documentTemplateIds: docs, utc: -new Date().getTimezoneOffset() },
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function sendOrderSms(orderId, text, phone) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "post",
      `orders/${orderId}/sms`,
      { text, phone },
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function sendCounteragentSms(text, phone) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "post",
      `counteragents/sms`,
      { text, phone },
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

export {
  getCustomersNotifications,
  getClientsNotifications,
  getNotificationTemplate,
  getNotificationMarks,
  createNotification,
  updateNotification,
  deleteNotification,
  getNotification,
  getNotificationOrderTemplate,
  getNotificationCounteragentTemplate,
  sendOrderTelegram,
  sendOrderEmail,
  sendOrderSms,
  sendCounteragentSms
};
