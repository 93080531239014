<template>
  <div
    class="dropdown"
    :class="['dropdown--' + type, isShow ? 'dropdown--opened' : '']"
  >
    <div @click="onClick">
      <slot name="title">
        <button class="btn btn-secondary dots-btn">
          <IconDots
            :width="30"
            :height="30"
            :iconColor="isWindow ? 'aside' : 'main'"
            :hoverColor="isWindow ? 'aside' : 'main'"
          ></IconDots>
        </button>
      </slot>
    </div>
    <div v-if="isShow && !isNotShow" class="dropdown__open" :style="compWindowStyle">
      <slot :changeIsShow="changeIsShow"></slot>
    </div>
  </div>
</template>
<script>
import IconDots from '../../icons/IconDots.vue';
export default {
  name: "dropdown",
  components: {
    IconDots
  },
  props: {
    type: {
      type: String,
      default: "top-left"
    },
    width: Number,
    zIndex: {
      type: Number,
      default: 1000
    },
    isWindow: Boolean,
    isHover: Boolean,
    disabled: Boolean,
    isNotShow: Boolean,
    popupStyle: Object
  },
  data() {
    return {
      isShow: false
    }
  },
  computed: {
    compWindowStyle() {
      const params = {}
      if (this.width) params.width = this.width + 'px'
      if (this.zIndex) params.zIndex = this.zIndex
      if (this.popupStyle) Object.assign(params, this.popupStyle)
      return params
    }
  },
  methods: {
    onClick(evt) {
      if (!this.isHover && !this.disabled) this.changeIsShow(evt)
    },
    changeIsShow(evt) {
      this.isShow = !this.isShow
      this.$emit("update:isShow", this.isShow, evt)
      if (this.isShow) {
        this.$emit("open", evt)
        if (!this.isHover) {
          setTimeout(
            () => document.addEventListener("click", this.clickOutEvent),
            10
          );
        }
      } else {
        this.$emit("close", evt)
        if (!this.isHover) {
          document.removeEventListener("click", this.clickOutEvent);
        }
      }
    },
    clickOutEvent(evt) {
      var $dd = this.$el.children[1];
      if (
        evt.target !== $dd && !$dd?.contains?.(evt.target) ||
        evt.target?.className?.includes?.('order-status-label') ||
        evt.target?.className?.includes?.('dropdown-item') ||
        evt.target?.closest?.('.dropdown-item')
      ) {
        this.changeIsShow(evt)
      }
    },
    onMouseEnter(evt) {
      if (!this.isShow) this.changeIsShow(evt)
    },
    onMouseLeave(evt) {
      if (this.isShow) this.changeIsShow(evt)
    }
  },
  mounted() {
    if (this.isHover) {
      this.$el.addEventListener("mouseenter", this.onMouseEnter)
      this.$el.addEventListener("mouseleave", this.onMouseLeave)
    }
  },
  beforeDestroy() {
    if (this.isHover) {
      this.$el.removeEventListener("mouseenter", this.onMouseEnter)
      this.$el.removeEventListener("mouseleave", this.onMouseLeave)
    }
  },
}
</script>
<style lang="scss" scoped>
.dropdown {
  display: inline-block;
  &__open {
    position: absolute;
    padding: 11px 0;
    z-index: 1000;
  }
  &--top-left > .dropdown__open {
    bottom: 100%;
    left: 0;
  }
  &--top-right > .dropdown__open {
    bottom: 100%;
    right: 0;
  }
  &--bottom-left > .dropdown__open {
    top: 100%;
    left: 0;
  }
  &--bottom-right > .dropdown__open {
    top: 100%;
    right: 0;
  }
  &--bottom-full > .dropdown__open {
    top: 100%;
    left: 0;
    right: 0;
  }
  &--left-bottom > .dropdown__open {
    left: 100%;
    top: 0;
  }
  .dots-btn {
    padding: 0;
    svg {
      padding: 6px 3px;
    }
  }
  &--opened button.btn.btn-secondary {
    background-color: var(--main-color-20);
  }
}
</style>