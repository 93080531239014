<template>
  <masked-input
    type="text"
    class="form-control custom-input text-right"
    :value="numberValue"
    @input="input"
    :mask="mask"
    @keyup.enter.native="pressEnter"
    @change.native="change"
    @focus.native="focus"
    @blur.native="blur"
    :guide="false"
    placeholderChar="#"
  >
  </masked-input>
</template>

<script>
import createNumberMask from "text-mask-addons/dist/createNumberMask";
var numeral = require("numeral");
export default {
  name: "v_input_money",

  props: {
    value: {
      required: true,
      default: ""
    }
  },

  data() {
    return {
      numberValue: this.number2price(this.$props.value),
      mask: createNumberMask({
        prefix: "",
        thousandsSeparatorSymbol: " ",
        allowDecimal: false,
        requireDecimal: false
      })
    };
  },
  watch: {
    value: function(value) {
      if (value != this.price2Number(this.numberValue))
        this.numberValue = this.number2price(this.$props.value);
    }
  },
  methods: {
    numeral(value) {
      return numeral(value).format("0,0");
    },
    price2Number(price) {
      if (price == "") this.numberValue = "";
      return typeof price == "string"
        ? price == ""
          ? undefined
          : Number(price.replace(/\s/g, ""))
        : price;
    },
    number2price(number) {
      return typeof number == "number"
        ? this.numeral(number)
        : (typeof number == "string" && number == "") || number == null
        ? ""
        : number;
    },
    input(value) {
      this.numberValue = value;
      if (this.value && this.numeral(this.value) == value) return;
      this.$emit("input", this.price2Number(value));
    },
    change(e) {
      this.numberValue = this.numeral(e.target.value);
      this.$emit("change", this.price2Number(e.target.value));
    },
    pressEnter() {
      this.$emit("keyupEnter");
    },
    focus() {
      this.$emit("focus");
    },
    blur() {
      this.$emit("blur");
    }
  }
};
</script>

<style lang="scss" scoped></style>
