function getTableFilters(type) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "GET",
      `params/${type}/filters`,
      {},
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function createTableFilter(type, name, params) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "POST",
      `params/${type}/filters`,
      { name, params, isChecked: true },
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function updateTableFilter(type, id, { params, name }) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "PATCH",
      `params/${type}/filters/${id}`,
      { params, name, isChecked: true },
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function updateCheckedFilter(type, id, isChecked) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "PATCH",
      `params/${type}/filters/${id}`,
      { isChecked },
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function deleteTableFilter(type, id) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "DELETE",
      `params/${type}/filters/${id}`,
      {},
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
function reorderFilters(type, filters) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "patch",
      `params/${type}/filters`,
      { filters: filters.map((({ id }, ind) => ({ id, sort: ind }))) },
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

export {
  getTableFilters,
  createTableFilter,
  updateTableFilter,
  updateCheckedFilter,
  deleteTableFilter,
  reorderFilters
};