import Vue from "vue";
import Vuex from "vuex";
import moment from "moment";
import numeral from "numeral";
import createPersistedState from "vuex-persistedstate";
import { createSocket } from "./plugins/socket";
import { prepareThemes, setTheme } from "./services/colors-api";
import _ from "lodash";

Vue.use(Vuex);

const checkIsMobileDevice = () => {
  var ua = window.navigator.userAgent;
  var p = window.navigator.platform;
  var iphone = ~ua.indexOf("iPhone");
  var ipod = ~ua.indexOf("iPod");
  var ipad = ~ua.indexOf("iPad");
  var blackberry = ~ua.indexOf("BlackBerry");
  var android = /Android (\d+(?:\.\d+)*)/.test(ua);
  
  if (/iPad|iPhone|iPod/.test(p) || iphone || ipad || ipod || blackberry || android) {
    return true;
  } else {
    return !!window.navigator.maxTouchPoints && window.navigator.maxTouchPoints > 2 && /MacIntel/.test(p);
  }
}

const defaultState = {
  // ГЛАВНОЕ
  user: {
    id: "",
    name: "",
    phones: [],
    email: "",
    accessToken: "",
    access: [],
    role: "",
    shopsAccess: [],
    inn: "",
    position: "",
    userHashId: "",
    isCreator: false
  },
  activeShop: "",
  isDemo: false,
  newsLink: "",
  isNew: false,
  // ПАРАМЕТРЫ ДОСТУПА
  isClearing: false,
  isAccessLock: false,
  dateLock: null,
  isShowAccessEnd: true,
  searchQueries: {},
  currency: {
    name: "Рубль",
    value: "руб"
  },
  version: process.env.VUE_APP_VERSION,
  // МАССИВЫ ДЛЯ ПЕРЕМЕЩЕНИЯ ДАННЫХ МЕЖДУ ВКЛАДКАМИ
  documentsTemplates: {
    purchase: null
  },
  moveToDocument: {
    purchase: null
  },
  arrayToCancellation: { store: null, data: [] },
  postingToReturn: [],
  toCart: [],
  companyHashId: "",
  // Чтобы показывалось только одно сообщение о том, что идёт обновление
  isShowUpdateMessage: false,
  notSavedOrderData: null,
  lastFormData: {
    counteragent: null
  },
  documents: {
    posting: [],
    cancellation: [],
    move: [],
    purchaseReturn: [],
    saleReturn: [],
    inventory: [],
    deleted: {
      posting: [],
      cancellation: [],
      move: [],
      purchaseReturn: [],
      saleReturn: [],
      inventory: []
    }
  },
  kkmWindowState: null,
  isShowReferal: false,
  prices: [],
  clientNotificationParams: null,
  exportData: null,
  pageFilters: {},
  isAccessUpdate: false,
  tableSettings: {},
  tableFilters: { order: undefined },
  tableParamHash: {},
  isFontSizeChanged: false,
  isNotVerified: false,
  isNavigationBlocked: false,
  navigationPath: "",
  isMobileDevice: checkIsMobileDevice(),
  kkmCancelPayment: false,
  kkmTryRepeat: false,
  restoreToken: "",
  defaultMenuWidth: 190,
  menuWidth: 190,
  isLicensePayment: false,
  socketId: null,
  activeTheme: null,
  font: "PT Root UI",
  fontSize: 13,
  themeClasses: ""
};

export default new Vuex.Store({
  state: defaultState,
  ///////////////////////////////////////////////////////////////
  // ТИПО ВЫЧИСЛЯЕМЫХ СВОЙСТВ ДЛЯ ХРАНИЛИЩА
  ///////////////////////////////////////////////////////////////
  getters: {
    isAccessLock: state => {
      if (!state.dateLock) return false;
      if (moment(new Date()) > moment(state.dateLock)) return true;
      else if (
        state.isShowAccessEnd &&
        moment().add(3, "d") > moment(state.dateLock)
      )
        return true;
      else return false;
    },
    access: state => {
      return (
        (state.user &&
          state.user.access &&
          state.user.access.reduce((obj, item) => {
            obj[item] = true;
            return obj;
          }, {})) ||
        {}
      );
    },
    activeStores: state => {
      return state.activeShop &&
        state.user &&
        Array.isArray(state.user.shopsAccess) &&
        state.user.shopsAccess.find(shop => shop.id === state.activeShop)
        ? state.user.shopsAccess.find(shop => shop.id === state.activeShop)
            .storesAccess || []
        : [];
    },
    stores: state => {
      return state.user?.shopsAccess?.flatMap?.(({ storesAccess, id, name }) => storesAccess.map(store => ({ ...store, shopId: id, shopName: name })) || []) || []
    },
    activeShop: state => {
      return state.user?.shopsAccess?.find?.(({id}) => id === state.activeShop)
    },
    isLeftMenu: state => {
      return ~state.menuWidth
    }
  },
  ///////////////////////////////////////////////////////////////
  // СИНХРОННЫЕ ОПЕРАЦИИ БЕЗ ВОЗМОЖНОСТИ ВЛИЯТЬ НА АПИ
  ///////////////////////////////////////////////////////////////
  mutations: {
    setThemeClasses(store, classes) {
      store.themeClasses = classes
    },
    setIsLicensePayment(store, value) {
      store.isLicensePayment = value
    },
    setMenuWidth(store, menuWidth) {
      store.menuWidth = menuWidth
    },
    kkmCancelPayment(store, value) {
      store.kkmCancelPayment = value
    },
    kkmTryRepeat(store, value) {
      store.kkmTryRepeat = value
    },
    exportBegin(store, params) {
      store.exportData = params;
    },
    changeShowReferal(store, value) {
      store.isShowReferal = value;
    },
    setKkmWindowState(state, kkmState) {
      const { key } = kkmState
      state.kkmWindowState = key !== 'close'
        ? kkmState
        : null;
    },
    notSavedDataUpdate(state, {type, blocks, formType}) {
      if (formType) {
        if (blocks) {
          state.lastFormData[formType] = {
            blocks,
            type
          }
        } else delete state.lastFormData[formType]
      }
    },
    setSocketId(state, socketId) {
      state.socketId = socketId
    },
    // АВТОРИЗАЦИЯ
    login(state, user) {
      if (!user.access) user.access = [];
      if (!user.params) user.params = {};
      if (!user.accessHash) user.accessHash = "";
      state.pageFilters = {};

      if (user.secondId) state.companyHashId = user.secondId;

      if (user.prices) {
        state.prices = user.prices;
        delete user.prices;
      }
      state.newsLink = user.newsLink || "";
      state.isNew = user.isNew || "";
      state.isNotVerified = user.isNotVerified || false;
      state.user = user;
      if (user.theme) {
        state.font = user.theme.font.name
        state.fontSize = user.theme.font.size || 13
        state.menuWidth = user.theme.menuWidth || this.defaultMenuWidth;
        if (user.theme.items) {
          prepareThemes(user.theme.items)
          state.activeTheme = user.theme.items.length > 1
            ? user.theme.items
            : user.theme.items[0]
        }
      }
      if (
        state.activeShop &&
        user.shopsAccess &&
        user.shopsAccess.find(shop => shop.id === state.activeShop)
      ) {
        // Если активная локация уже есть, и доступ к ней не снят, пусть остается как есть
      } else if (user.shopsAccess && user.shopsAccess && user.shopsAccess[0]) {
        // В другом случае при изменении доступа активной становится первая точка
        state.activeShop = user.shopsAccess[0].id;
      }
      if (!user.withoutSocket) {
        if (Vue.prototype.$socket) {
          if (Vue.prototype.$socket.connected) Vue.prototype.$socket.disconnect();
          Vue.prototype.$socket.removeAllListeners();
          delete Vue.prototype.$socket
          state.socketId = null
        }
        setTimeout(() => {
          const {socket, socketId} = createSocket(user.accessToken)
          Vue.prototype.$socket = socket
          state.socketId = socketId
        }, 1000)
      }
    },
    // ДЕАВТОРИЗАЦИЯ И ОЧИСТКА ДАННЫХ
    logout(state) {
      state.user = {
        id: "",
        name: "",
        phones: [],
        accessToken: "",
        access: [],
        role: "",
        shopsAccess: [],
        userHashId: "",
        isCreator: false
      };
      state.isAccessLock = false;
      state.dateLock = null;
      state.postingToReturn = [];
      state.toCart = [];
      state.searchQueries = {};
      state.isDemo = false;
      state.activeShop = "";
      state.currency = {
        name: "Рубль",
        value: "руб"
      };
      state.companyHashId = "";
      state.prices = [];
      state.pageFilters = {};
      state.tableSettings = {};
      state.tableFilters = {};
      state.tableParamHash = "";
      if (Vue.prototype.$socket) {
        if (Vue.prototype.$socket.connected) Vue.prototype.$socket.disconnect();
        Vue.prototype.$socket.removeAllListeners();
        delete Vue.prototype.$socket
        state.socketId = null
      }
    },
    // ОБНОВЛЕНИЕ ДОСТУПОВ ПОЛЬЗОВАТЕЛЯ
    updateAccess(state, params) {
      var access = params.access;
      var shopsAccess = params.shopsAccess;
      var accessHash = params.accessHash;

      var name = params.name,
        phones = params.phones,
        surname = params.surname,
        patronymic = params.patronymic,
        role = params.role,
        inn = params.inn,
        position = params.position,
        email = params.email,
        companyName = params.companyName,
        prices = params.prices,
        companyHashId = params.secondId,
        userHashId = params.userHashId,
        newsLink = params.newsLink || "",
        isNew = params.isNew || "",
        isNotVerified = params.isNotVerified || false,
        customerId = params.customerId,
        isCreator = params.isCreator,
        theme = params.theme;

      if (companyHashId) {
        state.companyHashId = companyHashId;
      }

      if (prices) state.prices = prices;

      if (
        params.currency &&
        (!state.currency || state.currency.id !== params.currency.id)
      ) {
        state.currency = params.currency;
        if (numeral.locales[params.currency.value] === undefined) {
          numeral.register("locale", params.currency.value, {
            delimiters: {
              thousands: " ",
              decimal: "."
            },
            abbreviations: {
              thousand: "тыс.",
              million: "млн.",
              billion: "млрд.",
              trillion: "трл."
            },
            ordinal: function(number) {
              return number === 1 ? "er" : "ème";
            },
            currency: {
              symbol: params.currency.value
            }
          });
        }
        numeral.locale(params.currency.value);
      }

      state.user.access = access;
      state.user.shopsAccess = shopsAccess;
      state.user.accessHash = accessHash;
      state.user.userHashId = userHashId;
      state.user.isCreator = isCreator;
      if (state.newsLink !== newsLink) {
        state.newsLink = newsLink;
      }
      if (state.isNew !== isNew) {
        state.isNew = isNew;
      }
      if (state.isNotVerified !== isNotVerified) {
        state.isNotVerified = isNotVerified;
      }

      if (
        state.activeShop &&
        state.user.shopsAccess &&
        state.user.shopsAccess.find(shop => shop.id === state.activeShop)
      ) {
        // Если активная локация уже есть, и доступ к ней не снят, пусть остается как есть
      } else if (
        state.user.shopsAccess &&
        state.user.shopsAccess &&
        state.user.shopsAccess[0]
      ) {
        // В другом случае при изменении доступа активной становится первая точка
        state.activeShop = state.user.shopsAccess[0].id;
      }

      if (name) state.user.name = name;
      if (phones) state.user.phones = phones;
      if (surname) state.user.surname = surname;
      if (patronymic) state.user.patronymic = patronymic;
      if (role) state.user.role = role;
      if (inn) state.user.inn = inn;
      if (position) state.user.position = position;
      if (email) state.user.email = email;
      if (companyName) state.user.companyName = companyName;
      if (customerId) state.user.id = customerId;
      if (params.params) state.user.params = params.params;

      // Правильное сравнение с учетом того что может быть массив
      if (theme) {
        if (state.font !== theme.font.name) state.font = theme.font.name
        if (state.fontSize !== theme.font.size) state.fontSize = theme.font.size || 13
        if (state.menuWidth !== theme.menuWidth) state.menuWidth = theme.menuWidth || this.defaultMenuWidth;
        prepareThemes(theme.items)
        if (
          !_.isEqual(
            theme.items,
            Array.isArray(state.activeTheme)
              ? state.activeTheme
              : [state.activeTheme]
          )
        ) {
          state.activeTheme = theme.items.length > 1
            ? theme.items
            : theme.items[0]
        } else {
          setTheme(state.activeTheme, state.font, state.fontSize)
        }
      }
    },
    // ОБНОВЛЕНИЕ ДОСТУПНОСТИ САЙТА ПОЛЬЗОВАТЕЛЮ И ДАННЫХ ПО ТАРИФУ
    changeDemo(state, value) {
      state.isDemo = value;
    },
    setActiveShop(state, activeShop) {
      state.activeShop = activeShop;
    },
    lockUser(state) {
      state.isAccessLock = true;
    },
    unlockUser(state) {
      state.isAccessLock = false;
    },
    updateDateLock(state, date) {
      state.dateLock = date;
    },
    closeAccessShow(state) {
      state.isShowAccessEnd = false;
    },
    setClearing(state, value) {
      state.isClearing = value;
    },
    // ОБНОВЛЕНИЕ МАССИВОВ ДЛЯ ПЕРЕДАЧИ ДАННЫХ МЕЖДУ ВКЛАДКАМИ
    addToCart(state, array) {
      state.toCart = array;
    },
    deleteToCart(state) {
      state.toCart = [];
    },
    setDocumentTemplate(state, {type, params}) {
      state.documentsTemplates[type] = params || null
    },
    addToOtherDocument(state, {type, params}) {
      state.moveToDocument[type] = params || null
    },
    setArrayToCancellation(state, arrayToCancellation) {
      if (Array.isArray(arrayToCancellation)) {
        state.arrayToCancellation = { store: null, data: arrayToCancellation };
      } else if (arrayToCancellation) {
        if (arrayToCancellation.store)
          state.arrayToCancellation.store = arrayToCancellation.store;
        if (arrayToCancellation.data)
          state.arrayToCancellation.data = arrayToCancellation.data;
      } else {
        state.arrayToCancellation = { store: null, data: null };
      }
    },
    setPostingToReturn(state, postingToReturn) {
      state.postingToReturn = postingToReturn;
    },
    updateCurrency(state, currency) {
      if (currency && (!state.currency || state.currency.id !== currency.id)) {
        state.currency = currency;
        if (numeral.locales[currency.value] === undefined) {
          numeral.register("locale", currency.value, {
            delimiters: {
              thousands: " ",
              decimal: "."
            },
            abbreviations: {
              thousand: "тыс.",
              million: "млн.",
              billion: "млрд.",
              trillion: "трл."
            },
            ordinal: function(number) {
              return number === 1 ? "er" : "ème";
            },
            currency: {
              symbol: currency.value
            }
          });
        }
        numeral.locale(currency.value);
      }
    },
    // ОБНОВЛЕНИЕ ПАРАМЕТРОВ ПОЛЬЗОВАТЕЛЯ
    updateName(state, name) {
      state.user.name = name;
    },
    updateSurname(state, surname) {
      state.user.surname = surname;
    },
    updatePatronymic(state, patronymic) {
      state.user.patronymic = patronymic;
    },
    updateRole(state, name) {
      state.user.role = name;
    },
    updateInn(state, inn) {
      state.user.inn = inn;
    },
    updatePosition(state, position) {
      state.user.position = position;
    },
    // ФИЛЬТРЫ
    updateFilters(state, filters) {
      state.user.params.orderFilters = filters;
    },
    updateShopFilters(state, filters) {
      state.user.params.shopFilters = filters;
    },
    // ВЫБРАННЫЕ КОЛОНКИ В ТАБЛИЦАХ
    updateTableActiveColumns(state, data) {
      if (data.columns && Object.keys(data.columns).length > 0) {
        if (!state.user.params.columns) state.user.params.columns = {};
        if (data.type == "shop-provider") {
          state.user.params.columns.shopProvider = data.columns;
        } else if (data.type == "cart") {
          state.user.params.columns.cart = data.columns;
        } else if (data.type == "sellers") {
          state.user.params.columns.sellers = data.columns;
        } else {
          state.user.params.columns[data.type] = data.columns;
        }
      } else if (data.columns === null) {
        delete state.user.params.columns[data.type];
      }
    },
    updateParams(state, data) {
      if (data.type && data.params) {
        state.user.params[data.type + 'Params'] = data.params;
      } else if (data.type) {
        delete state.user.params[data.type + 'Params'];
      }
    },
    setCompanyHashId(state, companyHashId) {
      state.companyHashId = companyHashId;
    },
    // ВЕРСИЯ САЙТА
    updateVersion(state, version) {
      state.version = version;
    },
    // ПОИСКОВЫЕ ЗАПРОСЫ ДЛЯ ПОИСКОВОГО ИНПУТА
    searchQueries(state, { key, queries }) {
      state.searchQueries[key] = queries;
    },
    // КОЛИЧЕСТВО СООБЩЕНИЙ В CHANGELOG'e
    updateShowNews(state, value) {
      state.newsLink = value;
    },
    updateIsNew(state) {
      state.isNew = false;
    },
    setIsShowUpdateMessage(state, isShowUpdateMessage) {
      state.isShowUpdateMessage = isShowUpdateMessage;
    },
    addDocument(state, { type, doc, isDeleted }) {
      if (doc && typeof doc === "object" && !isDeleted)
        doc.shopId = state.activeShop;
      if (!state.documents) {
        state.documents = {
          posting: [],
          cancellation: [],
          move: [],
          purchaseReturn: [],
          saleReturn: [],
          inventory: [],
          deleted: {
            posting: [],
            cancellation: [],
            move: [],
            purchaseReturn: [],
            saleReturn: [],
            inventory: []
          }
        };
        if (isDeleted) state.documents.deleted[type].push(doc);
        else state.documents[type].push(doc);
      } else if (!isDeleted) {
        if (!Array.isArray(state.documents[type])) {
          state.documents[type] = [doc];
        } else {
          state.documents[type].push(doc);
        }
      } else if (!state.documents.deleted) {
        state.documents.deleted = {
          posting: [],
          cancellation: [],
          move: [],
          purchaseReturn: [],
          saleReturn: [],
          inventory: []
        };
        state.documents.deleted[type].push(doc);
        if (Array.isArray(state.documents[type])) {
          let docInd = state.documents[type].findIndex(
            element => element.id === doc
          );
          if (~docInd) {
            state.documents[type].splice(docInd, 1);
          }
        }
      } else if (!Array.isArray(state.documents.deleted[type])) {
        state.documents.deleted[type] = [doc];
        if (Array.isArray(state.documents[type])) {
          let docInd = state.documents[type].findIndex(
            element => element.id === doc
          );
          if (~docInd) {
            state.documents[type].splice(docInd, 1);
          }
        }
      } else {
        state.documents.deleted[type].push(doc);
        if (Array.isArray(state.documents[type])) {
          let docInd = state.documents[type].findIndex(
            element => element.id === doc
          );
          if (~docInd) {
            state.documents[type].splice(docInd, 1);
          }
        }
      }
    },
    clearDocuments(state, { type, doc, isDeleted }) {
      if (!state.documents) {
        state.documents = {
          posting: [],
          cancellation: [],
          move: [],
          purchaseReturn: [],
          saleReturn: [],
          inventory: [],
          deleted: {
            posting: [],
            cancellation: [],
            move: [],
            purchaseReturn: [],
            saleReturn: [],
            inventory: []
          }
        };
      } else if (!isDeleted) {
        if (!Array.isArray(state.documents[type])) {
          state.documents[type] = [];
        } else {
          let docInd = state.documents[type].findIndex(
            element => element.id === doc.id
          );
          if (~docInd) {
            state.documents[type].splice(docInd, 1);
          }
        }
      } else if (!state.documents.deleted) {
        state.documents.deleted = {
          posting: [],
          cancellation: [],
          move: [],
          purchaseReturn: [],
          saleReturn: [],
          inventory: []
        };
      } else if (!Array.isArray(state.documents.deleted[type])) {
        state.documents.deleted[type] = [];
      } else {
        let docInd = state.documents.deleted[type].findIndex(
          element => element.id === doc.id
        );
        if (~docInd) {
          state.documents.deleted[type].splice(docInd, 1);
        }
      }
    },
    setPrices(state, prices) {
      state.prices = prices;
    },
    updateWageGroupMode(state, value) {
      if (state.user && state.user.params)
        state.user.params.wageGrouping = value;
    },
    updateModifiesWidth(state, value) {
      if (state.user && state.user.params)
        state.user.params.modifiesWidth = value;
    },
    updateShopContentType(state, value) {
      if (state.user && state.user.params)
        state.user.params.shopContentType = value;
    },
    updateWhatsappLinkType(state, value) {
      if (state.user && state.user.params) {
        state.user.params.whatsappLinkType = value;
      }
    },
    updatePaymentPeriod(state, value) {
      if (state.user && state.user.params)
        state.user.params.paymentPeriod = value;
    },
    updateOrderGaranteeType(state, value) {
      if (state.user && state.user.params)
        state.user.params.orderGaranteeType = value;
    },
    updateMenuWidth(state, value) {
      state.menuWidth = value
      if (state.user && state.user.params)
        state.user.params.menuWidth = value;
    },
    updateHideFilters(state, value) {
      if (state.user && state.user.params)
        state.user.params.isHideFilters = value;
    },
    updateNotificationsOpened(state, value) {
      if (state.user && state.user.params)
        state.user.params.notificationsOpened = value;
    },
    updateExportFields(state, {type, value}) {
      if (state.user && state.user.params) {
        if (!state.user.params.export) state.user.params.export = {};
        state.user.params.export[type] = value;
      }
    },
    updateShopsSort(state, shopsAccess) {
      if (state.user && state.user.shopsAccess)
        state.user.shopsAccess = shopsAccess;
    },
    newClientEvent(state, params) {
      state.clientNotificationParams = params;
    },
    setTariff(state, tariff) {
      state.user.tariff = tariff;
    },
    updatePageFilters(state, {place, params}) {
      state.pageFilters[place] = params;
    },
    accessUpdate(state, value) {
      state.isAccessUpdate = value;
    },
    updateTableSettings(state, { tableType, columns, fontSize }) {
      state.tableSettings[tableType] = { columns, fontSize };
    },
    deleteTableSettings(state, tableType) {
      delete state.tableSettings[tableType];
    },
    updateColumnWidth(state, { tableType, name, width}) {
      let columns = state.tableSettings[tableType]?.columns;
      let col = columns?.find(col => col.name === name);
      if (col) col.width = width;
    },
    resizeFontTable(state, {tableType, fontSize}) {
      let tableSettings = state.tableSettings[tableType];
      if (tableSettings) tableSettings.fontSize = fontSize;
      state.isFontSizeChanged = true;
    },
    updatedFontSize(state) {
      state.isFontSizeChanged = false;
    },
    clearTableSettings(state, paramsHash) {
      state.tableParamHash = paramsHash;
      state.tableSettings = {};
    },
    updateParamsHash(state, paramsHash) {
      state.tableParamHash = paramsHash;
    },
    downloadTableFilters(store, payload) {
      if (!store.tableFilters) store.tableFilters = {};
      store.tableFilters[payload.tableType] = payload.filters;
    },
    createTableFilter(store, payload) {
      store.tableFilters?.[payload.tableType]?.forEach(filter => filter.isChecked = false);
      store.tableFilters?.[payload.tableType]?.push(payload.filter);
    },
    updateTableFilter(store, payload) {
      let index = store.tableFilters?.[payload.tableType]?.findIndex(({id}) => id === payload.id);
      if (~index) {
        if (payload.params) store.tableFilters[payload.tableType][index].params = payload.params;
        if (payload.name) store.tableFilters[payload.tableType][index].name = payload.name;
      }
    },
    deleteTableFilter(store, payload) {
      let index = store.tableFilters?.[payload.tableType]?.findIndex(({id}) => id === payload.id);
      if (~index) store.tableFilters[payload.tableType]?.splice(index, 1);
    },
    setActiveFilter(store, payload) {
      store.tableFilters?.[payload.tableType]?.forEach(
        filter => filter.isChecked = filter.id === payload.filterId
      );
    },
    blockNavigation(store, payload) {
      store.isNavigationBlocked = payload
      store.navigationPath = ""
    },
    prepareNavigation(store, payload) {
      store.navigationPath = payload
    },
    restoreToken(store, token) {
      store.restoreToken = token
    },
    setTheme(state, theme) {
      state.activeTheme = theme
    },
    setFont(state, font) {
      state.font = font
    },
    setFontSize(state, fontSize) {
      state.fontSize = fontSize
    }
  },
  ///////////////////////////////////////////////////////////////
  // АСИНХРОННЫЕ ОПЕРАЦИИ ДЛЯ СИНХРОНИЗАЦИИ С АПИ
  ///////////////////////////////////////////////////////////////
  actions: {
    //Чисто для того, чтобы после мутации сохранить её результат в АПИ.
    //Функция CallAPI в параметрах, потому что вызывать иначе я её не умею :(
    updateFilters({ commit, state }, data) {
      commit("updateFilters", data.filters);
      data.function("put", "customers/params", { params: state.user.params });
    },
    updateShopFilters({ commit, state }, data) {
      commit("updateShopFilters", data.filters);
      data.function("put", "customers/params", { params: state.user.params });
    },
    updateTableActiveColumns({ commit, state }, data) {
      if (!data.type) return;
      commit("updateTableActiveColumns", data);
      data.function("put", "customers/params", { params: state.user.params });
    },
    updateParams({ commit, state }, data) {
      if (!data.type) return;
      commit("updateParams", data);
      data.function("put", "customers/params", { params: state.user.params });
    },
    updateShownCheck({ state }, data) {
      if (state.user && state.user.params)
        state.user.params.showCheck = data.value;
      data.function("put", "customers/params", { params: state.user.params });
    },
    updateWageGroupMode({ commit, state }, data) {
      commit("updateWageGroupMode", data.value);
      data.function("put", "customers/params", { params: state.user.params });
    },
    updateModifiesWidth({ commit, state }, data) {
      commit("updateModifiesWidth", data.value);
      data.function("put", "customers/params", { params: state.user.params });
    },
    updateShopContentType({ commit, state }, data) {
      commit("updateShopContentType", data.value);
      data.function("put", "customers/params", { params: state.user.params });
    },
    updateWhatsappLinkType({ commit, state }, data) {
      commit("updateWhatsappLinkType", data.value);
      data.function("put", "customers/params", { params: state.user.params });
    },
    updateNotificationsOpened({ commit, state }, data) {
      commit("updateNotificationsOpened", data.value);
      data.function("put", "customers/params", { params: state.user.params });
    },
    updateExportFields({ commit, state }, data) {
      commit("updateExportFields", data);
      data.function("put", "customers/params", { params: state.user.params });
    },
    updatePaymentPeriod({ commit, state }, data) {
      commit("updatePaymentPeriod", data.value);
      data.function("put", "customers/params", { params: state.user.params });
    },
    updateOrderGaranteeType({ commit, state }, data) {
      commit("updateOrderGaranteeType", data.value);
      data.function("put", "customers/params", { params: state.user.params });
    },
    updateMenuWidth({ commit, state }, data) {
      commit("updateMenuWidth", data.value);
      data.function("put", "customers/params", { params: state.user.params });
    },
    updateHideFilters({ commit, state }, data) {
      commit("updateHideFilters", data.value);
      data.function("put", "customers/params", { params: state.user.params });
    }
  },
  ///////////////////////////////////////////////////////////////
  // СИНХРОНИЗАЦИЯ С LOCALSTORAGE (ПОЯВИЛОСЬ ПОСЛЕ 1.9.7.4)
  ///////////////////////////////////////////////////////////////
  plugins: [
    createPersistedState({
      paths: [
        "companyHashId",
        "user",
        "currency",
        "activeShop",
        "prices",
        "isDemo",
        "version",
        "isAccessLock",
        "dateLock",
        "pageFilters",
        "newsLink",
        "isNotVerified",
        "menuWidth",
        "activeTheme",
        "font",
        "fontSize"
      ],
      getState(key, storage, value) {
        try {
          const state = (value = storage.getItem(key)) && typeof value !== 'undefined'
            ? JSON.parse(value)
            : undefined;
          if (
            state.dateLock &&
            typeof state.dateLock === "object" &&
            state.dateLock?.dateLock
          ) {
            state.dateLock = state.dateLock.dateLock
          }
          return state;
        } catch (err) {
          return undefined
        }
      },
      filter: ({ type }) => {
        if (
          type === "login" ||
          type === "logout" ||
          type === "updateAccess" ||
          type === "changeDemo" ||
          type === "setActiveShop" ||
          type === "lockUser" ||
          type === "unlockUser" ||
          type === "updateDateLock" ||
          type === "updateCurrency" ||
          type === "updateName" ||
          type === "updateSurname" ||
          type === "updatePatronymic" ||
          type === "updateRole" ||
          type === "updateInn" ||
          type === "updatePosition" ||
          type === "updateFilters" ||
          type === "updateShopFilters" ||
          type === "updateTableActiveColumns" ||
          type === "updateParams" ||
          type === "setCompanyHashId" ||
          type === "updateVersion" ||
          type === "setPrices" ||
          type === "updateWageGroupMode" ||
          type === "updateModifiesWidth" ||
          type === "updateShopContentType" ||
          type === "updateWhatsappLinkType" ||
          type === "updateNotificationsOpened" ||
          type === "updateExportFields" ||
          type === "updatePageFilters" ||
          type === "updateShopsSort" ||
          type === "updatePaymentPeriod" ||
          type === "updateOrderGaranteeType" ||
          type === "updateMenuWidth" ||
          type === "updateHideFilters" ||
          type === "updateShowNews" ||
          type === "setTheme" ||
          type === "setFont" ||
          type === "setFontSize"
        ) {
          return true;
        } else return false;
      }
    }),
    store => {
      var activeShop = JSON.parse(window.localStorage.getItem("activeShop"));
      if (activeShop) store.commit("setActiveShop", activeShop);

      var user = JSON.parse(window.localStorage.getItem("user"));
      if (user) {
        var isNotVerified = JSON.parse(window.localStorage.getItem("isNotVerified"));
        if (isNotVerified && isNotVerified !== store.state.isNotVerified)
          user.isNotVerified = isNotVerified;

        store.commit("login", { ...user, withoutSocket: true});
        if (!activeShop && user.shopsAccess && user.shopsAccess[0]) {
          store.commit("setActiveShop", user.shopsAccess[0].id);
        }
      }

      var companyHashId = window.localStorage.getItem("companyHashId");
      if (companyHashId) {
        store.commit("setCompanyHashId", companyHashId);
      }

      var prices = JSON.parse(window.localStorage.getItem("prices"));
      if (prices) {
        store.commit("setPrices", prices);
      }

      var isDemo = JSON.parse(window.localStorage.getItem("isDemo"));
      if (
        isDemo !== store.state.isDemo &&
        isDemo !== undefined &&
        isDemo !== null
      )
        store.commit("changeDemo", isDemo);

      var currency = JSON.parse(window.localStorage.getItem("currency"));
      if (currency && currency !== store.state.currency)
        store.commit("updateCurrency", currency);

      var newsLink = JSON.parse(window.localStorage.getItem("newsLink"));
      if (newsLink && newsLink !== store.state.newsLink)
        store.commit("updateShowNews", newsLink);

      // var menuWidth = JSON.parse(window.localStorage.getItem("menuWidth"));
      // if (menuWidth && menuWidth !== store.state.menuWidth)
      //   store.commit("updateShowNews", menuWidth);

      var version = JSON.parse(window.localStorage.getItem("version"));
      if (version && version !== store.state.version)
        store.commit("updateVersion", version);

      if (window.localStorage.getItem("learning"))
        window.localStorage.removeItem("learning");
      if (window.localStorage.getItem("companyHashId"))
        window.localStorage.removeItem("companyHashId");
      if (window.localStorage.getItem("user"))
        window.localStorage.removeItem("user");
      if (window.localStorage.getItem("currency"))
        window.localStorage.removeItem("currency");
      if (window.localStorage.getItem("activeShop"))
        window.localStorage.removeItem("activeShop");
      if (window.localStorage.getItem("prices"))
        window.localStorage.removeItem("prices");
      if (window.localStorage.getItem("isDemo"))
        window.localStorage.removeItem("isDemo");
      if (window.localStorage.getItem("version"))
        window.localStorage.removeItem("version");
    }
  ]
});
