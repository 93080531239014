<template>
  <div class="pagination-table">
    <button
      class="full-left-button"
      :disabled="disabledPrevios"
      @click="changePage(1)"
    >
      <span class="ti-angle-double-left"></span>
    </button>
    <button
      class="full-left-button"
      :disabled="disabledPrevios"
      @click="changePage(params.page - 1)"
    >
      <span class="ti-angle-left"></span>
    </button>

    <b class="pagination-label">
      {{ getFirstNumber }} - {{ getLastNumber }} из {{ params.total || 0 }}
    </b>

    <button
      class="full-left-button"
      :disabled="disabledNext"
      @click="changePage(params.page + 1)"
    >
      <span class="ti-angle-right"></span>
    </button>
    <button
      class="full-left-button"
      :disabled="disabledNext"
      @click="changePage(getLastPage)"
    >
      <span class="ti-angle-double-right"></span>
    </button>
  </div>
</template>
<script>
export default {
  name: "pagination",
  data() {
    return {};
  },
  props: {
    params: Object
  },
  computed: {
    getFirstNumber() {
      return this.params.total
        ? 1 + (this.params.page - 1) * this.params.pageSize
        : 0;
    },
    getLastNumber() {
      return this.params.page * this.params.pageSize < this.params.total
        ? this.params.page * this.params.pageSize
        : this.params.total;
    },
    disabledNext() {
      return this.params.page * this.params.pageSize >= this.params.total;
    },
    disabledPrevios() {
      return this.params.page < 2;
    },
    getLastPage() {
      return Math.ceil(this.params.total / this.params.pageSize);
    }
  },
  methods: {
    changePage(page) {
      this.$emit("update:page", page);
    }
  }
};
</script>
<style lang="scss">
.pagination-table {
  .full-left-button {
    font-size: 9px;
    font-weight: 600;
    padding-top: 1.5px;
    padding-bottom: 1.5px;
    padding-left: 3px;
    padding-right: 3px;
    background-color: lightgray;
    border: none;
    border-radius: 50%;
    float: left;
    margin-right: 5px;
    &:hover {
      background-color: darken(#d8d8d8, 15%);
    }
    &:active,
    &:focus {
      outline: 0;
    }
  }
  .pagination-label {
    font-size: 11px;
    display: inline-block;
    margin-top: 0px;
    margin-left: 5px;
    margin-right: 10px;
  }
}
</style>
