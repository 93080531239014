<template>
  <div class="v-scroll-table-wrapper">
    <v-scroll-2
      class="v-scroll-table"
      :scrollingX="true"
      :disabled="disabled"
      @scroll-to-bottom="scroll2Bottom"
    >
      <slot></slot>
    </v-scroll-2>
  </div>
</template>

<script>
export default {
  name: "v_scroll_table_wrapper",
  props: {
    disabled: Boolean
  },
  methods: {
    scroll2Bottom() {
      this.$emit("scrollToBottom");
    }
  }
};
</script>

<style lang="scss">
.v-scroll-table {
  &-wrapper {
    height: 100%;
    flex: 1;
    overflow: auto;
    position: relative;
    width: 100%;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.06), 0 10px 20px -5px rgba(0, 0, 0, 0.4);
  }
  .page-table {
    box-shadow: none;
  }
}
</style>
