function getTelegramBotLink() {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "get",
      "company/customers/telegram",
      {},
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
function getCounteragentsTelegramBotLink() {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "get",
      "counteragents/telegram",
      {},
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
function bindCustomerTelegram(customerId, code) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "put",
      `company/customers/${customerId}/telegram`,
      { code },
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
function bindNewCustomerTelegram(code) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "put",
      `company/customers/telegram`,
      { code },
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
function bindCounteragentTelegram(counteragentId, code) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "put",
      `counteragents/${counteragentId}/telegram`,
      { code },
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
function bindNewOrderTelegram(code) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "put",
      `counteragents/telegram`,
      { code },
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
function unbindCustomerTelegram(customerId) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "delete",
      `company/customers/${customerId}/telegram`,
      {},
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
function unbindCounteragentTelegram(counteragentId) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "delete",
      `counteragents/${counteragentId}/telegram`,
      {},
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
function sendCustomerTelegramSms(phone) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "post",
      "company/customers/telegram/sms",
      { phone },
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
function sendCounteragentTelegramSms(phone) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "post",
      "counteragents/telegram/sms",
      { phone },
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

export {
  getTelegramBotLink,
  getCounteragentsTelegramBotLink,
  bindCustomerTelegram,
  bindNewCustomerTelegram,
  bindCounteragentTelegram,
  bindNewOrderTelegram,
  unbindCustomerTelegram,
  unbindCounteragentTelegram,
  sendCustomerTelegramSms,
  sendCounteragentTelegramSms
};
