<template>
  <div>
    <v-export v-if="exportData" :params="exportData" @close="exportData = null" />
    <notify
      v-if="!!notificationParams"
      :notificationParams="notificationParams"
      @close="close"
    />
  </div>
</template>

<script>
export default {
  name: "api-events",
  components: {
    vExport: () => import('../livesklad/resources/v_export'),
    notify: () => import('./_client-notification'),
  },
  data() {
    return {
      events: [],
      notificationParams: null,
      exportData: null
    };
  },
  watch: {
    "$store.state.socketId"(newValue) {
      if (newValue) this.addSocketListeners()
    },
    "$store.state.exportData"(data) {
      if (data) {
        this.exportData = data;
        this.$store.commit("exportBegin", null);
      }
    },
    "$store.state.clientNotificationParams"(value) {
      if (value) {
        value.isChange = true;
        let { type, orderId, counteragentId, printDocuments } = value;
        if (orderId) {
          this.$backendService
            .getNotificationOrderTemplate(orderId, type)
            .then(body => {
              let data = Object.assign({}, value, body.data);
              if (printDocuments) data.printDocuments = printDocuments;
              if (!this.notificationParams && !this.events.length) {
                this.notificationParams = data;
              } else {
                this.events.push(data);
              }
              this.$store.commit("newClientEvent", null);
            });
        } else if (counteragentId) {
          this.$backendService
            .getNotificationCounteragentTemplate(counteragentId, type)
            .then(body => {
              let data = Object.assign({}, value, body.data);
              if (printDocuments) data.printDocuments = printDocuments;
              if (!this.notificationParams && !this.events.length) {
                this.notificationParams = data;
              } else {
                this.events.push(data);
              }
              this.$store.commit("newClientEvent", null);
            });
        } else {
          if (!this.notificationParams && !this.events.length) {
            this.notificationParams = value;
          } else {
            this.events.push(value);
          }
          this.$store.commit("newClientEvent", null);
        }
      }
    }
  },
  // sockets: {
  //   listNotification(items) {
  //     if (isActive) {
  //       items.forEach(item => {
  //         if (!item.type) {
  //           item.type = item.chatId ? "telegram" : item.email ? "email" : "sms";
  //         }
  //         if (typeof item.isActive !== "boolean") item.isActive = true;
  //       });
  //       this.events.push(...items);
  //       if (!this.notificationParams)
  //         this.notificationParams = this.events.shift();
  //     }
  //   },
  //   tariffPayEvent({dateLock}) {
  //     if (this.$store.state.isAccessLock) this.$store.commit("unlockUser");
  //     this.$store.commit("updateDateLock", dateLock);
  //   }
  // },
  methods: {
    listNotification(items) {
      if (!document.hidden) {
        items.forEach(item => {
          if (!item.type) {
            item.type = item.chatId ? "telegram" : item.email ? "email" : "sms";
          }
          if (typeof item.isActive !== "boolean") item.isActive = true;
        });
        this.events.push(...items);
        if (!this.notificationParams)
          this.notificationParams = this.events.shift();
      }
    },
    tariffPayEvent({dateLock}) {
      if (this.$store.state.isAccessLock) this.$store.commit("unlockUser");
      this.$store.commit("updateDateLock", dateLock);
    },
    close() {
      this.notificationParams = null;
      setTimeout(() => {
        if (this.events.length) this.notificationParams = this.events.shift();
      }, 1000);
    },
    addSocketListeners() {
      this.$socket.on("listNotification", this.listNotification)
      this.$socket.on("tariffPayEvent", this.tariffPayEvent)
    }
  },
  mounted() {
    this.addSocketListeners()
  },
  beforeDestroy() {
    this.$socket?.off?.("listNotification", this.listNotification)
    this.$socket?.off?.("tariffPayEvent", this.tariffPayEvent)
  }
};
</script>

<style lang="scss" scoped></style>
