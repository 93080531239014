<template>
  <div class="switcher">
    <slot name="header">
      <p
        v-if="placeholder"
        class="text-center small-text bold-text text-center"
        style="margin-bottom: 0;"
      >
        {{ placeholder }}
      </p>
    </slot>
    <div class="input-group-btn switcher-container pull-left">
      <button
        v-for="(option, index) in options"
        :key="index"
        class="btn btn-switcher"
        @click="change(index)"
        :class="[
          ' btn-' + color,
          active === index ? '' : ' btn-inverse',
          getType
        ]"
        :style="[btnWidth ? { width: btnWidth + 'px' } : {}]"
      >
        <slot name="option" :text="option">
          {{ option }}
        </slot>
      </button>
    </div>
    <slot name="middle"></slot>
    <div v-if="showActive" class="switcher-active-label pull-right">
      <slot name="active">{{
        options[active] === "нет" ? "" : options[active]
      }}</slot>
    </div>
  </div>
</template>
<style lang="scss">
.switcher {
  position: relative;
  display: inline-block;
  width: auto;
  .switcher-container {
    width: auto;
  }
  .btn.btn-switcher {
    margin-left: 0;
    border-radius: 0;
    z-index: 0 !important;
    &:focus,
    &:active {
      outline: 0;
    }
    &:first-of-type {
      border-radius: 4px 0 0 4px;
    }
    &:last-of-type {
      border-radius: 0 4px 4px 0;
    }
    &.btn-small {
      height: 25px;
      padding: 0;
      width: 30px;
      &.pages {
        width: 40px;
      }
      &.middle {
        vertical-align: middle;
      }
    }
  }
  .switcher-placeholder {
    font-size: 9px;
    margin-bottom: 0;
  }
  .switcher-active-label {
    margin-left: 5px;
    line-height: 25px;
  }
  .switcher-input {
    width: 60px;
    font-weight: 600;
    text-align: center;
    height: 25px;
    margin-left: 5px;
  }
}
</style>
<script>
export default {
  name: "switcher",
  data() {
    return {};
  },
  model: {
    prop: "active",
    event: "change"
  },
  props: {
    placeholder: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default: "info"
    },
    options: Array,
    active: Number,
    type: {
      type: String,
      default: "normal"
    },
    showActive: {
      type: Boolean,
      default: false
    },
    btnWidth: [Number, String]
  },
  computed: {
    getType() {
      if (this.$props.type === "small") {
        return "btn-small";
      } else if (this.$props.type === "pages") {
        return "btn-small pages";
      } else if (this.$props.type === "small middle") {
        return "btn-small middle";
      } else {
        return "";
      }
    }
  },
  watch: {
    active: function(newValue) {
      this.$emit("changed", newValue);
    }
  },
  methods: {
    change(newValue) {
      this.$emit("change", newValue);
    }
  },
  mounted() {}
};
</script>
