let masks = [
  // Номера начинающиеся с "8"
  {
    reg: "^8[3489]\\d{0,9}$",
    value: "d (ddd) ddd-dd-dd"
  },
  {
    reg: "^87\\d{0,9}$",
    value: "d (ddd) ddd-dddd",
  },
  // Украина
  {
    reg: "^\\+($|3$|38$|380\\d{0,9}$)",
    value: ".ddd (dd) ddd-dd-dd",
  },
  // Молдавия
  {
    reg: "^\\+($|3$|37$|373\\d{0,8}$)",
    value: ".ddd-dddd-dddd",
  },
  // Азербайджан
  {
    reg: "^\\+($|9$|99$|994\\d{0,9}$)",
    value: ".ddd (dd) ddd-dd-dd",
  },
  // Киргизия
  {
    reg: "^\\+($|9$|99$|996\\d{0,9}$)",
    value: ".ddd (ddd) dddddd",
  },
  // Узбекистан
  {
    reg: "^\\+($|9$|99$|998\\d{0,9}$)",
    value: ".ddd (dd) ddd-dddd",
  },
  // Белорусь
  {
    reg: "^\\+($|3$|37$|375\\d{0,9}$)",
    value: ".ddd (dd) ddd-dd-dd",
  },
  // Хорватия
  {
    reg: "^\\+($|3$|38$|385\\d{0,8}$)",
    value: ".ddd (dd) ddd-ddd",
  },
  // Германия
  {
    reg: "^\\+($|4$|49\\d{0,11}$)",
    value: ".dd (ddd) dddd-dddd",
  },
  {
    reg: "^\\+($|4$|49\\d{0,12}$)",
    value: ".dd (dddd) dddd-dddd",
  },
  {
    reg: "^\\+($|4$|49\\d{13,14}$)",
    value: ".dd (dddddd) dd-dddddd",
  },
  // Россия
  {
    reg: "^\\+($|7$|7[3489]\\d{0,9}$)",
    value: ".d (ddd) ddd-dd-dd",
  },
  {
    reg: "^\\+($|7$|77\\d{0,9}$)",
    value: ".d (ddd) ddd-dddd",
  },
  // Домашние маски
  {
    reg: "^\\d{4,5}$",
    value: "ddd-dd",
  },
  {
    reg: "^\\d{6}$",
    value: "dd-dd-dd",
  },
  {
    reg: "^\\d{7}$",
    value: "ddd-dd-dd",
  },
  {
    reg: "^[349]\\d{7,9}$",
    value: "ddd ddd-dd-dd",
  },
  {
    reg: "^7\\d{7,9}$",
    value: "ddd ddd-dddd",
  },
];
let stringToMask = function(str) {
  let mask = str
    .split("")
    .map((s) =>
      s === "." ? /./ : s === "w" ? /\w/ : s === "d" ? /\d/ : s
    );
  return mask;
};
let toMaskedPhone = function(mask, str) {
  let j = 0, res = "";
  for (let i = 0; i < mask.length; i++) {
    if (mask[i] === "d" || mask[i] === ".") {
      res += str[j++];
      if (j === str.length) break;
    } else res += mask[i];
  }
  return res;
}
export function getPhoneMask(rawValue) {
  var number = rawValue.replace(/[^+\d]/gm, "");
  let mask = masks.find(({ reg }) => new RegExp(reg).test(number));
  return mask
    ? stringToMask(mask.value)
    : new Array(number.length).fill(1).map(() => /[+\d]/);
}
export function formatPhone(rawValue) {
  if (!rawValue) return rawValue;
  var number = rawValue.replace(/\D/gm, "");
  if (number[0] !== "8" && (number.length === 11 || number.length === 12)) number = `+${number}`;
  let mask = masks.find(({ reg }) => new RegExp(reg).test(number));
  return mask
    ? toMaskedPhone(mask.value, number)
    : rawValue;
}