function getCashItems() {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "get",
      "cash-items",
      { pageSize: 50 },
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function createCashItem(params) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "post",
      "cash-items",
      params,
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function updateCashItem(cashItemId, params) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "patch",
      `cash-items/${cashItemId}`,
      params,
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function deleteCashItem(cashItemId) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "delete",
      `cash-items/${cashItemId}`,
      {},
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

export {
  getCashItems,
  createCashItem,
  updateCashItem,
  deleteCashItem
};
