function getTransaction(cashId) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "get",
      `cash/${cashId}`,
      {},
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
function createTransaction(cashRegisterId, type, params) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "post",
      `cash-registers/${cashRegisterId}/${
        type === "money" ? "money" : "bank"
      }`,
      {
        ...params,
        cashRegisterId: undefined,
        money: params[type === "money" ? "money" : "bank"]
      },
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
function checkTransactionConfirm(cashId, fiscalRegisterId) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "get",
      `cash/${cashId}/confirm`,
      {
        fiscalRegisterId
      },
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
function setTransactionConfirm(cashId, params) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "post",
      `cash/${cashId}/confirm`,
      params,
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function updateTransaction(cashId, obj) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "patch",
      `cash/${cashId}`,
      obj,
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function deleteTransaction(cashId, params = {}) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "delete",
      `cash/${cashId}`,
      params,
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function getDocumentInfo(cashId, params = {}) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "get",
      `cash/${cashId}/document`,
      params,
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function confirmTerminal(cashId, transactionId, isCancel) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "post",
      `cash/${cashId}/terminal${isCancel ? '/cancel' : ''}`,
      { [ isCancel ? 'cancelTransactionId' : 'transactionId' ] : transactionId },
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

export {
  getTransaction,
  createTransaction,
  checkTransactionConfirm,
  setTransactionConfirm,
  updateTransaction,
  deleteTransaction,
  getDocumentInfo,
  confirmTerminal
}