function getAllShops() {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "get",
      "company/shops",
      {},
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function sortShops(shopIds) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "put",
      "company/shops/sort",
      { shopIds },
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

// function getCustomer(userId) {
//   return new Promise((resolve, reject) => {
//     this._api.callAPI(
//       "get",
//       `company/customers/${userId}`,
//       {},
//       response => {
//         resolve(response.body);
//       },
//       error => {
//         reject(error);
//       }
//     );
//   });
// }

// function getCustomerTemplate() {
//   return new Promise((resolve, reject) => {
//     this._api.callAPI(
//       "get",
//       "company/customers/template",
//       {},
//       response => {
//         resolve(response.body);
//       },
//       error => {
//         reject(error);
//       }
//     );
//   });
// }

// function createCustomer(params) {
//   return new Promise((resolve, reject) => {
//     this._api.callAPI(
//       "post",
//       "company/customers",
//       params,
//       response => {
//         resolve(response.body);
//       },
//       error => {
//         reject(error);
//       }
//     );
//   });
// }

// function updateCustomer(userId, params) {
//   return new Promise((resolve, reject) => {
//     this._api.callAPI(
//       "patch",
//       `company/customers/${userId}`,
//       params,
//       response => {
//         resolve(response.body);
//       },
//       error => {
//         reject(error);
//       }
//     );
//   });
// }

// function confirmCustomerTelegram(userId, phone) {
//   return new Promise((resolve, reject) => {
//     this._api.callAPI(
//       "put",
//       "company/customers/telegram",
//       {
//         phone: phone,
//         customerId: userId
//       },
//       response => {
//         resolve(response.body);
//       },
//       error => {
//         reject(error);
//       }
//     );
//   });
// }

// function deleteCustomer(userId) {
//   return new Promise((resolve, reject) => {
//     this._api.callAPI(
//       "delete",
//       `company/customers/${userId}`,
//       {},
//       response => {
//         resolve(response.body);
//       },
//       error => {
//         reject(error);
//       }
//     );
//   });
// }

// function getRoles({ isAll = undefined } = {}) {
//   return new Promise((resolve, reject) => {
//     this._api.callAPI(
//       "get",
//       "company/roles",
//       { isAll },
//       response => {
//         resolve(response.body);
//       },
//       error => {
//         reject(error);
//       }
//     );
//   });
// }

// function getRole(roleId) {
//   return new Promise((resolve, reject) => {
//     this._api.callAPI(
//       "get",
//       `company/roles/${roleId}`,
//       {},
//       response => {
//         resolve(response.body);
//       },
//       error => {
//         reject(error);
//       }
//     );
//   });
// }

// function getRoleTemplate() {
//   return new Promise((resolve, reject) => {
//     this._api.callAPI(
//       "get",
//       "company/roles/template",
//       {},
//       response => {
//         resolve(response.body);
//       },
//       error => {
//         reject(error);
//       }
//     );
//   });
// }

// function createRole(params) {
//   return new Promise((resolve, reject) => {
//     this._api.callAPI(
//       "post",
//       "company/roles",
//       params,
//       response => {
//         resolve(response.body);
//       },
//       error => {
//         reject(error);
//       }
//     );
//   });
// }

// function updateRole(roleId, params) {
//   return new Promise((resolve, reject) => {
//     this._api.callAPI(
//       "patch",
//       `company/roles/${roleId}`,
//       params,
//       response => {
//         resolve(response.body);
//       },
//       error => {
//         reject(error);
//       }
//     );
//   });
// }

// function deleteRole(roleId) {
//   return new Promise((resolve, reject) => {
//     this._api.callAPI(
//       "delete",
//       `company/roles/${roleId}`,
//       {},
//       response => {
//         resolve(response.body);
//       },
//       error => {
//         reject(error);
//       }
//     );
//   });
// }
export {
  getAllShops,
  sortShops
  // getCustomer,
  // getCustomerTemplate,
  // createCustomer,
  // updateCustomer,
  // confirmCustomerTelegram,
  // deleteCustomer,
  // getRoles,
  // getRole,
  // getRoleTemplate,
  // createRole,
  // updateRole,
  // deleteRole
};
