import numeral from "numeral";
import moment from "moment";
var mixin = {
  computed: {
    currency() {
      return (
        (this.$store.state.currency && this.$store.state.currency.value) ||
        "руб."
      );
    },
    currencySymbol() {
      return (
        (this.$store.state.currency && this.$store.state.currency.icon) || "₽"
      );
    }
  },
  methods: {
    ///////////////////////////////////////////////////////////////////////
    // МЕТОД ДЛЯ ОТПРАВЛЕНИЯ ЗАПРОСОВ В АПИ
    ///////////////////////////////////////////////////////////////////////
    callAPI(method, url, data, respFun, errorFun) {
      // ЕСЛИ ИДЕТ ОЧИСТКА СИСТЕМЫ, НИКАКИЕ ЗАПРОСЫ В ЭТО ВРЕМЯ ПОСЫЛАТЬ НЕЛЬЗЯ!
      if (this.$store.state.isClearing) return;

      // ЧАСТЬ ФУНКЦИЙ ЗАВЯЗАНЫ НА АКТИВНОЙ ЛОКАЦИИ, НО ЗДЕСЬ ПРОВЕРКА ДОСТУПОВ НЕ НУЖНА
      // ОНА ИМЕЕТ МЕСТО БЫТЬ ПРИ ОБНОВЛЕНИИ ДОСТУПОВ

      // УСТАНАВЛИВАЕМ ПАРАМЕТРЫ В ЗАВИСИМОСТИ ОТ ТИПА ЗАПРОСА
      var props = {
        url: url,
        method: method.toUpperCase()
      };
      if (props.method === "GET") {
        if (data.filter) {
          data.filter = data.filter.replace(/ё/g, "е").replace(/Ё/g, "Е");
        }
        props.params = data;
      } else {
        props.body = data;
      }
      // САМ ЗАПРОС
      return this.$http(props).then(
        response => {
          // ОБНОВЛЕНИЕ ВЕРСИИ В СООТВЕТСТВИИ С ПОЛУЧЕННОЙ ОТ АПИ
          if (
            (~response.url.indexOf("keenetic") ||
            ~response.url.indexOf("livesklad.com")) &&
            !~response.url.indexOf("images.livesklad.com")
          ) {
            if (
              response.body.version &&
              (!this.$store.state.version ||
                this.$store.state.version !== response.body.version)
            ) {
              this.$store.commit("updateVersion", response.body.version);
              if (
                ~response.url.indexOf("login") &&
                response.ok === true &&
                respFun
              ) {
                respFun(response);
                setTimeout(() => {
                  location.reload();
                }, 500);
              } else {
                location.reload();
              }
              return;
            }
            // ЕСЛИ ОБНОВИЛАСЬ ДАТА БЛОКИРОВКИ, НУЖНО ЕЁ ПЕРЕСЧИТАТЬ
            if (response.body.dateLock !== this.$store.state.dateLock) {
              this.$store.commit("updateDateLock", response.body.dateLock);
            }
            // ЕСЛИ ДОСТУП ЗАБЛОКИРОВАН В БАЗЕ, МНЕ НУЖНО ЭТО ПОКАЗАТЬ
            if (response.body.isAccessLock && !this.$store.state.isAccessLock) {
              this.$store.commit("lockUser");
              // И НАОБОРОТ ПРИ РАЗБЛОКИРОВКЕ
            } else if (
              !response.body.isAccessLock &&
              this.$store.state.isAccessLock
            ) {
              this.$store.commit("unlockUser");
            }
            // ЕСЛИ ЭТА ФУНКЦИЯ ПРЕДПОЛАГАЕТ ОБНОВЛЕНИЕ ХЭША И ХЭШ УСТАРЕЛ, ЕГО НУЖНО ОБНОВИТЬ
            if (
              (response.body.accessHash &&
                response.body.accessHash !==
                  this.$store.state.user.accessHash) ||
              (!this.$store.state.user &&
                !~response.url.indexOf("login") &&
                !~response.url.indexOf("logout")) ||
              (!this.$store.state.user.userHashId &&
                !~response.url.indexOf("login") &&
                !~response.url.indexOf("logout"))
            ) {
              this.loadCustomerAccess(
                response.body.accessHash,
                this.$store.state.user.accessHash
              );
            }
            if (response.body.paramHash && response.body.paramHash !== this.$store.state.tableParamHash) {
              if (
                this.$store.state.tableParamHash &&
                !/params\/.*\/fields/.test(url) &&
                !/params\/.*\/filters/.test(url)
              ) {
                this.$store.commit("clearTableSettings", response.body.paramHash);
              } else {
                this.$store.commit("updateParamsHash", response.body.paramHash);
              }
            }
            // ЕСЛИ В СИСТЕМЕ ДЕМОНСТРАЦИОННЫЕ ДАННЫЕ, ПОКАЗАТЬ КНОПКУ ОЧИСТКИ
            if (
              ~response.url.indexOf("livesklad") &&
              !~response.url.indexOf("images") &&
              !~response.url.indexOf("print") &&
              (response.body.isClear || false) !== this.$store.state.isDemo
            ) {
              this.$store.commit("changeDemo", response.body.isClear || false);
            }
          }
          // ЕСЛИ ВРОДЕ БЫ ВСЁ НИШТЯК, МОЖНО ЗАПУСТИТЬ ОСНОВНОЙ ОБРАБОТЧИК
          if (response.ok === true) {
            if (respFun) respFun(response);
          }
        },
        error => {
          if (errorFun) {
            let res = errorFun(error);
            if (res) return;
          }
          // ОБНОВЛЕНИЕ ВЕРСИИ В СООТВЕТСТВИИ С ПОЛУЧЕННОЙ ОТ АПИ
          if (
            error.body &&
            error.body.version &&
            (!this ||
              !this.$store ||
              !this.$store.state ||
              !this.$store.state.version ||
              this.$store.state.version !== error.body.version)
          ) {
            this.$store.commit("updateVersion", error.body.version);
            location.reload();
            return;
          }
          // ЕСЛИ ИСЧЕЗ ДОСТУП К ПРОГРАММЕ, УВЕДОМИТЬ ОБ ЭТОМ ПОЛЬЗОВАТЕЛЯ
          if (
            error.body &&
            error.body.error &&
            error.body.error.isAccessLock &&
            !~error.url.indexOf("trust-payment") &&
            error.status !== 555
          ) {
            this.$store.commit("lockUser");
            if (errorFun) errorFun(error);
            this.showMessage(
              "Программа находится в режиме ограниченного функционала",
              "У вас закончился доступ"
            );
            // ЕСЛИ ОШИБКА 401, ЛИБО ОНА ПРИ АВТОРИЗАЦИИ, ЛИБО КОГДА ПРОПАЛ ДОСТУП К ЭЛЕМЕНТУ,
            // ЛИБО ЭТО МОЖЕТ БЫТЬ ДРУГОЙ САЙТ, ТОГДА ВЫКИДЫВАТЬ НА LOGOUT НЕ НУЖНО!
          } else if (error.status === 401) {
            if (error.url.indexOf("login") === -1) {
              if (~error.url.indexOf("images.livesklad.com")) {
                this.showMessage("Ошибка доступа к картинкам");
              } else if (~error.url.indexOf("livesklad.com")) {
                if (
                  this.$store.state.user &&
                  this.$store.state.user.accessToken
                ) {
                  this.showMessage(
                    "Ошибка доступа"
                  );
                  this.logout();
                }
              } else {
                this.showMessage("Ошибка доступа");
              }
            } else {
              this.showMessage(
                "Не удалось выполнить вход. Проверьте правильность ввода логина и пароля и повторите попытку"
              );
            }
            if (errorFun) errorFun(error);
            // ОБЩАЯ ОШИБКА ВАЛИДАЦИИ ДАННЫХ
          } else if (error.status === 400) {
            if (errorFun) {
              let res = errorFun(error);
              if (res) return;
            }
            this.showMessage("Введенные данные не верны.");
            // ОШИБКА ОТСУТСТВИЯ ЭЛЕМЕНТА
          } else if (error.status === 404) {
            let res;
            if (errorFun) {
              res = errorFun(error);
            }
            if (!res) {
              if (~error.url.indexOf("images")) {
                this.showMessage(
                  "Картинка не найдена. Возможно, она уже была удалена"
                );
              } else {
                this.showMessage("Элемент не найден. Возможно, он уже удален.");
              }
            }
            // ОШИБКА СЕРВЕРА. ОБЫЧНО КОГДА В АПИ КОСЯК
          } else if (error.status === 500) {
            this.showMessage(
              "Пожалуйста, сообщите об этой ошибке тех. поддержке через онлайн чат в правом нижнем углу",
              "Ошибка сервера"
            );
            if (errorFun) errorFun(error);
            // ПРИ ОЧЕНЬ ДОЛГОЙ ОПЕРАЦИИ МОЖНО НЕ ДОЖДАТЬСЯ ОТВЕТА ОТ СЕРВЕРА
          } else if (error.status === 504) {
            this.showMessage(
              "Ответ от сервера ещё не получен." +
                "Возможно, операция занимает дольше времени, чем ожидалось. Дождись завершения операции."
            );
            // УСТАРЕЛ ХЭШ ДОСТУПОВ???
          // } else if (error.status === 503) {
          //   this.loadCustomerAccess();
          //   if (errorFun) errorFun(error);
          //   // ПОЛЬЗОВАТЕЛЬСКАЯ ОШИБКА С ТЕКСТОМ
          // }
          } else if (error.status === 503 && ~error.url.indexOf("livesklad")) {
            this.showMessage(
              "Превышено максимальное количество запросов на сервер",
              "Ошибка!"
            );
          }
          else if (error.status === 555) {
            if (errorFun) {
              let res = errorFun(error);
              if (res) return;
            }
            if (error.body && error.body.error && error.body.error.details) {
              this.showMessage(error.body.error.details);
            } else {
              this.showMessage(
                "Пожалуйста, сообщите об этой ошибке тех. поддержке через онлайн чат в правом нижнем углу",
                "Ошибка сервера"
              );
            }
          } else if (error.status === 557) {
            if (errorFun) errorFun(error);
            this.showUpdateMessage(
              (error &&
                error.body &&
                error.body.error &&
                error.body.error.details) ||
                ""
            );
          } else {
            if (error.body && error.body.error && error.body.error.details) {
              if (typeof error.body.error.details === "string") {
                this.showMessage(error.body.error.details);
              } else {
                this.showMessage(
                  "Пожалуйста, сообщите об этой ошибке тех. поддержке через онлайн чат в правом нижнем углу",
                  "Ошибка сервера"
                );
              }
            } else if (~error.url.indexOf("livesklad")) {
              this.showMessage(
                "Возможно, у вас отсутствует подключение к интернету. Проверьте соединение"
              );
            }
          }
        }
      );
    },
    logout() {
      if (this.$route.path !== "/login") {
        this.callAPI("POST", "logout", {});
        this.$store.commit("logout");
        this.$router.push("/login");
      }
    },
    showUpdateMessage(message) {
      if (!this.$store.state.isShowUpdateMessage) {
        this.$store.commit("setIsShowUpdateMessage", true);
        this.showMessage(message, " ", "update");
      }
    },
    ///////////////////////////////////////////////////////////////////////
    // КОНТРОЛЬ ДОСТУПОВ ПОЛЬЗОВАТЕЛЯ
    ///////////////////////////////////////////////////////////////////////
    loadCustomerAccess(newHash) {
      // ЕСЛИ УЖЕ ИДЕТ ОБНОВЛЕНИЕ ДОСТУПОВ, ПОВТОРНО ВЫЗЫВАТЬ ЕГО НЕ НУЖНО, ИЛИ ИДЕТ РАЗАВТАРИЗАЦИЯ И ТОКЕН УЖЕ УДАЛЕН
      if (
        this.$store.state.isAccessUpdate ||
        !this.$store.state.user ||
        !this.$store.state.user.accessToken
      )
        return;
      this.$store.commit("accessUpdate", true);
      // СЧИТЫВАЕМ НОВЫЕ ДОСТУПЫ И ОБНОВЛЯЕМ ХРАНИЛИЩЕ
      return new Promise((resolve, reject) => {
        this.callAPI(
          "get",
          `customers/access`,
          {},
          response => {
            let data = response.body.data;
            if (data.apiCount) data.access.push("isApiTariff")
            this.$store.commit("updateAccess", {
              access: data.access,
              shopsAccess: data.shopsAccess,
              accessHash: response.body.accessHash || newHash,
              name: data.name,
              phones: data.phones,
              surname: data.surname,
              patronymic: data.patronymic,
              role: data.role,
              inn: data.inn,
              position: data.position,
              email: data.email,
              params: data.params,
              companyName: data.companyName,
              currency: data.currency,
              secondId: data.secondId,
              prices: data.prices,
              userHashId: data.userHashId,
              newsLink: data.newsLink,
              isNew: data.isNew,
              customerId: data.id,
              isCreator: data.isCreator,
              isNotVerified: data.isNotVerified,
              theme: data.theme
            });
            if ((response.body.isClear || false) !== this.$store.state.isDemo) {
              this.$store.commit("changeDemo", response.body.isClear || false);
            }
            this.$store.commit("accessUpdate", false);
            resolve(response.body);
          },
          error => {
            this.$store.commit("accessUpdate", false);
            reject(error);
          }
        );
      });
    },
    ///////////////////////////////////////////////////////////////////////
    // МЕТОДЫ ДЛЯ ФОРМАТИРОВАНИЯ ТЕКСТА / ЧИСЕЛ / ДАТ
    ///////////////////////////////////////////////////////////////////////
    numeral(value, format) {
      if (value === null || value === undefined || Number.isNaN(value)) {
        return "";
      } else if (typeof value !== "number") {
        return value;
      } else {
        return numeral(value).format(format || "0,0.00");
      }
    },
    extNumeral(value) {
      if (value === null || value === undefined || Number.isNaN(value)) {
        return "";
      } else if (typeof value !== "number") {
        return value;
      } else {
        return numeral(value).format("0,0.00 $");
      }
    },
    getDay(date) {
      return moment(date).get("date");
    },
    moment(date, format) {
      return moment(date || undefined).format(format || "DD.MM.YYYY - HH:mm");
    },
    roundCount(num) {
      return Number.isNaN(+num)
        ? num
        : typeof num === "number"
        ? +(Math.round(num * 1000) / 1000).toFixed(3)
        : +(Math.round(+num * 1000) / 1000).toFixed(3);
    },
    roundMoney(num) {
      return Number.isNaN(+num)
        ? num
        : typeof num === "number"
        ? +(Math.round(num * 100) / 100).toFixed(2)
        : +(Math.round(+num * 100) / 100).toFixed(2);
    },
    ///////////////////////////////////////////////////////////////////////
    // ДРУГИЕ ОБЩИЕ ФУНКЦИИ
    ///////////////////////////////////////////////////////////////////////
    showMessage(message, title, type) {
      setTimeout(() => {
        this.$nextTick(() => {
          this.$notify({
            title: title || "Ошибка",
            text: message || "При выполнении операции возникла ошибка",
            type: type || "error",
            duration:
              !type || type === "error" || type === "warn"
                ? -1
                : 2000
          });
        });
      }, 100);
    },
    updatePrintCheckedDefault(type, documentTemplateIds) {
      this.callAPI("put", "document-templates", {
        type: type,
        documentTemplateIds: documentTemplateIds
      });
    },
    getUuid() {
      return (
        "_" +
        Math.random()
          .toString(36)
          .substr(2, 9)
      );
    },
    ///////////////////////////////////////////////////////////////////////
    // ПРАВИЛЬНОЕ СЧИТЫВАНИЕ КОЛОНОК ТАБЛИЦ НА САЙТЕ
    ///////////////////////////////////////////////////////////////////////
    getColumns(key, columns, withUpdate = true) {
      if (!key) return columns; // Если нет ключа, точно нет смысла что-то делать
      if (
        this.$store.state.user &&
        this.$store.state.user.params &&
        this.$store.state.user.params.columns &&
        this.$store.state.user.params.columns[key]
      ) {
        // Вилка, если какие-то параметры уже есть в хранилище
        if (columns && Object.keys(columns).length > 0) {
          var current = this.$store.state.user.params.columns[key];
          var newVersion = Object.assign({}, columns);
          var isUpdate = false;

          for (let key in current) {
            if (newVersion[key] === undefined) {
              isUpdate = true;
            }
          }
          for (let key in newVersion) {
            if (current[key] !== undefined) {
              newVersion[key] = current[key];
            }
          }
          if (isUpdate && withUpdate) {
            // Либо убрали старые поля. либо добавили новые, либо вместе
            this.$store.dispatch("updateTableActiveColumns", {
              type: key,
              columns: newVersion,
              function: this.callAPI
            });
          }
          return newVersion;
        } else if (columns === null) {
          // Целенаправленное зануление в АПИ... Зачем-то. Видимо, для очистки лишних данных
          this.$store.dispatch("updateTableActiveColumns", {
            type: key,
            columns: null,
            function: this.callAPI
          });
        } else {
          // Если никакие ключи не посылаются, просто вернуть данные из АПИ
          return this.$store.state.user.params.columns[key];
        }
      } else {
        // Вилка без параметров в хранилище
        return columns;
      }
    }
  },
  ///////////////////////////////////////////////////////////////////////
  // ФИЛЬТРЫ ДЛЯ HTML MUSTACHE
  ///////////////////////////////////////////////////////////////////////
  filters: {
    tomoney(value, format) {
      if (!value && value !== 0) return "";
      value = value.toString();
      return numeral(value).format(format || "0,0.00");
    },
    toextmoney(value) {
      if (!value && value !== 0) return "";
      value = value.toString();
      return numeral(value).format("0,0.00 $");
    },
    toshortmoney(value) {
      if (!value && value !== 0) return "";
      value = value.toString();
      return numeral(value).format("0,0");
    },
    tonormalmoney(value) {
      if (!value && value !== 0) return "";
      value = value.toString();
      return numeral(value).format("0,0.[00]");
    },
    toextshortmoney(value) {
      if (!value && value !== 0) return "";
      value = value.toString();
      return numeral(value).format("0,0 $");
    },
    todate(value, format) {
      if (value && typeof value !== "number") value = value.toString();
      return moment(value).format(format || "DD MMM YYYY");
    },
    totime(value, format) {
      if (value) value = value.toString();
      return moment(value).format(format || "HH:mm");
    },
    todatetime(value, format) {
      return value
        ? moment(value.toString()).format(format || "DD.MM.YYYY - HH:mm")
        : "";
    },
    fromNow(value) {
      return moment(value).fromNow();
    }
  }
};
export default mixin;
