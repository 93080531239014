function updateAutopay(type, params) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "PATCH",
      `autopays/${type}`,
      params,
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function deleteAutopay(type) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "DELETE",
      `autopays/${type}`,
      {},
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function recurrentPay(type, params) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "POST",
      `autopays/${type}/pay`,
      {
        ...params,
        utc: -new Date().getTimezoneOffset()
      },
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function getSbpLink(params) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "POST",
      `company/pay/sbp`,
      params,
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function getPayInvoices(type) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "GET",
      `pay-invoices/${type}`,
      {},
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function createPayInvoice(type, params) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "POST",
      `pay-invoices/${type}`,
      params,
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function loadPayInvoice(type, id) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "GET",
      `pay-invoices/${id}/pdf`,
      {},
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function getSignature(data) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "POST",
      `company/pay/sign`,
      { body: data },
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

export {
  updateAutopay,
  deleteAutopay,
  recurrentPay,
  getSbpLink,
  getPayInvoices,
  createPayInvoice,
  loadPayInvoice,
  getSignature
}