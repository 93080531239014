<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="isMouseEnter && hoverWidth? hoverWidth : width"
    :height="isMouseEnter && hoverHeight? hoverHeight : height"
    viewBox="0 0 12 12"
    class="icon-close"
    fill="none"
    @mouseenter="isMouseEnter = true"
    @mouseleave="isMouseEnter = false"
  >
    <!-- <path
      d="M1.252 1.252 9 9m-8-.148L8.852 1"
      stroke-width="1.5"
      stroke-linecap="round"
      :class="compColor"
    /> -->
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M.277.277c.37-.37.97-.37 1.34 0L6 4.66 10.383.277a.947.947 0 0 1 1.34 1.34L7.34 6l4.383 4.383a.947.947 0 1 1-1.34 1.34L6 7.34l-4.383 4.383a.947.947 0 1 1-1.34-1.34L4.66 6 .277 1.617a.947.947 0 0 1 0-1.34Z"
      :class="compColor"
    />
  </svg>
</template>

<script>
export default {
  name: "IconClose",
  props: {
    width: {
      type: Number,
      default: 10
    },
    height: {
      type: Number,
      default: 10
    },
    hoverWidth: Number,
    hoverHeight: Number,
    iconColor: {
      type: String,
      default: "primary"
    }
  },
  data() {
    return {
      isMouseEnter: false
    }
  },
  computed: {
    compColor() {
      return `color-${this.iconColor}`
    }
  }
};
</script>

<style lang="scss" scoped>
.color-main {
  fill: var(--main-color);
}
.color-aside {
  fill: var(--aside-color);
}
.color-primary {
  fill: var(--color-primary);
}
.color-success {
  fill: var(--color-success);
}
.color-cancel {
  fill: var(--color-danger);
}
.color-yellow {
  fill: var(--color-warning);
}
.color-white {
  fill: #FFFFFF;
}
.icon-close {
  transition: all .3s;
}
</style>