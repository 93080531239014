<template>
  <div class="count">
    <slot name="header">
      <p class="count-placeholder text-center small-text bold-text">
        {{ placeholder }}
      </p>
    </slot>
    <button
      class="btn btn-danger btn-count"
      :disabled="disabled || value <= minValue"
      @click="changeCount(value - 1)"
    >
      <span class="ti-minus"></span>
    </button>
    <v-input-number
      :value="currentValue"
      :isFloat="isFloat"
      class="input-count input-sm"
      :disabled="disabled"
      @input="input"
      @change="change"
    ></v-input-number>
    <button
      class="btn btn-success btn-count"
      :disabled="disabled || value >= maxValue"
      @click="changeCount(value + 1)"
    >
      <span class="ti-plus"></span>
    </button>
  </div>
</template>
<style lang="scss" scoped>
.count {
  width: auto;
  .count-placeholder {
    margin-bottom: 0;
  }
  .btn-count {
    border-radius: 20px;
    outline: 0;
    width: 25px;
    height: 25px;
    padding: 0;
    line-height: 25px;
    text-align: center;
    font-size: 17px;
    vertical-align: middle;
    &.btn-success {
      background-color: rgb(92, 208, 150);
    }
    &:disabled {
      border: 1px solid lightgray;
      background-color: lightgray;
    }
  }
  .count-label {
    font-size: 15px;
    margin-left: 5px;
    margin-right: 5px;
    font-weight: 600;
  }
  .input-count {
    display: inline-block;
    vertical-align: middle;
    margin: 0 4px;
    padding: 5px 4px;
    width: 45px;
    &.input-sm {
      height: 25px;
    }
  }
}
</style>
<script>
export default {
  name: "count",
  props: {
    value: {
      type: Number,
      required: true
    },
    placeholder: {
      type: String,
      default: ""
    },
    minValue: {
      type: Number,
      default: 1
    },
    maxValue: {
      type: Number,
      default: 99999999
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isFloat: {
      type: Boolean
    }
  },
  data() {
    return {
      currentValue: this.$props.value
    };
  },
  watch: {
    value: function(newValue) {
      this.currentValue = newValue;
    }
  },
  methods: {
    input(value) {
      this.currentValue = value;
      if (!value) {
        // Если сейчас 0 или нет значения, а предыдущее было больше 0, $emit
        if (this.$props.value) this.$emit("input", this.$props.minValue);
      } else {
        var newValue = this.isFloat
            ? this.roundCount(value)
            : Math.round(value),
          oldValue = this.isFloat
            ? this.roundCount(this.$props.value)
            : Math.round(this.$props.value);

        if (newValue === oldValue) {
          return;
        }
        let result = this.roundCount(
          Math.min(
            Math.max(newValue, this.$props.minValue),
            this.$props.maxValue
          )
        );
        if (result === oldValue) {
          return;
        }
        this.$emit("input", result);
      }
    },
    change() {
      let newValue = this.isFloat
        ? this.roundCount(this.currentValue || 0)
        : Math.round(this.currentValue || 0);
      let result = this.roundCount(
        Math.min(Math.max(newValue, this.$props.minValue), this.$props.maxValue)
      );
      if (this.currentValue !== result) {
        this.currentValue = result;
      }
      this.$emit("change", result);
    },
    changeCount(value) {
      let newValue = this.isFloat
          ? this.roundCount(value || 0)
          : Math.round(value || 0),
        oldValue = this.isFloat
          ? this.roundCount(this.$props.value)
          : Math.round(this.$props.value);
      let result = this.roundCount(
        Math.min(Math.max(newValue, this.$props.minValue), this.$props.maxValue)
      );
      if (result === oldValue) {
        return;
      }
      this.$emit("change", result);
    }
  }
};
</script>
