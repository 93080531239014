function getSalariesStatistic() {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "get",
      "salaries",
      {
        utc: -new Date().getTimezoneOffset()
      },
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
function getCompanySalarySettings() {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "get",
      "salaries/salary",
      {},
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
function getCompanyRevenueSettings() {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "get",
      "salaries/revenue",
      {},
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
function updateCompanySalarySettings(params) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "patch",
      "salaries/salary",
      params,
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
function updateCompanyRevenueSettings(params) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "patch",
      "salaries/revenue",
      params,
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
function payWages(typePayment, params = {}) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "post",
      `salaries/customers/cash/${typePayment}`,
      {
        ...params,
        utc: -new Date().getTimezoneOffset()
      },
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
function printCashDocuments(cashId, documentTemplateIds = [], shopId) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "get",
      `salaries/cash/${cashId}/print`,
      {
        documentTemplateIds,
        shopId,
        utc: -new Date().getTimezoneOffset()
      },
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
function getCustomerSalaries(customerId, params = {}) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "get",
      `salaries/customers/${customerId}/salaries`,
      {
        ...params,
        utc: -new Date().getTimezoneOffset()
      },
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
function getCustomerCashes(customerId, params = {}) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "get",
      `salaries/customers/${customerId}/cash`,
      {
        utc: -new Date().getTimezoneOffset(),
        ...params
      },
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
function getCustomerEvents(customerId, params = {}) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "get",
      `salaries/customers/${customerId}/events`,
      {
        utc: -new Date().getTimezoneOffset(),
        ...params
      },
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
function createBonus(customerId, params = {}) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "post",
      `salaries/customers/${customerId}/bonus`,
      params,
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
function createPenalty(customerId, params = {}) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "post",
      `salaries/customers/${customerId}/penalties`,
      params,
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
function createSalaryItem(customerId, params = {}) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "post",
      `salaries/customers/${customerId}/salary`,
      params,
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
function updateSalaryItem(id, params = {}) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "patch",
      `salaries/${id}`,
      params,
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
function deleteSalaryItem(id) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "delete",
      `salaries/${id}`,
      {},
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
function createPayment(customerId, typeCash = "money", params = {}) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "post",
      `salaries/customers/${customerId}/cash/${typeCash}`,
      {
        ...params,
        utc: -new Date().getTimezoneOffset()
      },
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
function checkRevenue(customerId, params = {}) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "post",
      `salaries/customers/${customerId}/revenue`,
      {
        ...params,
        utc: -new Date().getTimezoneOffset()
      },
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
function getAllSalaries(customerId) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "get",
      `company/customers/${customerId}/customer-salaries`,
      {},
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
function createSalary(customerId, params) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "post",
      `company/customers/${customerId}/customer-salaries`,
      params,
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
function updateSalary(customerId, salaryId, params) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "patch",
      `company/customers/${customerId}/customer-salaries/${salaryId}`,
      params,
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
function deleteSalary(customerId, salaryId) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "delete",
      `company/customers/${customerId}/customer-salaries/${salaryId}`,
      {},
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
function getCustomersForWage(params = {}) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "get",
      `salaries/customers`,
      {
        utc: -new Date().getTimezoneOffset(),
        ...params
      },
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
function createWageItem(customerId, type, params) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "post",
      `salaries/customers/${customerId}/${type}`,
      params,
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}
function updateWageItem(salaryId, type, itemId, params) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "patch",
      `salaries/${salaryId}/${type}/${itemId}`,
      params,
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function importWageSettings(customerId, params) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "put",
      `company/customers/${customerId}/customer-salaries`,
      params,
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function recalculateWage(customerId, params) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "patch",
      `salaries/customers/${customerId}`,
      params,
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

export {
  getSalariesStatistic,
  getCompanySalarySettings,
  getCompanyRevenueSettings,
  updateCompanySalarySettings,
  updateCompanyRevenueSettings,
  payWages,
  printCashDocuments,
  getCustomerSalaries,
  getCustomerCashes,
  getCustomerEvents,
  createBonus,
  createPenalty,
  createSalaryItem,
  updateSalaryItem,
  deleteSalaryItem,
  createPayment,
  checkRevenue,
  // старые функции
  getAllSalaries,
  createSalary,
  updateSalary,
  deleteSalary,
  getCustomersForWage,
  createWageItem,
  updateWageItem,
  importWageSettings,
  recalculateWage
};