import printJS from "print-js";
import JsBarcode from "jsbarcode";
import PHE from "../../../vendors/print-html-element/main.js";
import Vue from "vue";
import { DOMImplementation, XMLSerializer } from "@xmldom/xmldom";

export default class VuePrinter {
  constructor() {
    this.turn = [];
    this.title = "Документ";
    this._api = new Vue()
  }

  printDocument(document, title) {
    if (Array.isArray(document)) {
      this.turn = document;
      this.title = title || "Документ";
      this.print(this.turn.shift(), title);
    } else this.print(document, title);
  }
  print(document, title) {
    if (!document || (typeof document !== "string" && !document.text)) return;
    try {
      if (typeof document === "string" || document.isOld) {
        PHE.printHtml(typeof document === "string" ? document : document.text, {
          pageTitle: title || "Документ",
          printMode: "iframe",
          styles: "body { line-height: 1.2; }"
        });
        this.onPrintDialogClose();
      } else {
        const text = document.text.replaceAll(/<img/gi, "<img onError=\"this.src = 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IS0tIFVwbG9hZGVkIHRvOiBTVkcgUmVwbywgd3d3LnN2Z3JlcG8uY29tLCBHZW5lcmF0b3I6IFNWRyBSZXBvIE1peGVyIFRvb2xzIC0tPgo8c3ZnIGZpbGw9IiMwMDAwMDAiIHdpZHRoPSI4MDBweCIgaGVpZ2h0PSI4MDBweCIgdmlld0JveD0iMCAwIDMyIDMyIiBpZD0iaWNvbiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6bm9uZTt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPm5vLWltYWdlPC90aXRsZT48cGF0aCBkPSJNMzAsMy40MTQxLDI4LjU4NTksMiwyLDI4LjU4NTksMy40MTQxLDMwbDItMkgyNmEyLjAwMjcsMi4wMDI3LDAsMCwwLDItMlY1LjQxNDFaTTI2LDI2SDcuNDE0MWw3Ljc5MjktNy43OTMsMi4zNzg4LDIuMzc4N2EyLDIsMCwwLDAsMi44Mjg0LDBMMjIsMTlsNCwzLjk5NzNabTAtNS44MzE4LTIuNTg1OC0yLjU4NTlhMiwyLDAsMCwwLTIuODI4NCwwTDE5LDE5LjE2ODJsLTIuMzc3LTIuMzc3MUwyNiw3LjQxNDFaIi8+PHBhdGggZD0iTTYsMjJWMTlsNS00Ljk5NjYsMS4zNzMzLDEuMzczMywxLjQxNTktMS40MTYtMS4zNzUtMS4zNzVhMiwyLDAsMCwwLTIuODI4NCwwTDYsMTYuMTcxNlY2SDIyVjRINkEyLjAwMiwyLjAwMiwwLDAsMCw0LDZWMjJaIi8+PHJlY3QgaWQ9Il9UcmFuc3BhcmVudF9SZWN0YW5nbGVfIiBkYXRhLW5hbWU9IiZsdDtUcmFuc3BhcmVudCBSZWN0YW5nbGUmZ3Q7IiBjbGFzcz0iY2xzLTEiIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIvPjwvc3ZnPg=='\"")
        printJS({
          printable: text,
          type: "raw-html",
          css: "/tinymce/css/content.css",
          header: title || "Документ"
        });
        setTimeout(() => this.onPrintDialogClose(), 1500);
      }
    } catch (e) {
      this._api?.$backendService?.sendLog?.("printError", {
        err: e?.message
      })
    }
  }

  onPrintDialogClose() {
    if (this.turn && this.turn.length)
      this.print(this.turn.shift(), this.title);
  }

  getBarcode(value, params, type) {
    const xmlSerializer = new XMLSerializer();
    const d = new DOMImplementation().createDocument(
      "http://www.w3.org/1999/xhtml",
      "html",
      null
    );
    const svgNode = d.createElementNS("http://www.w3.org/2000/svg", "svg");

    if (params) params.xmlDocument = d;

    JsBarcode(
      svgNode,
      value,
      params || {
        xmlDocument: d,
        height: 40,
        margin: 0,
        displayValue: false,
        flat: true
      }
    );

    let svgText = xmlSerializer.serializeToString(svgNode);
    if (type === "Слева")
      svgText = `<div style="float: left;">${svgText}</div>`;
    else if (type === "Справа")
      svgText = `<div style="float: right;">${svgText}</div>`;
    return svgText;
  }
}
