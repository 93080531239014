<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="isMouseEnter && hoverWidth? hoverWidth : width"
    :height="isMouseEnter && hoverHeight? hoverHeight : height"
    class="icon-trash-3"
    :class="withPadding ? 'icon-trash-3--padding' : ''"
    :style="padding ? { padding } : {}"
    viewBox="0 0 16 16"
    fill="none"
    @mouseenter="isMouseEnter = true"
    @mouseleave="isMouseEnter = false"
  >
    <path
      d="M9.115 1.184c-.831.007-1.664-.013-2.495.01-.329.042-.603.325-.786.616-.207.362-.386.74-.575 1.111-.07.22-.2.43-.409.54-.59.01-1.18-.01-1.77.01-.207.125-.436.336-.39.603.021.281.295.44.54.503.119.012.238-.004.356.016.046.064.101.128.117.208l.58 8.22c.019.174-.013.355.05.523.07.218.09.454.195.66.107.15.216.31.383.397.205.115.412.275.66.257 1.681-.004 3.363.008 5.044-.006.358-.125.711-.345.898-.685.094-.256.153-.525.231-.786.022-.21-.016-.425.047-.63.183-2.618.36-5.238.505-7.855.02-.117.093-.216.15-.317.322.052.675-.064.856-.345.014-.143.004-.286.007-.428.005-.206-.171-.34-.34-.341-.606-.007-1.212.006-1.816-.007-.23-.113-.322-.362-.41-.584-.218-.395-.381-.824-.645-1.193-.217-.336-.613-.474-.983-.497zm.087 1.248c.21.281.333.615.495.923-.126.044-.248.12-.386.109H6.685c-.12.006-.225-.057-.334-.095.092-.308.252-.59.414-.865.06-.12.199-.155.316-.196.58-.011 1.162 0 1.743-.006a.544.544 0 0 1 .378.13zm1.781 2.15c.06.068.125.134.195.194l-.619 8.175c-.034.195.005.397-.027.593-.039.055-.086.103-.132.152-1.552.002-3.105.003-4.657 0-.17.014-.256-.174-.283-.314l-.644-8.597a1.89 1.89 0 0 0 .2-.203c1.99 0 3.978.002 5.967 0z"
      :class="compColor"
    />
    <rect
      width="1.035"
      height="5.273"
      rx=".517"
      transform="scale(-1 1) rotate(-2.35 153.802 240.58)"
      :class="compColor"
    />
    <rect
      width="1.035"
      height="5.273"
      rx=".517"
      transform="scale(-1 1) rotate(2.35 -161.463 -175.883)"
      :class="compColor"
    />
  </svg>
</template>

<script>
export default {
  name: "IconTrash",
  props: {
    width: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 16
    },
    hoverWidth: Number,
    hoverHeight: Number,
    iconColor: {
      type: String,
      default: "primary"
    },
    hoverColor: String,
    withPadding: Boolean,
    padding: String
  },
  data() {
    return {
      isMouseEnter: false
    }
  },
  computed: {
    compColor() {
      return `color-${this.isMouseEnter && !this.disabled ? this.hoverColor : this.iconColor}`
    },
  }
};
</script>

<style lang="scss" scoped>
.color-main {
  fill: var(--main-color);
}
.color-primary {
  fill: var(--color-primary);
}
.color-success {
  fill: var(--color-success);
}
.color-cancel {
  fill: var(--color-danger);
}
.color-yellow {
  fill: var(--color-warning);
}
.color-white {
  fill: #FFFFFF;
}
.color-danger {
  fill: var(--color-danger);
}
.color-gray {
  fill: #64748B;
}
.icon-trash-3 {
  transition: all .3s;
  &--padding {
    padding: 2px;
    border-radius: 4px;
    // background-color: #fce6e6;
    background-color: var(--color-danger-10-op);
    &:hover {
      background-color: var(--color-danger);
    }
  }
}
</style>
