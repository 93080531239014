function getFields(type) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "GET",
      `params/${type}/fields`,
      { },
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function saveFields(type, fields, fontSize) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "PUT",
      `params/${type}/fields`,
      { fields, fontSize },
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function saveSort(type, sort) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "PATCH",
      `params/${type}/sort`,
      { field: sort.field, dir: sort.dir.toUpperCase() },
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function updateColWidth(type, name, width) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "PATCH",
      `params/${type}/fields/${name}`,
      { width },
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

function getDefaultFields(type) {
  return new Promise((resolve, reject) => {
    this._api.callAPI(
      "GET",
      `params/${type}/fields/default`,
      {},
      response => {
        resolve(response.body);
      },
      error => {
        reject(error);
      }
    );
  });
}

export {
  getFields,
  saveFields,
  saveSort,
  updateColWidth,
  getDefaultFields
};
