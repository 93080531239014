<template>
  <div>
    <div class="call-wrapper">
      <div v-if="calls.length" class="call__btns" key="btns">
        <router-link
          v-if="$store.getters.access.statisticCallAccess"
          to="/analytics/calls"
          v-slot="{ navigate }"
          custom
        >
          <button
            class="cb-all"
            @click="clearCalls(navigate, $event)"
          >
            <span class="cb-all__icon"></span>
            Журнал звонков
          </button>
        </router-link>
        <div v-else></div>
        <button class="cb-close" @click="clearCalls()">Закрыть все</button>
      </div>
      <transition-group tag="div" name="calls">
        <div
          v-for="(item, index) in calls"
          :key="item.extCallId"
          class="call"
          :class="(~activeCallIndex ? activeCallIndex === index : index === calls.length - 1) ? 'call--active' : ''"
          :style="{zIndex: item.zIndex}"
        >
          <div
            v-if="item.callStatus === 'missed'"
            class="call__line call__line--missed"
            @click="showCall(index)"
          ></div>
          <div
            v-else-if="item.callStatus === 'answered'"
            class="call__line call__line--answered"
            @click="showCall(index)"
          ></div>
          <div
            v-else-if="item.callStatus === 'internal'"
            class="call__line call__line--internal"
            @click="showCall(index)"
          ></div>
          <div
            v-else
            class="call__line call__line--green"
            @click="showCall(index)"
          ></div>
          <div class="call__header" @click="showCall(index)">
            <img
              v-if="item.callStatus === 'missed'"
              src="../../assets/img/call-notifications/phone-missed.svg"
              class="ch-icon"
            >
            <img
              v-else-if="item.callStatus === 'answered'"
              src="../../assets/img/call-notifications/phone-answered.svg"
              class="ch-icon"
            >
            <img
              v-else-if="item.callStatus === 'internal'"
              src="../../assets/img/call-notifications/phone-internal.svg"
              class="ch-icon"
            >
            <img
              v-else
              src="../../assets/img/call-notifications/phone.svg"
              class="ch-icon"
            >
            <div class="ch-name">
              <div class="ch-phone">{{item.phone && item.phone.name}}</div>
              <div class="ch-client">{{
                item.counteragent
                  ? item.counteragent.name
                  : item.counteragents && item.counteragents.length > 0
                  ? item.counteragents[0].name
                  : item.extNumber
              }}</div>
            </div>
            <div class="ch-time">{{item.time}}</div>
            <IconClose2
              iconColor="main"
              class="ch-close"
              @click.native="clearCall(index)"
            ></IconClose2>
          </div>
          <transition name="call">
            <div v-if="~activeCallIndex ? activeCallIndex === index : index === calls.length - 1" class="call__body">
              <div class="call__counteragent">
                <template v-if="$store.getters.access.counteragentOrderAccess">
                  <img
                    v-if="item.counteragent && item.counteragent.rating === 1"
                    src="../../assets/img/call-notifications/client-good.svg"
                    class="call__counteragent-icon"
                  >
                  <img
                    v-else-if="item.counteragent && item.counteragent.rating === -1"
                    src="../../assets/img/call-notifications/client-bad.svg"
                    class="call__counteragent-icon"
                  >
                  <div class="call__counteragent-data">
                    <p class="call__counteragent-name" @click="showCounteragent">
                      {{item.counteragent &&
                        item.counteragent.name ||
                        item.extNumber}}
                    </p>
                    <p
                      v-if="item.counteragents && item.counteragents[0] && item.counteragents[0].name"
                      class="call__counteragent-phone"
                    >
                      {{item.extNumber}}
                      <span class="call__counteragent-sms" @click="sendSms">sms</span>
                    </p>
                  </div>
                  <div v-if="item.counteragents && item.counteragents.length > 1" class="call__counteragent-switcher">
                    <span class="fa fa-chevron-left sw-arrow" @click="toPreviosCounteragent"></span>
                    {{item.counteragentNumber}}/{{item.counteragents.length}}
                    <span class="fa fa-chevron-right sw-arrow" @click="toNextCounteragent"></span>
                  </div>
                </template>
                <div v-else>Контрагент - нет доступа</div>
              </div>
              <div
                v-if="!$store.getters.access.allOrderAccess && !$store.getters.access.ownOrderAccess"
                class="call__orders"
              >
                <div class="call__orders-zero">Заказы - нет доступа</div>
              </div>
              <template v-else>
                <div class="call__orders" @click="showCounteragentOrders">
                  <div
                    v-if="item.counteragent && item.counteragent.orders && item.counteragent.orders.length"
                    class="call__orders-all"
                  >Заказы ({{item.counteragent.orders.length}})</div>
                  <div v-else class="call__orders-zero">Заказов нет</div>
                  <button class="call__orders-add" @click.stop="newOrder">+</button>
                </div>
                <div class="call__orders-table-wrapper">
                  <v-scroll-2>
                    <table v-if="item.counteragent && item.counteragent.orders && item.counteragent.orders.length" class="call__orders-table">
                      <tr v-for="order in item.counteragent.orders" :key="order.id" @click="showOrder(order)">
                        <td>№{{order.number}}</td>
                        <td>{{order.price || 0 | tomoney}}</td>
                        <td :style="{color: order.status.color}">
                          <span class="call__status-circle" :style="{backgroundColor: order.status.color}"></span>
                          {{order.status.name}}
                        </td>
                      </tr>
                    </table>
                  </v-scroll-2>
                </div>
              </template>
              <div class="call__comment-wrapper">
                <div
                  v-if="item.id"
                  contenteditable="true"
                  class="call__comment custom-editable"
                  :class="item.withoutBorder ? 'call__comment--not-border' : ''"
                  placeholder="Добавить комментарий"
                  @input="updateComment"
                  @keydown.enter="sendCallComment"
                  @focusout="sendCallComment"
                  @mouseenter="commentEnter"
                ></div>
                <div v-else style="padding-top:8px;color:#9A9A9A;">Комментарий можно будет оставить<br/>после завершения звонка</div>
              </div>
            </div>
          </transition>
        </div>
      </transition-group>
    </div>
    <counteragent
      v-if="isShowCounteragent"
      class="under-notifications"
      :counteragentId="activeCounteragentId"
      :preferData="preferCounteragentData"
      @addClient="addClient"
      @changeClient="changeClient"
      @deleteClient="deleteClient"
      @addedFromCounteragent="newOrderFromCounteragent"
      @close="closeCounteragent"
    ></counteragent>
    <order
      v-if="isShowOrder"
      :orderId="activeOrderId"
      :preferData="preferOrderData"
      :preferCounteragent="preferOrderCounteragent"
      :callId="callId"
      @close="closeOrder"
      @addOrder="addOrder"
      @updatedOrder="updateOrder"
      @deleteOrder="deleteOrder"
      @openOrder="activeOrderId = $event"
    ></order>
  </div>
</template>

<script>
import moment from 'moment';
// import counteragent from "../../components/livesklad/compendiums/counteragent";
// import order from "../../components/livesklad/orders/order";
import { formatPhone } from './resources/_mask-function';
import IconClose2 from '../icons/IconClose2.vue';
export default {
  components: {
    IconClose2,
    counteragent: () => import('../../components/livesklad/compendiums/counteragent'),
    order: () => import('../../components/livesklad/orders/order')
  },
  data() {
    return {
      calls: [],
      activeCallIndex: -1,
      // Контрагенты
      isShowCounteragent: false,
      activeCounteragentId: "",
      preferCounteragentData: undefined,
      // Заказы
      activeOrderId: undefined,
      isShowOrder: false,
      preferOrderData: undefined,
      preferOrderCounteragent: undefined,
      callId: null
    }
  },
  // sockets: {
  //   startCallEvent: function(data) {
  //     this.callBegin(data);
  //   },
  //   endCallEvent: function(data) {
  //     let call = this.calls.find(call => call.extCallId === data.extCallId);
  //     if (call) {
  //       this.$set(call, "id", data.callId);
  //       this.$set(call, "callStatus", data.status === "answered" ? data.status : "missed");
  //     }
  //   }
  // },
  watch: {
    "$store.state.socketId"(newValue) {
      if (newValue) this.addSocketListeners()
    }
  },
  methods: {
    ///////////////////////////////////////////// Контрагент /////////////////////////////////////////////
    toPreviosCounteragent() {
      let call = ~this.activeCallIndex
        ? this.calls[this.activeCallIndex]
        : this.calls[this.calls.length - 1];
      this.$set(
        call,
        "counteragentNumber",
        call.counteragentNumber === 1
          ? call.counteragents.length
          : call.counteragentNumber - 1
      );
      this.$set(
        call,
        "counteragent",
        call.counteragents[call.counteragentNumber - 1]
      );
    },
    toNextCounteragent() {
      let call = ~this.activeCallIndex
        ? this.calls[this.activeCallIndex]
        : this.calls[this.calls.length - 1];
      this.$set(
        call,
        "counteragentNumber",
        call.counteragentNumber === call.counteragents.length
          ? 1
          : call.counteragentNumber + 1
      );
      this.$set(
        call,
        "counteragent",
        call.counteragents[call.counteragentNumber - 1]
      );
    },
    showCounteragent() {
      let call = ~this.activeCallIndex
        ? this.calls[this.activeCallIndex]
        : this.calls[this.calls.length - 1];
      if (call.counteragent) this.activeCounteragentId = call.counteragent.id;
      else {
        this.preferCounteragentData = {
          phones: [call.extNumber]
        }
        this.activeCounteragentId = undefined;
      }
      this.isShowCounteragent = true;
    },
    closeCounteragent() {
      this.isShowCounteragent = false;
    },
    addClient(client) {
      let call = ~this.activeCallIndex
        ? this.calls[this.activeCallIndex]
        : this.calls[this.calls.length - 1];
      this.$set(call, "counteragents", [client]);
      this.$set(call, "counteragent", client);
      this.$set(call, "counteragentNumber", 1);
    },
    changeClient(client) {
      let call = ~this.activeCallIndex
        ? this.calls[this.activeCallIndex]
        : this.calls[this.calls.length - 1];
      if (call.counteragent.name !== client.name) {
        this.$set(call.counteragent, "name", client.name);
      }
    },
    deleteClient(id) {
      let call = ~this.activeCallIndex
        ? this.calls[this.activeCallIndex]
        : this.calls[this.calls.length - 1];
      let index = call.counteragents.findIndex(client => client.id === id);
      if (~index) call.counteragents.splice(index, 1);
      if (call.counteragents.length) {
        this.$set(call, "counteragentNumber", 1)
        this.$set(call, "counteragent", call.counteragents[0])
      } else this.$delete(call, "counteragent");
      this.isShowCounteragent = false;
    },
    ///////////////////////////////////////////// Заказы /////////////////////////////////////////////
    newOrderFromCounteragent(order) {
      let call = ~this.activeCallIndex
        ? this.calls[this.activeCallIndex]
        : this.calls[this.calls.length - 1];
      let obj = {
        id: order.id,
        number: order.number,
        status: order.status,
        price: 0
      }; 
      if (call.counteragent?.orders) call.counteragent.orders.unshift(obj)
      else this.$set(call.counteragent, "orders", [obj])
    },
    async newOrder() {
      let call = ~this.activeCallIndex
        ? this.calls[this.activeCallIndex]
        : this.calls[this.calls.length - 1];
      
      if (call.counteragent && !call.counteragent.isFull) {
        const { getCounteragent } = await import('../../services/_counteragents')
        getCounteragent(call.counteragent.id).then(body => {
          this.$set(
            call,
            "counteragent",
            Object.assign(
              { isFull: true },
              call.counteragent,
              body.data
            )
          )
          this.newOrder()
        })
        return;
      } else if (call.node || !call.counteragent) {
        let obj = {};
        if (call.node) obj.node = call.node;
        if (call.counteragent) obj.counteragent = call.counteragent;
        else obj.phones = [call.extNumber];
        this.preferOrderData = obj;
      } else {
        this.preferOrderCounteragent = call.counteragent;
      }
      this.callId = call.id;
      this.activeOrderId = undefined;
      this.isShowOrder = true;
    },
    showOrder(order) {
      if (order.shopId && order.shopId !== this.$store.state.activeShop) {
        this.$store.commit("setActiveShop", order.shopId);
      }
      this.preferOrderCounteragent = undefined;
      this.activeOrderId = order.id;
      this.isShowOrder = true;
    },
    closeOrder() {
      this.isShowOrder = false;
    },
    addOrder(order) {
      this.activeOrderId = order.id;
      let call = ~this.activeCallIndex
        ? this.calls[this.activeCallIndex]
        : this.calls[this.calls.length - 1];
      if (call.counteragent && order.counteragent?.id !== call.counteragent.id) {
        this.isShowOrder = false;
        let otherCounteragent = call.counteragents.find(({id}) => id === order.counteragent?.id);
        if (otherCounteragent) {
          if (!otherCounteragent.orders) this.$set(otherCounteragent, "orders", []);
          otherCounteragent.orders.unshift({
            id: order.id,
            number: order.number,
            status: order.status,
            price: 0,
          })
        }
        return;
      } else if (call.counteragent?.orders) {
        call.counteragent.orders.unshift({
          id: order.id,
          number: order.number,
          status: order.status,
          price: 0,
        })
      } else if (call.counteragent) {
        this.$set(call.counteragent, "orders", [{
          id: order.id,
          number: order.number,
          status: order.status,
          price: 0,
        }])
      } else {
        this.$set(call, "counteragents", [{
          id: order.counteragent.id,
          name: order.counteragent.name,
          orders: [{
            id: order.id,
            number: order.number,
            status: order.status,
            price: 0,
          }]
        }])
        this.$set(call, "counteragent", call.counteragents[0]);
        this.$set(call, "counteragentNumber", 1);
      }
      this.isShowOrder = false;
    },
    updateOrder(order, isClose) {
      let call = ~this.activeCallIndex
        ? this.calls[this.activeCallIndex]
        : this.calls[this.calls.length - 1];
      let index = call.counteragent.orders.findIndex(({id}) => order.id === id);
      if (order.counteragent?.id === call.counteragent.id) {
        if (~index) {
          call.counteragent.orders[index].status = order.status;
          call.counteragent.orders[index].price = order.summ;
        }
      } else {
        if (~index) call.counteragent.orders.splice(index, 1);
        let otherCounteragent = call.counteragents.find(({id}) => id === order.counteragent?.id);
        if (otherCounteragent) {
          if (!otherCounteragent.orders) this.$set(otherCounteragent, "orders", []);
          otherCounteragent.orders.unshift({
            id: order.id,
            number: order.number,
            status: order.status,
            price: 0,
          })
        }
      }
      if (isClose) this.isShowOrder = false;
    },
    deleteOrder(id) {
      let call = ~this.activeCallIndex
        ? this.calls[this.activeCallIndex]
        : this.calls[this.calls.length - 1];
      let index = call.counteragent.orders.findIndex(order => order.id === id);
      if (~index) call.counteragent.orders.splice(index, 1);
      this.isShowOrder = false;
    },
    ///////////////////////////////////////////// Комментарий /////////////////////////////////////////////
    updateComment(event) {
      let call = ~this.activeCallIndex
        ? this.calls[this.activeCallIndex]
        : this.calls[this.calls.length - 1];
      this.$set(call, "isNodeUpdate", true);
      this.$set(call, "node", event.target.textContent);
    },
    commentEnter() {
      let call = ~this.activeCallIndex
        ? this.calls[this.activeCallIndex]
        : this.calls[this.calls.length - 1];
      if (call.withoutBorder) this.$set(call, "withoutBorder", false);
    },
    sendCallComment(event) {
      let call = ~this.activeCallIndex
        ? this.calls[this.activeCallIndex]
        : this.calls[this.calls.length - 1];
      if (event?.target?.textContent !== undefined && !event.shiftKey) {
        event.preventDefault();
        if (call.isNodeUpdate) {
          this.$set(call, "isNodeUpdate", false);
          event.target.blur();
          this.callAPI(
            "patch",
            `calls/${call.id}/node`,
            { node: event.target.textContent },
            () => {
              this.$set(call, "withoutBorder", true);
              this.showMessage(" ", "Комментарий сохранен", "success")
            }
          )
        }
      }
    },
    ///////////////////////////////////////////// SMS /////////////////////////////////////////////
    sendSms() {
      let call = ~this.activeCallIndex
        ? this.calls[this.activeCallIndex]
        : this.calls[this.calls.length - 1];
      this.$store.commit("newClientEvent", {
        type: "sms",
        counteragentId: call.counteragent?.id,
        recipient: call.extNumber,
        isActive: call.counteragent?.isSendSms ?? true,
      });
    },
    ///////////////////////////////////////////// Звонки /////////////////////////////////////////////
    showCounteragentOrders() {
      let call = ~this.activeCallIndex
        ? this.calls[this.activeCallIndex]
        : this.calls[this.calls.length - 1];
      if (call.counteragent?.orders?.length) {
        this.$router.push({
          path: '/orders',
          query: {
            counteragentId: call.counteragent.id,
            counteragentName: call.counteragent.name
          }
        })
      }
    },
    showCall(index) {
      this.activeCallIndex = index;
      if (~index) {
        this.calls.forEach((call, ind) => {
          this.$set(
            call,
            "zIndex",
            ind < index
              ? ind
              : 100 - (ind - index)
          );
        })
      } else {
        this.calls.forEach(call => this.$set(call, "zIndex", 0))
      }
    },
    clearCall(index) {
      this.calls.splice(index, 1);
      this.showCall(-1);
    },
    clearCalls(navigate, ev) {
      this.calls = [];
      this.activeCallIndex = -1;
      if (navigate) navigate(ev)
    },
    callBegin(data) {
      // Если уведомление об этом звонке уже висит, игнорировать новые уведомления
      if (this.calls.find(({extCallId}) => extCallId === data.extCallId)) return;
      // Показывать только звонки с телефонов, к которым у пользователя есть доступ в текущей локации
      if (
        data.phone?.id &&
        this.$store.state.user
          ?.shopsAccess
          ?.find(({id}) => id === this.$store.state.activeShop)
          ?.phonesAccess
          ?.find(({id}) => id === data.phone.id)
      ) {
        if (data.orders?.length && data.counteragents?.length) data.counteragents?.forEach(client => {
          client.orders = data.orders.filter(({counteragentId}) => counteragentId === client.id);
        })
        data.counteragent = data.counteragents?.[0]
        if (data.extNumber && !/[^0-9+-\s()]/.test(data.extNumber)) {
          data.extNumber = formatPhone(data.extNumber?.replace(/\D/gm, ""));
        }
        data.counteragentNumber = 1;
        data.time = moment().format("HH:mm");
        data.zIndex = ~this.activeCallIndex
          ? 100 - (this.calls.length - this.activeCallIndex )
          : 0;
        this.calls.push(data);
        if (this.calls.length > 5) {
          this.calls.shift();
          if (~this.activeCallIndex) {
            this.showCall(this.activeCallIndex);
          }
        }
      }
    },
    endCallEvent(data) {
      let call = this.calls.find(call => call.extCallId === data.extCallId);
      if (call) {
        this.$set(call, "id", data.callId);
        this.$set(call, "callStatus", data.status === "answered" ? data.status : "missed");
      }
    },
    addSocketListeners() {
      this.$socket.on("startCallEvent", this.callBegin)
      this.$socket.on("endCallEvent", this.endCallEvent)
    }
  },
  created() {
    this.addSocketListeners()
  },
  beforeDestroy() {
    this.$socket?.off?.("startCallEvent", this.callBegin)
    this.$socket?.off?.("endCallEvent", this.endCallEvent)
  }
  // mounted() {
  //   this.callBegin({
  //     extCallId: "1",
  //     id: "?",
  //     orders: [{
  //       id: "63d6ca6bf6d9191629db351d",
  //       number: "29012023A0038",
  //       price: 0,
  //       status: {
  //         color: "var(--other-dropdown-row-bg-hover)",
  //         name: "статус"
  //       },
  //       counteragentId: "5cecf960cf7197169a3a0bf3"
  //     }],
  //     counteragents: [{
  //       name: 'The One',
  //       id: '5cecf960cf7197169a3a0bf3',
  //     }],
  //     extNumber: "+7 (963) 313 67-55"
  //   })
  // }
}
</script>

<style lang="scss" scoped>
.call {
  &-wrapper {
    position: fixed;
    right: -20px;
    bottom: 40px;
    z-index: 150;
    padding: 35px 40px 0px;
    overflow: hidden;
    &:hover .call__btns {
      opacity: 1;
    }
  }
  &__btns {
    position: absolute;
    top: 0px;
    left: 40px;
    right: 40px;
    display: flex;
    justify-content: space-between;
    opacity: 0;
    transition: opacity 0.3s;
    .cb-all, .cb-close {
      outline: none;
      border: none;
      padding: 3px 42px 4px 42px;
      border-radius: 8px;
      &:hover {
        color: #FFFFFF;
      }
    }
    .cb-all {
      padding-right: 22px;
      color: #157EFC;
      background-color: #CEDCF2;
      position: relative;
      &__icon {
        position: absolute;
        top: 4px;
        left: 22px;
        background: transparent url('../../assets/img/call-notifications/list-icon.svg') 0% 0% no-repeat padding-box;
        display: inline-block;
        width: 16px;
        height: 16px;
      }
      &:hover {
        background-color: #157EFC;
        .cb-all__icon {
          background: transparent url('../../assets/img/call-notifications/list-icon-active.svg') 0% 0% no-repeat padding-box;
        }
      }
    }
    .cb-close {
      color: #ED5152;
      // background-color: #FDEEEE;
      background-color: #fbe3e3;
      &:hover {
        background-color: #ED5152;
      }
    }
  }
  width: 320px;
  border-radius: 8px;
  position: relative;
  padding-left: 12px;
  box-shadow: 0px 0px 32px #00000052;
  background-color: var(--aside-bg);
  & + & {
    margin-top: -45px;
  }
  &__line {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 12px;
    border-radius: 8px 0px 0px 8px;
    &--missed {
      background: #ED5152 0% 0% no-repeat padding-box;
    }
    &--answered {
      background: #9191AB 0% 0% no-repeat padding-box;
    }
    &--internal {
      background: #8E8E8E 0% 0% no-repeat padding-box;
    }
    &--green {
      background: #4AC772 0% 0% no-repeat padding-box;
    }
  }
  &__header {
    height: 90px;
    transition: height 0.3s;
    display: flex;
    align-items: center;
    border-radius: 0 8px 0 0;
    background: var(--aside-bg-97) 0% 0% no-repeat padding-box;
    padding: 13px;
    padding-bottom: 58px;
    cursor: pointer;
    .ch {
      &-icon {
        height: 18px;
        margin-right: 10px;
        align-self: flex-start;
      }
      &-name {
        flex: 1 1;
      }
      &-phone, &-client {
        white-space: nowrap;
        max-width: 195px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &-client {
        font-size: 12px;
        color: var(--aside-color-60);
      }
      &-time {
        color: var(--aside-color-60);
      }
      &-close {
        visibility: collapse;
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }
  &__counteragent {
    padding: 13px;
    position: relative;
    display: flex;
    align-items: flex-start;
    &-icon {
      margin-top: 1px;
      margin-left: 1px;
      margin-right: 11px;
    }
    &-data {
      flex: 1;
    }
    &-name {
      margin: 0;
      display: inline-block;
      font-weight: 600;
      border-bottom: 1px dashed var(--main-color-60);
      line-height: normal;
      cursor: pointer;
      &:hover {
        border-bottom-color: transparent;
      }
    }
    &-phone {
      margin: 0;
      margin-top: 3px;
      color: var(--aside-color-60);
    }
    &-sms {
      display: inline-block;
      background-color: var(--color-success);
      border-radius: 3px;
      padding: 0 4px;
      text-transform: uppercase;
      font-weight: 700;
      color: #FFFFFF;
      font-size: 11px;
      padding-bottom: 1px;
      line-height: 14px;
      vertical-align: top;
      margin-top: 2px;
      margin-left: 5px;
      cursor: pointer;
      &:hover {
        background-color: #169B59;
      }
    }
    &-switcher {
      .sw-arrow {
        color: #aaa;
        font-size: 12px;
        cursor: pointer;
        &:hover {
          color: inherit;
        }
      }
    }
  }
  &__orders {
    display: flex;
    background: var(--aside-bg-97) 0% 0% no-repeat padding-box;
    padding: 6px 13px;
    justify-content: space-between;
    align-items: center;
    color: #157EFC;
    cursor: pointer;
    margin-bottom: 2px;
    &:hover {
      background-color: var(--main-table-header-bg-hover);
    }
    &-zero {
      color: var(--aside-color-60);
    }
    &-add {
      height: 24px;
      width: 24px;
      border-radius: 4px;
      background-color: #157EFC;
      border: 1px solid #157EFC;
      outline: none;
      font-size: 24px;
      line-height: 16px;
      padding: 0;
      color: #FFFFFF;
      &:hover {
        background-color: #0A50BA;
      }
    }
  }
  &__orders-table {
    margin-top: 8px;
    margin-bottom: 8px;
    width: 100%;
    cursor: pointer;
    &-wrapper::v-deep .__panel {
      max-height: 100px;
    }
    td {
      padding: 6px;
    }
    // tr:first-child td {
    //   padding-top: 13px;
    // }
    // tr:last-child td {
    //   padding-bottom: 13px;
    // }
    tr td:first-child {
      padding-left: 13px;
    }
    tr:hover td {
      background-color: var(--aside-bg-97);
    }
  }
  &__status-circle {
    display: inline-block;
    border-radius: 50%;
    width: 6px;
    height: 6px;
    margin-bottom: 1px;
  }
  &__comment {
    &-wrapper {
      padding: 13px;
      background: var(--aside-bg-97) 0% 0% no-repeat padding-box;
      border-radius: 0px 0px 8px 0px;
    }
    outline: none;
    border-radius: 4px;
    border: 1px solid transparent;
    padding: 8px;
    min-height: 64px;
    transition: min-height 0.3s;
    &:empty {
      border: 1px solid var(--input-border);
      background-color: var(--input-bg);
      min-height: 32px;
      &:before {
        content: attr(placeholder);
        color: #999;
        font-size: 13px;
        font-weight: normal;
        display: block; /* For Firefox */
        white-space: pre;
      }
    }
    &:focus {
      border-color: var(--color-primary);
    }
    &:focus {
      min-height: 64px;
    }
    &--not-border:hover {
      border: 1px solid transparent;
      background-color: transparent;
    }
  }
  &.call--active {
    .call__header {
      height: 45px;
      padding-bottom: 13px;
      .ch-client {
        display: none;
      }
      .ch-close {
        visibility: visible;
      }
    }
    & ~ .call {
      .call__header {
        padding-top: 58px;
        padding-bottom: 13px;
        border-radius: 0 0 8px 0;
      }
    }
  }
}
// Анимация открытия
.call-enter-active {
  transition: max-height .7s;
  overflow: hidden;
}
.call-leave-active {
  transition: max-height .5s;
  overflow: hidden;
}
.call-enter-to, .call-leave {
  max-height: 400px;
}
.call-enter, .call-leave-to {
  max-height: 0;
}
.calls-enter-active, .calls-leave-active {
  transition: max-height .7s;
}
.calls-enter-to, .calls-leave {
  max-height: 400px;
}
.calls-enter, .calls-leave-to {
  max-height: 45px;
}
</style>