<template>
  <div class="switcher">
    <slot name="header">
      <p class="switcher__header" :style="titleStyle">{{ title || "" }}</p>
    </slot>
    <div class="switcher__container" :style="{ width: allWidth || 'auto' }">
      <button
        v-for="(option, index) in items"
        :key="index"
        class="btn switcher__btn"
        :class="[
          !~color.indexOf('#') ? 'btn-' + color : '',
          value !== option ? 'btn-inverse' : '',
          getType
        ]"
        :style="compBtnStyles[index]"
        :disabled="disabled"
        @click="change(option)"
      >
        <slot name="option" :text="option">
          <slot :name="index">{{ option }}</slot>
        </slot>
      </button>
    </div>
    <slot name="middle">
      <input
        v-if="showInput"
        type="text"
        class="switcher__input form-control custom-input pull-left"
        :value="message"
        @input="updateValue($event.target.value)"
      />
    </slot>
    <div v-if="showDescription" class="switcher__active-label pull-right">
      <slot name="description">{{ value === "нет" ? "" : value }}</slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "v-switch",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    value: [Number, Object],
    options: Array,
    count: {
      type: Number,
      default: 2
    },
    title: String,
    color: {
      type: String,
      default: "info"
    },
    type: {
      type: String,
      default: "normal"
    },
    showDescription: Boolean,
    showInput: Boolean,
    allWidth: Number,
    widths: Array,
    disabled: Boolean
  },
  data() {
    return {
      message: this.value || ""
    };
  },
  computed: {
    items() {
      return Array.isArray(this.options) && this.options.length
        ? this.options
        : this.count
        ? Array.apply(null, { length: this.count }).map(Number.call, Number)
        : [];
    },
    getType() {
      if (this.$props.type === "small") {
        return "btn-small";
      } else if (this.$props.type === "pages") {
        return "btn-small pages";
      } else if (this.$props.type === "small middle") {
        return "btn-small middle";
      } else {
        return "";
      }
    },
    titleStyle() {
      return this.$props.showInput
        ? {
            textAlign: "left",
            marginLeft: "40px"
          }
        : {
            textAlign: "center"
          };
    },
    compBtnStyles() {
      let styles = this.items.map((item, index) => ({
        width:
          this.widths && this.widths[index]
            ? typeof this.widths[index] === "number"
              ? this.widths[index] + "px"
              : this.widths[index]
            : "auto"
      }));
      if (~this.color.indexOf("#")) {
        styles = styles.map((stl, index) => {
          let isActive = this.items.indexOf(this.value) === index;
          return Object.assign(stl, {
            border: "1px solid " + this.color,
            backgroundColor: isActive ? this.color : "white",
            color: isActive ? "white" : this.color
          });
        });
      }
      return styles;
    }
  },
  watch: {
    value: function(newValue) {
      this.$emit("changed", newValue);
    }
  },
  methods: {
    change(newValue) {
      this.$emit("change", newValue);
    },
    updateValue(value) {
      this.$emit("input", value.trim());
    }
  }
};
</script>
<style lang="scss" scoped>
.switcher {
  position: relative;
  .switcher__header {
    margin-bottom: 0;
    text-align: center;
    color: gray;
    font-size: 11px;
    &.bold-text {
      font-weight: 600;
      color: var(--main-color-60);
    }
  }
  .switcher__container {
    width: auto;
  }
  .switcher__btn {
    // margin-left: 0;
    // border-radius: 0;
    // z-index: 0 !important;
    &:focus,
    &:active {
      outline: 0;
    }
    &:first-of-type {
      border-radius: 4px 0 0 4px;
    }
    &:last-of-type {
      border-radius: 0 4px 4px 0;
    }
    &.btn-small {
      height: 25px;
      padding: 0;
      width: 30px;
      &.pages {
        width: 40px;
      }
      &.middle {
        vertical-align: middle;
      }
    }
  }
  .switcher-placeholder {
    font-size: 9px;
    margin-bottom: 0;
  }
  .switcher__active-label {
    margin-left: 5px;
    line-height: 25px;
  }
  .switcher__input {
    width: 60px;
    font-weight: 600;
    text-align: center;
    height: 25px;
    margin-left: 5px;
  }
}
</style>
