<template>
  <div
    contenteditable="true"
    class="contenteditable custom-editable"
    :placeholder="placeholder"
    @input="update"
    @keydown.enter="send"
    @keydown="keydownEv"
    @paste="pasteEv"
    @blur="$emit('blur')"
  ></div>
</template>

<script>
export default {
  name: 'v_content_editable',
  props: {
    content: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    sendWithoutShift: {
      type: Boolean,
      default: true
    },
    clearOnSend: {
      type: Boolean,
      default: true
    },
    maxlength: Number
  },
  data () {
    return {
      text: this.$props.content,
      utils: {}
    }
  },
  watch: {
    content (newValue) {
      if (newValue !== this.text) {
        this.$el.innerText = newValue
      }
    }
  },
  mounted () {
    this.$el.innerText = this.content
  },
  methods: {
    update (event) {
      this.text = event.target.innerText
      this.$emit('update', event.target.innerText)
    },
    send (event) {
      if (this.$props.sendWithoutShift && !event.ctrlKey && !event.shiftKey) {
        event.preventDefault()
        this.$emit('send', event.target.innerText)
        if (this.clearOnSend) {
          this.$el.innerText = ''
          this.text = ''
        }
      } else if (!this.$props.sendWithoutShift && event.ctrlKey) {
        event.preventDefault()
        this.$emit('send', event.target.innerText)
      }
    },
    keydownEv (event) {
      const len = event.target.innerText.trim().length
      let hasSelection = false
      const selection = window.getSelection()
      const isSpecial = this.utils.isSpecial(event)
      const isNavigational = this.utils.isNavigational(event)

      if (selection) {
        hasSelection = !!selection.toString()
      }

      if (isSpecial || isNavigational) {
        return true
      }

      if (this.maxlength && len >= this.maxlength && !hasSelection) {
        event.preventDefault()
        return false
      }
    },
    pasteEv (event) {
      const len = event.target.innerText.trim().length

      if (this.maxlength && len >= this.maxlength) {
        event.preventDefault()
        return false
      } else if (this.maxlength && (len + event.clipboardData.getData('Text').length) > this.maxlength) {
        event.preventDefault()
        this.$emit('update', (this.text + event.clipboardData.getData('Text'))
          .substring(0, this.maxlength))
        return false
      } else {
        return true
      }
    }
  },
  created () {
    const keys = {
      backspace: 8,
      shift: 16,
      ctrl: 17,
      alt: 18,
      delete: 46,
      meta: 91,
      leftArrow: 37,
      upArrow: 38,
      rightArrow: 39,
      downArrow: 40
    }
    const utils = {
      special: {},
      navigational: {},
      isSpecial (e) {
        return typeof this.special[e.keyCode] !== 'undefined' || e.ctrlKey
      },
      isNavigational (e) {
        return typeof this.navigational[e.keyCode] !== 'undefined'
      }
    }
    utils.special[keys.backspace] = true
    utils.special[keys.shift] = true
    utils.special[keys.ctrl] = true
    utils.special[keys.alt] = true
    utils.special[keys.delete] = true

    utils.navigational[keys.upArrow] = true
    utils.navigational[keys.downArrow] = true
    utils.navigational[keys.leftArrow] = true
    utils.navigational[keys.rightArrow] = true

    this.utils = utils
  }
}
</script>

<style lang="css" scoped>
[contenteditable="true"]:empty:before {
  content: attr(placeholder);
  color: var(--color-placeholder);
  font-size: 13px;
  display: block; /* For Firefox */
  white-space: pre;
}
</style>
