<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="isMouseEnter && hoverWidth? hoverWidth : width"
    :height="isMouseEnter && hoverHeight? hoverHeight : height"
    viewBox="0 0 20 20"
    class="icon-close"
    fill="none"
    @mouseenter="isMouseEnter = true"
    @mouseleave="isMouseEnter = false"
  >
    <path d="M0,0H20V20H0Z"/>
    <path :class="compColor" d="M16.151,5.66a.83.83,0,0,0-1.175,0L10.9,9.726,6.826,5.651A.831.831,0,1,0,5.651,6.826L9.726,10.9,5.651,14.976a.831.831,0,0,0,1.175,1.175L10.9,12.076l4.075,4.075a.831.831,0,0,0,1.175-1.175L12.076,10.9l4.075-4.075A.835.835,0,0,0,16.151,5.66Z" transform="translate(-0.901 -0.901)"/>
  </svg>
</template>

<script>
export default {
  name: "IconClose",
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    },
    hoverWidth: Number,
    hoverHeight: Number,
    iconColor: {
      type: String,
      default: "primary"
    }
  },
  data() {
    return {
      isMouseEnter: false
    }
  },
  computed: {
    compColor() {
      return `color-${this.iconColor}`
    }
  }
};
</script>

<style lang="scss" scoped>
path:first-child {
  fill: none;
}
.color-main {
  fill: var(--main-color);
}
.color-primary {
  fill: var(--color-primary);
}
.color-success {
  fill: var(--color-success);
}
.color-cancel {
  fill: var(--color-danger);
}
.color-yellow {
  fill: var(--color-warning);
}
.color-white {
  fill: #FFFFFF;
}
.icon-close {
  transition: all .3s;
}
</style>