<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="isMouseEnter && hoverWidth? hoverWidth : width"
    :height="isMouseEnter && hoverHeight? hoverHeight : height"
    viewBox="0 0 14 14"
    class="icon-dots"
    fill="none"
    :class="compDirection"
    :disabled="disabled"
    @mouseenter="isMouseEnter = true"
    @mouseleave="isMouseEnter = false"
    @click="clickEvent"
  >
    <path
      d="M7.094 2.025c.666-.139 1.378.325 1.53.987.191.684-.278 1.453-.967 1.61-.687.196-1.457-.28-1.614-.97-.2-.715.323-1.508 1.05-1.627zM7.094 5.691c.666-.138 1.378.326 1.53.988.191.684-.278 1.453-.967 1.61-.687.196-1.457-.28-1.614-.97-.2-.716.323-1.508 1.05-1.628zM7.094 9.358c.666-.139 1.378.326 1.53.988.191.683-.278 1.453-.967 1.61-.687.196-1.457-.28-1.614-.971-.2-.715.323-1.507 1.05-1.627z"
      :class="compColor"
    />
  </svg>
</template>

<script>
export default {
  name: "IconDots",
  props: {
    width: {
      type: Number,
      default: 14
    },
    height: {
      type: Number,
      default: 14
    },
    hoverWidth: Number,
    hoverHeight: Number,
    iconColor: {
      type: String,
      default: "gray"
    },
    hoverColor: {
      type: String,
      default: "primary"
    },
    iconDirection: {
      type: String,
      default: "bottom"
    },
    disabled: Boolean
  },
  data() {
    return {
      isMouseEnter: false
    }
  },
  computed: {
    compColor() {
      return `color-${this.isMouseEnter && !this.disabled ? this.hoverColor : this.iconColor}`
    },
    compDirection() {
      return `direction-${this.iconDirection}`
    }
  },
  methods: {
    clickEvent(ev) {
      if (!this.disabled) this.$emit('click', ev)
    }
  }
};
</script>

<style lang="scss" scoped>
.color-gray {
  fill: var(--main-color-40);
}
.color-active-gray {
  fill: var(--main-color-60);
}
.color-main {
  fill: var(--main-color);
}
.color-aside {
  fill: var(--aside-color);
}
.color-primary {
  fill: var(--color-primary);
}
.color-success {
  fill: var(--color-success);
}
.color-cancel {
  fill: var(--color-danger);
}
.color-yellow {
  fill: var(--color-warning);
}
.color-white {
  fill: #FFFFFF;
}
.color-dashed-name {
  fill: var(--main-color-40);
}
.icon-dots {
  transition: all .3s;
  &[disabled="disabled"] {
    opacity: 0.6;
  }
}
.direction-top {
  transform: rotate(0.5turn);
}
</style>