<template>
  <div
    v-show="isShow"
    class="themes-modal"
    :style="{ left: left + 'px', top: top + 'px' }"
    @mousedown="startColResize($event)"
  >
    <div v-if="activeTheme" class="active-theme">
      <div class="active-theme__groups">
        <input
          ref="theme-name"
          v-focus="!activeTheme.id"
          v-model="activeTheme.name"
          placeholder="Название темы"
          class="active-theme__name form-control"
        >
        <div
          v-for="group in activeTheme.colors.filter(({isHidden}) => !isHidden)"
          :key="group.id"
          class="color-group"
          :class="activeGroup && group.id === activeGroup.id ? 'color-group--active' : ''"
          @click="setActiveGroup(group)"
        >{{ $t(`themes.${group.id}.name`) }}</div>
        <div
          class="color-group"
          :class="activeGroup && activeGroup.id === 'statuses' ? 'color-group--active' : ''"
          @click="setActiveGroup({ id: 'statuses' })"
        >Статусы</div>
        <div
          class="color-group"
          :class="activeGroup && activeGroup.id === 'locations' ? 'color-group--active' : ''"
          @click="setActiveGroup({ id: 'locations' })"
        >Название локации</div>
        <div class="theme-btns">
          <button class="btn btn-primary" @click="createOrUpdate">Сохранить</button>
          <button class="btn btn-secondary" @click="cancelTheme">Отмена</button>
        </div>
      </div>
      <div class="active-theme__colors">
        <template v-if="activeGroup && activeGroup.items">
        <div
          v-for="color in activeGroup.items.filter(({ isHidden }) => !isHidden)"
          :key="color.id"
          class="color"
        >
          <div class="color__name">
            {{ $t(`themes.${activeGroup.id}.${color.id}`) }}
          </div>
          <div
            v-for="otherColor in color.otherColors"
            :key="otherColor.id"
            class="color-circle"
            :class="otherColor.value === color.value && !color.isShowWindow ? 'active' : ''"
            v-tooltip="otherColor.name"
            @click="setColor(color, otherColor.value, $event)"
          >
            <div class="color-circle__circle" :style="{ backgroundColor: otherColor.value }"></div>
          </div>
          <div
            class="color-circle color-circle--not-active"
            :class="[color.value === color.value ? 'active' : '']"
            @click="focusInput"
          >
            <div class="color-circle__circle" :style="{ backgroundColor: color.value }"></div>
          </div>
          <div class="color-circle-plus">
            <input
              type="color"
              class="color-input"
              :value="color.value"
              @input="setFallbackColor($event, color)"
            >
            <IconMiniPlus
              class="btn-icon color-circle__circle"
              style="display: inline-block;"
              v-tooltip="'Добавить цвет'"
              @click.native="focusInput"
            />
          </div>
        </div>
        </template>
        <div v-else-if="activeGroup && activeGroup.id === 'statuses'">
          <div style="padding:12px 0 5px">Вид статуса:</div>
          <v-select-new
            v-model="statusType"
            :items="statusTypes"
            :searchable="false"
            :withClearBtn="false"
            @input="setStatusType"
          ></v-select-new>
        </div>
        <div v-else-if="activeGroup && activeGroup.id === 'locations'">
          <div style="padding:12px 0 5px">Вид названия локации:</div>
          <v-select-new
            v-model="locationType"
            :items="locationTypes"
            :searchable="false"
            :withClearBtn="false"
            @input="setLocationType"
          ></v-select-new>
        </div>
      </div>
    </div>
    <template v-else>
      <div class="themes-modal__header">
        <div class="themes-modal__header__title">Оформление</div>
        <icon-mini-close
          class="themes-modal__header__close"
          :width="24"
          :height="24"
          iconColor="main"
          @click.native="cancelChanges"
        />
      </div>
      <v-tabs
        classes="themes-tabs"
        marginBottom="20px"
        :activePage="activeTab"
        :pages="tabs"
        @open="activeTab = $event"
      ></v-tabs>
      <template v-if="activeTab.id === 'list'">
        <template v-if="themes && themes.length">
          <v-scroll-2 class="theme-scroll">
          <template v-for="i in 1">
          <div
            v-for="currentTheme in themes"
            :key="currentTheme.id + '_' + i"
            class="theme"
            :class="checkIsActive(currentTheme) ? 'active' : ''"
            @click="setActiveTheme(currentTheme)"
          >
            <div class="theme__circle-wrapper">
              <div class="theme__circle" :style="{background: `linear-gradient(135deg, ${
                currentTheme.colors.find(( {id} ) => id === 'menu').items.find(( {id} ) => id === 'bg').value
              } 0%, ${
                currentTheme.colors.find(( {id} ) => id === 'menu').items.find(( {id} ) => id === 'bg').value
              } 50%, ${
                currentTheme.colors.find(( {id} ) => id === 'main').items.find(( {id} ) => id === 'bg').value
              } 50%, ${
                currentTheme.colors.find(( {id} ) => id === 'main').items.find(( {id} ) => id === 'bg').value
              } 100%)`}"></div>
            </div>
            <div class="theme__name">{{ currentTheme.name }}</div>
            <div
              v-if="isAuto && dayTheme && currentTheme.id === dayTheme.id"
              style="margin-left: 12px;cursor: pointer;height: 18px;display: flex;position: relative;"
              v-tooltip="{ content: 'Выбрана в качестве “Дневной темы” на вкладке “Авто-смена”', classes: 'svg-tooltip-1'}"
            >
              <img src="../../../assets/img/icons/day-theme.svg">
            </div>
            <div
              v-if="isAuto && nightTheme && currentTheme.id === nightTheme.id"
              style="margin-left: 12px;cursor: pointer;height: 18px;display: flex;position: relative;"
              v-tooltip="{ content: 'Выбрана в качестве “Ночной темы” на вкладке “Авто-смена”', classes: 'svg-tooltip-2'}"
            >
              <img src="../../../assets/img/icons/night-theme.svg">
            </div>
            <template v-if="!currentTheme.isSystem">
              <div class="theme__edit" @click.stop="openExistTheme(currentTheme)">
                <IconPencil iconColor="primary"></IconPencil>
              </div>
              <div class="theme__delete" @click.stop="deleteTheme(currentTheme)">
                <IconTrash iconColor="danger" hoverColor="danger"></IconTrash>
              </div>
            </template>
          </div>
          </template>
          </v-scroll-2>
        </template>
        <button class="btn btn-primary-opacity" style="margin-top:8px;margin-bottom:8px" @click="openNewTheme">
          <IconMiniPlus class="btn-icon"/>
          <span>Добавить тему</span>
        </button>
      </template>
      <template v-else-if="activeTab.id === 'auto'">
        <label
          class="v-radio-btn"
          :class="!isAuto ? 'active' : ''"
        >
          <input
            type="radio"
            name="months"
            v-model="isAuto"
            :value="false"
            :checked="!isAuto"
            
            @change="setAutoTheme"
          />
          <span>Не использовать</span>
        </label>
        <label
          class="v-radio-btn"
          :class="isAuto ? 'active' : ''"
        >
          <input
            type="radio"
            name="months"
            v-model="isAuto"
            :value="true"
            :checked="isAuto"
            
            @change="setAutoTheme"
          />
          <span>Автоматическая смена темы на дневную и ночную</span>
        </label>
        <template v-if="isAuto">
          <div class="select-item">
            <v-select-new
              v-model="dayTheme"
              :items="themes"
              :searchable="false"
              :withClearBtn="false"
              @input="updateAutoTheme"
            ></v-select-new>
            <span>дневная тема</span>
          </div>
          <div class="select-item">
            <v-select-new
              v-model="nightTheme"
              :items="themes"
              :searchable="false"
              :withClearBtn="false"
              @input="updateAutoTheme"
            ></v-select-new>
            <span>ночная тема</span>
          </div>
        </template>
        <div class="auto-message">Автоматическое переключение темы будет работать только в том случае, 
          если такая опция включена в настройках вашей операционной системы.</div>
      </template>
      <template v-else-if="activeTab.id === 'font'">
        <div class="select-item">
          <span>Шрифт</span>
          <v-select-new
            v-model="font"
            :items="fonts"
            :searchable="false"
            :withClearBtn="false"
            @input="updateFont"
          ></v-select-new>
        </div>
        <div class="select-item">
          <span>Размер</span>
          <v-select-new
            v-model="fontSize"
            :items="fontSizes"
            :searchable="false"
            :withClearBtn="false"
            @input="updateFontSize"
          ></v-select-new>
        </div>
      </template>
      <template v-else-if="activeTab.id === 'menu'">
        <div class="menu-message">Выберите расположение</div>
        <label
          class="v-radio-btn"
          :class="~$store.state.menuWidth ? 'active' : ''"
        >
          <input
            type="radio"
            name="months"
            :checked="~$store.state.menuWidth"
            
            @change="updateMenuType('left')"
          />
          <span>Слева</span>
          <v-dropdown
            style="margin-left:5px"
            type="bottom-left"
            :width="110"
            @click.native.stop.prevent
          >
            <template #title>
              <span class="link-primary">{{ $store.state.menuWidth === 70 ? 'свернутое' : 'развернутое' }}</span>
            </template>
            <div class="dropdown-item" @click="updateMenuType('left')">развернутое</div>
            <div class="dropdown-item" @click="updateMenuType('left-mini')">свернутое</div>
          </v-dropdown>
        </label>
        <label
          class="v-radio-btn"
          :class="!~$store.state.menuWidth ? 'active' : ''"
        >
          <input
            type="radio"
            name="months"
            :checked="!~$store.state.menuWidth"
            
            @change="updateMenuType('top')"
          />
          <span>Сверху</span>
        </label>
      </template>
    </template>
    <div v-if="!activeTheme" class="theme-btns">
      <button v-if="btnDisabled" class="btn btn-primary" @click="cancelChanges">Закрыть</button>
      <template v-else>
        <button class="btn btn-primary" @click="saveChanges">Применить</button>
        <button class="btn btn-secondary" @click="cancelChanges">Отмена</button>
      </template>
    </div>
    <v-modal-new
      v-if="deletingTheme"
      :title="`Удаление темы - ${deletingTheme.name}`"
      bodyPadding="0 20px"
      okButtonColor="danger"
      okButtonText="Удалить"
      :okButtonDisabled="isLoading"
      @success="deleteTheme"
      @close="deletingTheme = null"
    >Восстановить тему будет нельзя.</v-modal-new>
  </div>
</template>
<script>
import vTabs from "../resources/v_tabs.vue";
import vModalNew from "../resources/v_modal_new";
import _ from "lodash";
import { setTheme, checkColorIsDark, prepareThemes } from "../../../services/colors-api";
import IconMiniClose from '../../icons/IconMiniClose.vue';
import IconPencil from "../../icons/orders/IconPen.vue";
import IconTrash from "../../icons/IconTrash3.vue";
import vSelectNew from "../resources/multiselect/v_select_new.vue";
import IconMiniPlus from "../../icons/orders/IconMiniPlus.vue";
import vDropdown from "../resources/v_dropdown.vue";
import { createTheme, deleteTheme, loadThemes, saveThemeSettings, updateTheme } from "../../../services/_themes.js";
export default {
  name: "themes",
  components: {
    vTabs,
    vModalNew,
    IconMiniClose,
    IconMiniPlus,
    IconPencil,
    IconTrash,
    vSelectNew,
    vDropdown
  },
  props: {
    isShow: Boolean
  },
  data() {
    const tabs = [
      { description: "Темы", id: "list" },
      { description: "Авто-смена", id: "auto" },
      { description: "Шрифт", id: "font" },
      { description: "Меню", id: "menu" },
    ]
    const menuTypes = [
      { name: "Сверху", id: "top"},
      { name: "Слева", id: "left"},
    ]
    const fonts = [
      {
        id: 0,
        value: "PT Root UI",
        name: "По умолчанию"
      },
      // Стандартные шрифты на Windows:
      {
        id: 1,
        value: "Arial",
        name: "Arial"
      },
      {
        id: 2,
        value: "Verdana",
        name: "Verdana"
      },
      {
        id: 3,
        value: "Times New Roman",
        name: "Times New Roman"
      },
      {
        id: 4,
        value: "Courier New",
        name: "Courier New"
      },
      {
        id: 5,
        value: "Georgia",
        name: "Georgia"
      },
      {
        id: 6,
        value: "Trebuchet MS",
        name: "Trebuchet MS"
      },
      {
        id: 7,
        value: "Comic Sans MS",
        name: "Comic Sans MS"
      },
      {
        id: 8,
        value: "Impact",
        name: "Impact"
      },
      // Стандартные шрифты на macOS:
      {
        id: 9, // San Francisco (системный шрифт, недоступен напрямую, но можно использовать -apple-system)
        value: "-apple-system",
        name: "-apple-system"
      },
      {
        id: 10,
        value: "Helvetica",
        name: "Helvetica"
      },
      {
        id: 11,
        value: "Helvetica Neue",
        name: "Helvetica Neue"
      },
      {
        id: 12,
        value: "Lucida Grande",
        name: "Lucida Grande"
      },
      {
        id: 13,
        value: "Geneva",
        name: "Geneva"
      },
      // Menlo (моноширинный шрифт) - проверить, будет ли он отличаться от monospace
      {
        id: 14,
        value: "Menlo",
        name: "Menlo"
      },
    ]
    const fontSizes = [{
      id: 13,
      name: "По умолчанию"
    }, {
      id: 17,
      name: "+4"
    }, {
      id: 16,
      name: "+3"
    }, {
      id: 15,
      name: "+2"
    }, {
      id: 14,
      name: "+1"
    }, {
      id: 12,
      name: "-1"
    }, {
      id: 11,
      name: "-2"
    }, {
      id: 10,
      name: "-3"
    }, {
      id: 9,
      name: "-4"
    }]
    return {
      activeTab: tabs[0],
      tabs: tabs,
      menuType: this.$store.getters.isLeftMenu ? menuTypes[1] : menuTypes[0],
      menuTypes: menuTypes,
      isLoading: false,
      isAuto: Array.isArray(this.$store.state.activeTheme),
      activeTheme: null,
      deletingTheme: null,
      oldTheme: null,
      theme: this.$store.state.activeTheme,
      themes: [],
      activeGroup: null,
      left: 0,
      top: 0,
      isMoving: false,
      startOffset: null,
      dayTheme: null,
      nightTheme: null,
      font: fonts.find(({ value }) => value === this.$store.state.font),
      fonts: fonts,
      fontSize: fontSizes.find(({ id }) => id === this.$store.state.fontSize),
      fontSizes: fontSizes,
      oldMenuWidth: this.$store.state.menuWidth,
      statusType: null,
      statusTypes: [{
        id: "normal",
        name: "Системный"
      }, {
        id: "inverse",
        name: "Инвентированный"
      }],
      locationType: null,
      locationTypes: [{
        id: "normal",
        name: "Системный"
      }, {
        id: "inverse",
        name: "Инвентированный"
      }, {
        id: "primary",
        name: "Одноцветный"
      }, {
        id: "line",
        name: "С линией"
      }],
      isBlockEvents: false
    }
  },
  computed: {
    btnDisabled() {
      if (!this.dayTheme || !this.nightTheme) return true
      const oldThemeId = this.getThemeId(this.$store.state.activeTheme)
      const newThemeId = this.isAuto
        ? this.getThemeId([this.dayTheme, this.nightTheme])
        : this.theme.id
      return this.$store.state.font === this.font?.value &&
        this.$store.state.fontSize === this.fontSize?.id &&
        this.oldMenuWidth === this.$store.state.menuWidth &&
        _.isEqual(oldThemeId, newThemeId)
    }
  },
  watch: {
    isShow() {
      if (this.isShow) {
        this.theme = this.$store.state.activeTheme
        this.isAuto = Array.isArray(this.$store.state.activeTheme)
        this.activeTab = this.tabs[0]
        this.loadThemes()
        this.modalToMiddle()
        document.addEventListener("mouseup", this.endColResize);
        document.addEventListener("mousemove", this.changeColSize)
      } else {
        document.removeEventListener("mouseup", this.endColResize);
        document.removeEventListener("mousemove", this.changeColSize);
      }
    }
  },
  methods: {
    getThemeId(theme) {
      return Array.isArray(theme)
        ? theme.map(({ id }) => id).sort()
        : theme.id
    },
    saveChanges() {
      if (!this.isBlockEvents) {
        if (!this.btnDisabled) {
          const params = {}
          const oldThemeId = this.getThemeId(this.$store.state.activeTheme)
          const newThemeId = this.isAuto
            ? this.getThemeId([this.dayTheme, this.nightTheme])
            : this.theme.id
          if (!_.isEqual(oldThemeId, newThemeId)) {
            if (this.isAuto) {
              params.lightThemeId = this.dayTheme.id
              params.darkThemeId = this.nightTheme.id
            } else {
              params.checkedThemeId = this.theme.id
            }
          }
          if (this.font?.value !== this.$store.state.font) {
            params.fontName = this.font.value
          }
          if (this.fontSize?.id !== this.$store.state.fontSize) {
            params.fontSize = this.fontSize.id
          }
          if (this.oldMenuWidth !== this.$store.state.menuWidth) {
            params.menuWidth = this.$store.state.menuWidth
          }
          this.saveThemeSettings(params, true)
        }
      }
    },
    close() {
      if (!this.isBlockEvents) {
        this.$emit('update:isShow', false)
      }
    },
    cancelChanges() {
      if (!this.isBlockEvents) {
        if (this.font?.value !== this.$store.state.font) {
          this.font = this.fonts.find(({ value }) => value === this.$store.state.font)
        }
        if (this.fontSize?.id !== this.$store.state.fontSize) {
          this.fontSize = this.fontSizes.find(({ id }) => id === this.$store.state.fontSize)
        }
        const savedLightTheme = this.themes.find(({ isLight }) => isLight)
          || this.themes.find(({ isLightDefault }) => isLightDefault)
        const savedDarkTheme = this.themes.find(({ isDark }) => isDark)
          || this.themes.find(({ isDarkDefault }) => isDarkDefault)
        if (this.dayTheme?.id !== savedLightTheme?.id) this.dayTheme = savedLightTheme
        if (this.nightTheme?.id !== savedDarkTheme?.id) this.nightTheme = savedDarkTheme

        if (this.isAuto !== Array.isArray(this.$store.state.activeTheme)) {
          this.isAuto = Array.isArray(this.$store.state.activeTheme)
        }
        if (Array.isArray(this.theme) !== Array.isArray(this.$store.state.activeTheme.id) ||
          !Array.isArray(this.theme) &&
            !Array.isArray(this.$store.state.activeTheme.id) &&
            this.theme.id !== this.$store.state.activeTheme.id
        ) {
          this.theme = this.$store.state.activeTheme
        }
        this.setTheme(this.theme)

        if (this.oldMenuWidth !== this.$store.state.menuWidth) {
          this.$store.dispatch("updateMenuWidth", {
            value: this.oldMenuWidth,
            function: this.callAPI
          })
        }
        this.close()
      }
    },
    cancelTheme() {
      if (!this.isBlockEvents) {
        this.setTheme(this.oldTheme)
        this.activeTheme = null
      }
    },
    startColResize(e) {
      this.startOffset = {
        left: this.left,
        pageX: e.pageX,
        top: this.top,
        pageY: e.pageY
      };
      this.isMoving = true
      document.body.classList.add("modal-resize");
    },
    endColResize() {
      if (this.isMoving) {
        this.startOffset = null;
        this.isMoving = false
        document.body.classList.remove("modal-resize");
        setTimeout(() => {
          this.isBlockEvents = false
        }, 50)
      }
    },
    changeColSize(e) {
      if (this.isMoving) {
        // this.isBlockEvents = true
        setTimeout(() => {
          if (this.isMoving) this.isBlockEvents = true
        }, 300)
        const windowHeight = document.documentElement.clientHeight || window.innerHeight,
          windowWidth = document.documentElement.clientWidth || window.innerWidth,
          { width } = this.$el?.getBoundingClientRect?.() || {}
        let left = Math.round(this.startOffset.left + e.pageX - this.startOffset.pageX);
        let top = Math.round(this.startOffset.top + e.pageY - this.startOffset.pageY);
        left = left < 100 - width
          ? 100 - width
          : left > windowWidth - 50
          ? windowWidth - 50
          : left
        top = top < 0
          ? 0
          : top > windowHeight - 50
          ? windowHeight - 50
          : top
        this.left = left;
        this.top = top;
      }
    },
    modalToMiddle() {
      this.$nextTick(() => {
        const windowHeight = document.documentElement.clientHeight || window.innerHeight,
          windowWidth = document.documentElement.clientWidth || window.innerWidth,
          { height, width } = this.$el?.getBoundingClientRect?.() || {}
        this.top = (windowHeight - height) / 2
        this.left = (windowWidth - width) / 2
      })
    },
    updateMenuType(id) {
      if (!this.isBlockEvents) {
        this.$store.dispatch("updateMenuWidth", {
          value: id === 'top'
            ? -1
            : id === 'left-mini'
            ? 70
            : this.$store.state.defaultMenuWidth,
          function: this.callAPI
        })
      }
    },
    checkColorIsDark(color) {
      return checkColorIsDark(color)
    },
    createOrUpdate() {
      if (!this.activeTheme.name) {
        this.showMessage("Введите название темы")
        this.$refs["theme-name"]?.focus?.()
      } else if (this.activeTheme.id) this.updateTheme()
      else this.createTheme()
    },
    openNewTheme() {
      if (!this.isBlockEvents) {
        this.oldTheme = this.theme
        this.activeTheme = {
          name: "",
          statusType: this.theme && !Array.isArray(this.theme)
            ? this.theme.statusType
            : this.checkIsDark()
            ? this.nightTheme.statusType
            : this.dayTheme.statusType,
          locationType: this.theme && !Array.isArray(this.theme)
            ? this.theme.locationType
            : this.checkIsDark()
            ? this.nightTheme.locationType
            : this.dayTheme.locationType,
          colors: this.theme && !Array.isArray(this.theme)
            ? _.cloneDeep(this.theme.colors)
            : this.checkIsDark()
            ? _.cloneDeep(this.nightTheme.colors)
            : _.cloneDeep(this.dayTheme.colors)
        }
        this.setActiveGroup(this.activeTheme.colors[0])
        this.setTheme(this.activeTheme)
        this.statusType = this.statusTypes.find(({id}) => id === this.activeTheme.statusType)
        this.locationType = this.locationTypes.find(({id}) => id === this.activeTheme.locationType)
      }
    },
    setActiveGroup(group) {
      if (!this.isBlockEvents) {
        this.activeGroup = group
        if (group.items) {
          group.items.forEach(color => {
            color.otherColors = []
            if (this.activeTheme.id) color.oldValue = color.value
          })
          this.themes.forEach(theme => {
            const themeGroup = theme.colors.find(({ id }) => id === this.activeGroup.id)
            if (themeGroup) {
              themeGroup.items.forEach((color, index) => {
                const otherColor = group.items[index].otherColors.find(({ value }) => value === color.value)
                if (otherColor) {
                  otherColor.name += ", " + theme.name
                } else
                // if (
                //   !group.items[index].oldValue ||
                //   group.items[index].oldValue !== color.value
                // ) {
                  group.items[index].otherColors.push({ value: color.value, name: theme.name, id: theme.id })
                // }
              })
            }
          })
        }
      }
    },
    openExistTheme(theme) {
      if (!this.isBlockEvents) {
        this.oldTheme = this.theme
        this.activeTheme = _.cloneDeep(theme)
        this.setActiveGroup(this.activeTheme.colors[0])
        this.setTheme(this.activeTheme)
        this.statusType = this.statusTypes.find(({id}) => id === this.activeTheme.statusType)
        this.locationType = this.locationTypes.find(({id}) => id === this.activeTheme.locationType)
      }
    },
    checkIsDark() {
      return !!(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)
    },
    setAutoTheme() {
      if (!this.isBlockEvents) {
        if (this.isAuto) {
          this.setTheme([this.dayTheme, this.nightTheme])
        } else {
          this.setTheme(this.checkIsDark() ? this.nightTheme : this.dayTheme)
        }
      }
    },
    updateAutoTheme() {
      if (!this.isBlockEvents) {
        this.setTheme([this.dayTheme, this.nightTheme])
      }
    },
    isFontAvailable(font) {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const text = 'abcdefghijklmnopqrstuvwxyz0123456789';

      context.font = '72px monospace';
      const baselineSize = context.measureText(text).width;

      context.font = `72px ${font}, monospace`;
      const newSize = context.measureText(text).width;

      return baselineSize !== newSize;
    },
    updateFont() {
      if (!this.isBlockEvents) {
        this.setTheme(this.$store.state.activeTheme)
      }
    },
    updateFontSize() {
      if (!this.isBlockEvents) {
        this.setTheme(this.$store.state.activeTheme)
      }
    },
    setActiveTheme(theme) {
      if (!this.isBlockEvents) {
        if (Array.isArray(this.theme) || this.theme.id !== theme.id) {
          this.setTheme(theme)
          if (this.isAuto) this.isAuto = !this.isAuto
        }
      }
    },
    setTheme(theme) {
      if (!this.isBlockEvents) {
        this.theme = theme
        setTheme(
          Array.isArray(this.theme)
            ? this.theme.map(theme => ({
              ...theme,
              isLight: theme.id === this.dayTheme?.id,
              isDark: theme.id === this.nightTheme?.id
            }))
            : this.theme,
          this.font?.value,
          this.fontSize?.id
        )
      }
    },
    saveThemeSettings(params, isClose) {
      if (!this.isBlockEvents && !this.isLoading) {
        this.isLoading = true
        return new Promise((resolve, reject) => {
          saveThemeSettings(params)
            .then(() => {
              this.isLoading = false
              if (params.checkedThemeId || params.lightThemeId || params.darkThemeId) {
                if (params.lightThemeId || params.darkThemeId) {
                  this.themes.forEach(theme => {
                    this.$delete(theme, "isLight")
                    this.$delete(theme, "isDark")
                  })
                  this.$set(this.dayTheme, "isLight", true)
                  this.$set(this.nightTheme, "isDark", true)
                }
                this.$store.commit("setTheme", _.cloneDeep(this.theme))
              }
              if (params.fontName) this.$store.commit("setFont", this.font.value)
              if (params.fontSize) this.$store.commit("setFontSize", this.fontSize.id)
              if (params.menuWidth) this.oldMenuWidth = this.$store.state.menuWidth
              if (isClose) this.close()
              resolve()
            })
            .catch(() => {
              this.isLoading = false
              reject()
            })
        })
      }
    },
    setColor(color, value, evt) {
      if (!this.isBlockEvents) {
        if (color.value !== value) {
          this.$set(color, "value", value)
          this.setTheme(this.activeTheme)
        } else {
          this.focusInput(evt)
        }
      }
    },
    setStatusType() {
      if (!this.isBlockEvents) {
        this.$set(this.activeTheme, "statusType", this.statusType.id)
        this.setTheme(this.activeTheme)
      }
    },
    setLocationType() {
      if (!this.isBlockEvents) {
        this.$set(this.activeTheme, "locationType", this.locationType.id)
        this.setTheme(this.activeTheme)
      }
    },
    updateColor({ hex }, color) {
      if (!this.isBlockEvents) {
        if (color.value !== hex) {
          this.$set(color, "value", hex)
          // this.endColorUpdate()
          this.setTheme(this.activeTheme)
        }
      }
    },
    focusInput(evt) {
      evt.target.closest?.(".color")?.querySelector(".color-input")?.click?.()
    },
    setFallbackColor(evt, color) {
      if (!this.isBlockEvents) {
        if (color.value !== evt.target.value) {
          this.$set(color, "value", evt.target.value)
          this.setTheme(this.activeTheme)
        }
      }
    },
    createTheme() {
      if (!this.isBlockEvents && !this.isLoading) {
        this.createThemeAPI({
          name: this.activeTheme.name,
          items: this.activeTheme.colors.reduce((result, {id: groupId, items}) => {
            result.push(...items.map(({id, value}) => ({groupId, id, value})))
            return result
          }, []),
          statusType: this.statusType?.id,
          locationType: this.locationType?.id,
        })
      }
    },
    updateTheme() {
      if (!this.isBlockEvents && !this.isLoading) {
        this.$set(this.activeTheme, "statusType", this.statusType?.id)
        this.$set(this.activeTheme, "locationType", this.locationType?.id)
        this.updateThemeAPI(this.activeTheme.id, {
          name: this.activeTheme.name,
          items: this.activeTheme.colors.reduce((result, {id: groupId, items}) => {
            result.push(...items.map(({id, value}) => ({groupId, id, value})))
            return result
          }, []),
          statusType: this.statusType?.id,
          locationType: this.locationType?.id,
        })
      }
    },
    checkIsActive(theme) {
      return Array.isArray(this.theme)
        ? this.theme.some(({ id, isDark, isDarkDefault }) => id === theme.id && (this.checkIsDark() === (isDark || isDarkDefault || false)))
        : this.theme.id === theme.id
    },
    deleteTheme(theme) {
      if (!this.isBlockEvents && !this.isLoading) {
        const activeTheme = theme || this.activeTheme || this.deletingTheme
        if (!this.deletingTheme) {
          this.deletingTheme = activeTheme
        } else {
          this.deleteThemeAPI(this.deletingTheme.id)
        }
      }
    },
    createThemeAPI(newTheme) {
      this.isLoading = true
      createTheme(newTheme)
        .then(body => {
          this.isLoading = false
          this.themes.push(...prepareThemes([body.data]))
          this.setTheme(this.themes.at(-1))
          if (this.isAuto) this.isAuto = false
          this.activeTheme = null
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    updateThemeAPI(id, theme) {
      this.isLoading = true
      updateTheme(id, theme)
        .then(body => {
          this.isLoading = false
          const index = this.themes.findIndex(theme => theme.id === id)
          if (~index) this.themes.splice(index, 1, ...prepareThemes([body.data]))
          this.setTheme(this.themes[index])
          if (this.isAuto) this.isAuto = false
          // this.saveThemeSettings({ checkedThemeId: this.themes[index].id}, this.themes[index])
          this.activeTheme = null
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    deleteThemeAPI(id) {
      const prom = !Array.isArray(this.$store.state.activeTheme)
        && this.$store.state.activeTheme.id === id
        && (Array.isArray(this.theme) || this.theme.id !== id)
        ? this.saveThemeSettings(
            Array.isArray(this.theme)
              ? { lightThemeId: this.dayTheme.id, darkThemeId: this.nightTheme.id }
              : { checkedThemeId: this.theme.id }
        )
        : Promise.resolve()
      this.isLoading = true
      prom
        .then(() => deleteTheme(id))
        .then(() => {
          this.isLoading = false
          const index = this.themes.findIndex(theme => theme.id === id)
          if (~index) this.themes.splice(index, 1)
          if (this.dayTheme.id === id) this.dayTheme = this.themes.find(({ isLight }) => isLight)
            || this.themes.find(({ isLightDefault }) => isLightDefault)
          if (this.nightTheme.id === id) this.nightTheme = this.themes.find(({ isDark }) => isDark)
            || this.themes.find(({ isDarkDefault }) => isDarkDefault)
          if (
            !Array.isArray(this.$store.state.activeTheme) &&
            this.$store.state.activeTheme.id === id &&
            !Array.isArray(this.theme) &&
            this.theme.id === id
          ) {
            // this.setTheme(this.themes[index])
            this.theme = this.themes.find(({ isLightDefault }) => isLightDefault)
            this.$store.commit("setTheme", _.cloneDeep(this.theme))
          } else if (
            !Array.isArray(this.theme)
            && this.theme.id === id
            && (Array.isArray(this.$store.state.activeTheme) || this.$store.state.activeTheme.id !== id)
          ) {
            this.setTheme(this.$store.state.activeTheme)
          } else if (
            Array.isArray(this.theme)
            && Array.isArray(this.$store.state.activeTheme)
          ) {
            this.theme = [this.dayTheme, this.nightTheme]
          }
          this.deletingTheme = null
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    loadThemes() {
      loadThemes().then(body => {
        this.themes = prepareThemes(body.data)
        if (Array.isArray(this.$store.state.activeTheme)) {
          const [theme1, theme2] = this.$store.state.activeTheme
          this.themes.forEach(theme => {
            if (theme1.id === theme.id) {
              if (theme1.isLight) this.$set(theme, "isLight", true)
              if (theme1.isDark) this.$set(theme, "isDark", true)
            }
            if (theme2.id === theme.id) {
              if (theme2.isLight) this.$set(theme, "isLight", true)
              if (theme2.isDark) this.$set(theme, "isDark", true)
            }
          })
        }
        this.dayTheme = this.themes.find(({ isLight }) => isLight)
          || this.themes.find(({ isLightDefault }) => isLightDefault)
        this.nightTheme = this.themes.find(({ isDark }) => isDark)
          || this.themes.find(({ isDarkDefault }) => isDarkDefault)
        this.fonts = this.fonts.filter(font => font.name === "По умолчанию" || this.isFontAvailable(font.value))
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.themes-modal {
  --main-bg: #F7F8FA;
  --main-bg-105: #FFFFFF;
  --main-bg-98: #F2F3F5;
  --main-bg-96: #EDEEF0;
  --main-bg-95: #EBECEE;
  --main-bg-93: #EDEDED;
  --main-bg-80: #CCCCCC;
  --main-color: #0F172A;
  --main-color-80: #3F4555;
  --main-color-60: #6F747F;
  --main-color-40: #9FA2AA;
  --main-color-30: #B7B9BF;
  --main-color-20: #CFD1D4;
  --main-color-10: #E7E8EA;
  --main-color-5: #F3F3F4;
  --main-primary: #317BFF;
  --main-primary-120: #0861FF;
  --main-primary-50: #98BDFF;
  --main-primary-50-op: rgba(49, 123, 255, 0.5);
  --main-primary-15-op: rgba(49, 123, 255, 0.15);
  --main-primary-10-op: rgba(49, 123, 255, 0.1);
  --main-primary-8-op: rgba(49, 123, 255, 0.08);
  --main-primary-5-op: rgba(49, 123, 255, 0.05);
  --main-primary-15: #E0EBFF;
  --main-primary-10: #EAF2FF;
  --main-primary-5: #F5F8FF;
  --main-primary-color: #FFFFFF;
  --color-primary: #317BFF;
  --color-primary-120: #0861FF;
  --color-primary-50: #98BDFF;
  --color-primary-50-op: rgba(49, 123, 255, 0.5);
  --color-primary-15-op: rgba(49, 123, 255, 0.15);
  --color-primary-10-op: rgba(49, 123, 255, 0.1);
  --color-primary-8-op: rgba(49, 123, 255, 0.08);
  --color-primary-5-op: rgba(49, 123, 255, 0.05);
  --color-primary-15: #E0EBFF;
  --color-primary-10: #EAF2FF;
  --color-primary-5: #F5F8FF;
  --color-success: #03C03C;
  --color-success-120: #00B315;
  --color-success-15-op: rgba(3, 192, 60, 0.15);
  --color-success-10-op: rgba(3, 192, 60, 0.1);
  --color-success-8-op: rgba(3, 192, 60, 0.08);
  --color-success-5-op: rgba(3, 192, 60, 0.05);
  --color-danger: #F0142F;
  --color-danger-30-op: rgba(240, 20, 47, 0.3);
  --color-danger-20-op: rgba(240, 20, 47, 0.2);
  --color-danger-15-op: rgba(240, 20, 47, 0.15);
  --color-danger-10-op: rgba(240, 20, 47, 0.1);
  --color-danger-8-op: rgba(240, 20, 47, 0.08);
  --color-danger-5-op: rgba(240, 20, 47, 0.05);
  --color-danger-120: #ED0005;
  --color-danger-20: #FCD0D5;
  --color-danger-10: #FEE8EA;
  --color-danger-5: #FEF3F5;
  --color-warning: #FEC72E;
  --color-warning-20-op: rgba(254, 199, 46, 0.2);
  --color-warning-10-op: rgba(254, 199, 46, 0.1);
  --color-warning-8-op: rgba(254, 199, 46, 0.08);
  --color-orange: #FF8311;
  --color-orange-10-op: rgba(255, 131, 17, 0.1);
  --color-orange-8-op: rgba(255, 131, 17, 0.08);
  --color-info: #66CCFF;
  --color-info-120: #47C2FF;
  --color-info-15-op: rgba(102, 204, 255, 0.15);
  --table-header: #F2F3F5;
  --table-bg: #FFFFFF;
  --table-bg-98: #FAFAFA;
  --table-bg-95: #EBECEE;
  --table-bg-93: #EDEDED;
  --table-bg-90: #E6E6E6;
  --table-bg-80: #CCCCCC;
  --table-color: #0F172A;



  --color-placeholder: var(--main-color-60);
  --input-fz: var(--main-fz);

  --main-table-row-bg: var(--table-bg);
  --main-table-row-color: var(--table-color);
  --main-table-row-bg-hover: var(--color-primary-5-op);
  --main-table-row-bg-active: var(--color-primary-10-op);
  --main-table-header-bg: var(--table-header);
  // --main-table-header-color: green;
  --main-table-header-bg-hover: var(--main-bg-95);
  --main-table-border: var(--main-color-10);
  --dropdown-bg: var(--table-bg);

  --aside-bg: var(--table-bg);
  --aside-bg-98: var(--table-bg-98);
  --aside-bg-95: var(--table-bg-95);
  --aside-bg-90: var(--table-bg-90);
  --aside-color: var(--table-color);
  --other-dropdown-row-bg-hover: var(--color-primary-15-op);
  --other-dropdown-row-bg-active: var(--table-bg-90);
  --other-dropdown-shadow-1: 1px 1px 5px 0px var(--main-color-5), 2px 5px 10px 0px var(--main-color-20);
  // --other-dropdown-shadow-2: 0 4px 6px -2px rgba(126, 132, 163, 0.05), 0 0 15px -3px rgba(126, 132, 163, 0.2);
  --other-dropdown-shadow-2: var(--other-dropdown-shadow-1);

  --modal-box-shadow: 0px 16px 32px 0px rgba(22, 34, 51, 0.16), 0px 4px 12px -4px rgba(22, 34, 51, 0.12);
  --table-border: solid 0.5px #7c8fbf66;
  --table-row-border-color: var(--main-table-border);
  --table-subtext: var(--table-color-60);

  // --input-fz: 20px;
  // Разные типы полей: текстовые, селекты, textarea, даты
  // Свойства: bg, border, shadow, font-weight, placeholder (color),
  // Состояния: обычное, hover, focus, disabled, selected, validate

  // Обычное
  --input-color: var(--main-color);
  --input-bg: var(--aside-bg);
  --input-border: var(--main-color-20);
  --input-background-border: var(--main-color-20);
  --input-shadow: none;
  --input-fw: 600;
  --input-placeholder: var(--color-placeholder);
  --input-placeholder-fw: 400;
  --input-placeholder-fz: var(--input-fz);

  // hover
  --input-hover-bg: var(--input-bg);
  --input-hover-border: var(--input-border);
  --input-hover-shadow: var(--input-shadow);
  --input-hover-fw: var(--input-fw);
  --input-hover-placeholder: var(--input-placeholder);

  // focus
  --input-focus-bg: var(--input-bg);
  --input-focus-border: var(--color-primary);
  --input-focus-shadow: var(--input-shadow);
  --input-focus-fw: var(--input-fw);
  --input-focus-placeholder: var(--input-placeholder);

  // // disabled
  --input-disabled-bg: var(--main-color-10);
  --input-disabled-border: var(--main-color-10);
  --input-disabled-shadow: var(--input-shadow);
  --input-disabled-fw: var(--input-fw);
  --input-disabled-placeholder: var(--input-placeholder);

  // selected
  --input-selected-bg: var(--input-bg);
  --input-selected-border: var(--input-border);
  --input-selected-shadow: var(--input-shadow);
  --input-selected-fw: var(--input-fw);
  --input-selected-placeholder: var(--color-placeholder);
  // hover-selected
  --input-hover-selected-bg: var(--input-bg);
  --input-hover-selected-border: var(--input-border);
  --input-hover-selected-shadow: var(--input-shadow);
  --input-hover-selected-fw: var(--input-fw);
  --input-hover-selected-placeholder: var(--color-placeholder);
  // focus-selected
  --input-focus-selected-bg: var(--input-bg);
  --input-focus-selected-border: var(--color-primary);
  --input-focus-selected-shadow: var(--input-shadow);
  --input-focus-selected-fw: var(--input-fw);
  --input-focus-selected-placeholder: var(--color-placeholder);
  // // validate
  --input-validate-color: var(--color-danger);
  --input-validate-bg: var(--color-danger-10);
  --input-validate-border: var(--color-danger-20);
  --input-validate-shadow: var(--input-shadow);
  --input-validate-fw: var(--input-fw);
  --input-validate-placeholder: var(--color-danger);
}
.themes-modal {
  position: fixed;
  top: 300px;
  left: 300px;
  width: 570px;
  height: 400px;
  background-color: var(--aside-bg);
  // background-color: #FFFFFF;
  border: var(--main-modal-border);
  // box-shadow: var(--main-dropdown-shadow);
  // border: 1px solid #98BDFF;
  color: #0F172A;
  border-radius: 12px;
  z-index: 6;
  padding: 20px 25px;
  box-shadow: 0px 4px 12px -4px rgba(22, 34, 51, 0.12), 0px 16px 32px 0px rgba(22, 34, 51, 0.16);
  display: flex;
  flex-direction: column;
  align-items: start;
  &__header {
    display: flex;
    margin-bottom: 15px;
    user-select: none;
    width: 100%;
    &__title {
      font-size: var(--header-fz);
      font-weight: 500;
      line-height: 24px;
    }
    &__close {
      margin-left: auto;
      cursor: pointer;
      // width: 24px;
      // height: 24px;
      padding: 8px;
      border-radius: 6px;
      background-color: var(--main-bg-98);
      // background-color: #F2F3F5;
      &:hover, &:active {
        background: var(--main-color-10);
        // background: #E7E8EA;
      }
      &:active {
        opacity: 0.5;
      }
    }
  }
  &::v-deep .themes-tabs .tab-switcher__item {
    padding-bottom: 6px;
    color: var(--main-color-80);
    // color: #3F4555;
    &.active {
      color: var(--main-color);
      // color: #0F172A;
    }
    &::after {
      border-radius: 4px 4px 2px 2px;
      height: 3px;
    }
  }
  .theme {
    display: flex;
    align-items: center;
    cursor: pointer;
    &.active {
      .theme__circle-wrapper {
        border-color: var(--color-primary);
        // border-color: #317BFF;
      }
      .theme__name {
        font-weight: 600;
      }
    }
    &:not(.active):hover .theme__circle-wrapper {
      border-color: var(--main-color-20);
      // border-color: #CFD1D4;
    }
    & + .theme {
      margin-top: 4px;
    }
    &__circle {
      // background-color: gray;
      // background: linear-gradient(135deg, gray 0%, gray 50%, white 50%, white 100%);
      border-radius: 4px;
      width: 100%;
      height: 100%;
      &-wrapper {
        width: 24px;
        height: 24px;
        border-radius: 7px;
        border: 1.5px solid transparent;
        padding: 2.5px;
        margin-right: 4px;
      }
    }
    &__name {

    }
    &__edit, &__delete {
      display: none;
    }
    &:hover {
      .theme__edit, .theme__delete {
        display: block;
        width: 24px;
        height: 24px;
        background-color: var(--main-bg-98);
        // background-color: #F2F3F5;
        border-radius: 4px;
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          background-color: var(--main-bg-95);
          // background-color: #EBECEE;
        }
        &:active {
          opacity: .5;
        }
      }
    }



    // &__delete {
    //   margin-left: 10px;
    // }
  }
  .active-theme {
    display: flex;
    height: 100%;
    width: 100%;
    &__name {
      font-size: calc(var(--main-fz) + 5px);
      font-weight: 500;
      margin-bottom: 18px;
      &::-webkit-input-placeholder,
      &::-moz-placeholder,
      &:-ms-input-placeholder,
      &:-moz-placeholder,
      &::placeholder {
        color: var(--main-color-60);
        // color: #6F747F;
        font-weight: 500;
        font-size: calc(var(--main-fz) + 5px);
      }
      &:focus {
        border-color: var(--color-primary);
        // border-color: #317BFF;
      }
    }
    &__groups {
      width: 200px;
      flex: 0 0 200px;
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      // .theme-btns {
      //   margin-top: 20px;
      // }
      .color-group {
        padding: 0 10px;
        line-height: 30px;
        position: relative;
        cursor: pointer;
        &--active {
          background-color: var(--main-bg-98);
          // background-color: #F2F3F5;
          border-radius: 4px;
          &::after {
            display: block;
            content: "";
            position: absolute;
            right: 0;
            width: 4px;
            top: 6px;
            height: calc(100% - 12px);
            border-radius: 2px;
            background-color: var(--color-primary);
            // background-color: #317BFF;
          }
        }
      }
    }
    &__colors {
      height: 100%;
      width: 100%;
      border-radius: 10px;
      padding: 0 12px;
      background-color: var(--main-bg);
    }
    .color {
      margin-top: 16px;
      &__name {
        font-size: calc(var(--main-fz) + 1px);
      }
    }
  }
  .theme-scroll {
    width: 100%;
    max-height: 200px;
    height: auto !important;
    &::v-deep .__panel {
      max-height: 200px;
    }
  }
  .theme-btns {
    margin-top: auto;
    .btn + .btn {
      margin-left: 20px;
    }
  }
  .v-radio-btn {
    font-size: inherit;
  }
}
.modal-resize .themes-modal {
  cursor: move;
}
.color-circle {
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  // padding: 3px;
  border: 1px solid transparent;
  // background-color: #fff;
  display: inline-flex;
  margin-right: 4px;
  &__circle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    outline: none;
    width: 16px;
    height: 16px;
  }
  &.active ~ .color-circle:last-child, & {
    border-color: transparent;
  }
  &.active ~ .color-circle--not-active {
    display: none;
  }
  // border: 1px solid gray;
  cursor: pointer;
  &:hover {
    border-color: var(--main-color-40);
  }
  &.active {
    // padding: 2px;
    border: 2px solid var(--color-primary);
    // border-color: var(--color-primary);
    // border-color: #317BFF;
  }
  &:last-child {
    border-color: var(--color-primary);
    // border-color: #317BFF;
  }
  // &--dark {
  //   color: #FFFFFF;
  // }
  // &--light {
  //   color: var(--main-color);
  //   // color: #0F172A;
  // }
  &-plus {
    width: 24px;
    height: 24px;
    display: inline-block;
    padding: 2px;
    // margin-left: 2px;
    vertical-align: middle;
    position: relative;
    .color-circle__circle {
      height: 100%;
      width: 100%;
      cursor: pointer;
    }
    // &::v-deep:hover svg {
    //   circle{
    //     fill-opacity: 1;
    //   }
    //   path {
    //     stroke: #FFFFFF;
    //   }
    // }
    &::v-deep svg {
      &:hover {
        circle{
          fill-opacity: 1;
          transition: all .3s;
        }
        path {
          stroke: #FFFFFF;
          transition: all .3s;
        }
      }
    }
  }
}
.color-input {
  width: 100%;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 5px;
  border: 1px solid #dcdcdc;
  color: #35495e;
  background: #fff;
  visibility: hidden;
  display: inline-block;
  border: 0;
  padding: 0;
  width: 0;
  height: 20px;
  vertical-align: top;
  // display: none;
  // &::before {
  //   content: "+";
  //   display: block;
  //   width: 20px;
  //   height: 20px;
  //   border-radius: 50%;
  //   background-color: var(--color-primary-10-op);
  // }
}
.color-window {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1;
}
.menu-message {
  margin-top: -6px;
  margin-bottom: 20px;
  font-size: calc(var(--main-fz) - 1px);
  color: var(--main-color-60);
  // color: #3F4555;

}
.v-radio-btn + .select-item {
  margin-top: 25px;
}
.select-item {
  .select {
    display: inline-block;
    width: 190px;
    margin-right: 12px;
  }
  span:first-child {
    display: inline-block;
    width: 57px;
  }
  & + .select-item {
    margin-top: 10px;
  }
}
.auto-message {
  margin-top: auto;
  color: var(--main-color-60);
  // color: #3F4555;
  font-size: calc(var(--main-fz) - 1px);
}
.auto-message + .theme-btns {
  margin-top: 20px;
}
</style>