import store from "../store";
function colorHexToNumber(color) {
  if (!color.indexOf('#')) {
    color = color.slice(1)
    if (color.length === 3) color = color[0] + color[0] + color[1] + color[1] + color[2] + color[2]
    return {
      r: parseInt(color.slice(0, 2), 16),
      g: parseInt(color.slice(2, 4), 16),
      b: parseInt(color.slice(4), 16)
    }
  } else {
    return {}
  }
}
function colorNumberToHex({r, g, b} = { r: 0, g: 0, b: 0}) {
  var componentToHex = function(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }
  return "#" + componentToHex(r).toUpperCase() + componentToHex(g).toUpperCase() + componentToHex(b).toUpperCase();
}
function checkColorIsDark(color) {
  const { r, g, b } = colorHexToNumber(color)
  return r < 128 || g < 128 || b < 128
}
function darken(color, { percent, opacity}) {
  const { r, g, b } = colorHexToNumber(color),
    alfa = percent / 100;
  return opacity
    ? `rgba(${r}, ${g}, ${b}, ${alfa})`
    : colorNumberToHex({
      r: Math.round(belowMax(r * alfa)),
      g: Math.round(belowMax(g * alfa)),
      b: Math.round(belowMax(b * alfa))
    })
}
function belowMax(value) {
  return value > 255 ? 255 : value
}
function lighten(color, { percent, opacity}) {
  // opacity
  // 0% | 0 - белый (255, 255, 255)
  // 100% | 1 - текущий цвет
  const { r, g, b } = colorHexToNumber(color),
    alfa = percent / 100;
  return opacity
    ? `rgba(${r}, ${g}, ${b}, ${alfa})`
    : colorNumberToHex({
      r: Math.round(higherZero(r + ((255 - r) * (1 - alfa)))),
      g: Math.round(higherZero(g + ((255 - g) * (1 - alfa)))),
      b: Math.round(higherZero(b + ((255 - b) * (1 - alfa))))
    })
}
function getBrightness(rgb) {
  return (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000 / 255;
}
function isLight(rgb) {
  return getBrightness(rgb) >= 0.5;
}
function isDark(rgb) {
  return getBrightness(rgb) <= 0.5;
}
function higherZero(value) {
  return value < 0 ? 0 : value
}
function darkenOrLighten(color, variant) {
  return checkColorIsDark(color)
    ? lighten(color, variant)
    : darken(color, variant)
}
function prepareVariant(variant, groupId, id, value) {
  let name = variant.name || id
  let res = variant.isByBrightness
    ? variant.percent
      ? darkenOrLighten(isLight(colorHexToNumber(value))
        ? "#0F172A"
        : variant.isFullWhite
        ? "#FFFFFF"
        : "#DDDDDD",
        variant
      )
      : (isLight(colorHexToNumber(value))
        ? "#0F172A"
        : variant.isFullWhite
        ? "#FFFFFF"
        : "#DDDDDD")
    : darkenOrLighten(value, variant)
  return `--${groupId}-${name}${variant.percent ? '-' + variant.percent : ''}${variant.opacity ? '-op' : ''}: ${res};`
}
function prepareRulesSet(theme, isMultiple) {
  let innerHTML = '#app {'
  theme.colors.forEach(({id: groupId, items, space}) => {
    if (!space) {
      items.forEach(({id, value, variants}) => {
        innerHTML += `--${groupId}-${id}: ${value};`
        if (variants) {
          variants.forEach(variant => {
            innerHTML += prepareVariant(variant, groupId, id, value)
          });
        }
      })
    }
  })
  const statusTypes = {
    normal: false,
    inverse: false,
  }
  statusTypes[theme.statusType] = true
  Object.keys(statusTypes).forEach(key => {
    innerHTML += `--status-${key}: ${statusTypes[key] ? 'inline-block' : 'none'};`;
  })
  const locationTypes = {
    normal: false,
    inverse: false,
    primary: false,
    line: false
  }
  locationTypes[theme.locationType] = true
  Object.keys(locationTypes).forEach(key => {
    innerHTML += `--location-${key}: ${locationTypes[key] ? 'block' : 'none'};`;
  })
  innerHTML += '}'
  theme.colors.forEach(({id: groupId, items, space}) => {
    if (space) {
      innerHTML += `${space} {`
      items.forEach(({id, value, variants}) => {
        innerHTML += `--${groupId}-${id}: ${value};`
        if (variants) {
          variants.forEach(variant => {
            innerHTML += prepareVariant(variant, groupId, id, value)
          });
        }
      })
      innerHTML += '}'
    }
  })
  if (theme.isDark && isMultiple) {
    innerHTML = "@media screen and (prefers-color-scheme: dark) {" + innerHTML + "}"
  }
  return innerHTML
}
function setThemeType(themes) {
  let className = ""
  if (Array.isArray(themes)) {
    themes.forEach(theme => {
      const mainBG = theme.colors?.find?.(({id}) => id === "main")?.items?.find?.(({id}) => id === "bg")?.value
      if (mainBG) {
        const isThemeDark = isDark(colorHexToNumber(mainBG))
        className += (className ? ' ' : '') + (theme.isDark
          ? isThemeDark
            ? 'dark-is-dark'
            : 'dark-is-light'
          : isThemeDark
          ? 'light-is-dark'
          : 'light-is-light')
      }
    })
  } else  {
    const theme = themes
    const mainBG = theme.colors?.find?.(({id}) => id === "main")?.items?.find?.(({id}) => id === "bg")?.value
    if (mainBG) {
      const isThemeDark = isDark(colorHexToNumber(mainBG))
      className = isThemeDark ? 'dark-theme' : 'light-theme'
    }
  }
  store.commit("setThemeClasses", className)
}
function setTheme(theme, font, fontSize) {
  let style = document.getElementById('main-css-colors-theme')
  if (style) document.getElementsByTagName('head')[0].removeChild(style)

  style = document.createElement('style');
  style.type = 'text/css';
  style.id = "main-css-colors-theme"
  let innerHTML = ""
  if (Array.isArray(theme)) {
    const light = theme.find(({isLight}) => isLight)
    const dark = theme.find(({isDark}) => isDark);
    [light, dark].forEach(theme => innerHTML += prepareRulesSet(theme, true))
    setThemeType([light, dark])
  } else {
    innerHTML += prepareRulesSet(theme)
    setThemeType(theme)
  }
  innerHTML += `#app { --main-font: ${font}; --main-fz: ${fontSize}px; }`
  style.innerHTML = innerHTML
  document.getElementsByTagName('head')[0].appendChild(style);
}
function getActiveTheme(themes) {
  return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    ? themes.find(({isDark}) => isDark)
    : themes.find(({isLight}) => isLight)
}
const variants = {
  "menu.bg": [
    {percent: "95"},
    {percent: "85"},
    {name: "color", isByBrightness: true},
    {name: "color", isByBrightness: true, percent: "85", opacity: true},
    {name: "color", isByBrightness: true, percent: "70", opacity: true},
    {name: "color", isByBrightness: true, percent: "50", opacity: true},
    {name: "color", isByBrightness: true, percent: "10", opacity: true},
    {name: "color", isByBrightness: true, percent: "5", opacity: true}
  ],
  "menu.active": [
    {percent: "50", opacity: true},
    {percent: "25", opacity: true},
    {percent: "10", opacity: true}
  ],
  "main.bg": [
    {percent: "105"},
    {percent: "98"},
    {percent: "96"},
    {percent: "95"},
    {percent: "93"},
    {percent: "92"},
    {percent: "90"},
    {percent: "85"},
    {percent: "80"},
    {percent: "70"},
    {name: "color", isByBrightness: true},
    {name: "color", isByBrightness: true, percent: "80"},
    {name: "color", isByBrightness: true, percent: "60"},
    {name: "color", isByBrightness: true, percent: "40"},
    {name: "color", isByBrightness: true, percent: "30"},
    {name: "color", isByBrightness: true, percent: "20"},
    {name: "color", isByBrightness: true, percent: "10"},
    {name: "color", isByBrightness: true, percent: "5"},
    {name: "color", isByBrightness: true, percent: "70", opacity: true},
    {name: "color", isByBrightness: true, percent: "30", opacity: true},
    {name: "color", isByBrightness: true, percent: "10", opacity: true}
  ],
  "main.primary": [
    {percent: "120"},
    {percent: "50"},
    {percent: "50", opacity: true},
    {percent: "15", opacity: true},
    {percent: "10", opacity: true},
    {percent: "8", opacity: true},
    {percent: "5", opacity: true},
    {percent: "15"},
    {percent: "10"},
    {percent: "5"},
    {name: "primary-color", isByBrightness: true, isFullWhite: true},
  ],
  "color.primary": [
    {percent: "120"},
    {percent: "50"},
    {percent: "50", opacity: true},
    {percent: "15", opacity: true},
    {percent: "10", opacity: true},
    {percent: "8", opacity: true},
    {percent: "5", opacity: true},
    {percent: "15"},
    {percent: "10"},
    {percent: "5"},
  ],
  "color.success": [
    {percent: "120"},
    {percent: "50", opacity: true},
    {percent: "20", opacity: true},
    {percent: "15", opacity: true},
    {percent: "10", opacity: true},
    {percent: "8", opacity: true},
    {percent: "5", opacity: true},
  ],
  "color.danger": [
    {percent: "50", opacity: true},
    {percent: "30", opacity: true},
    {percent: "20", opacity: true},
    {percent: "15", opacity: true},
    {percent: "10", opacity: true},
    {percent: "8", opacity: true},
    {percent: "5", opacity: true},
    {percent: "120"},
    {percent: "85"},
    {percent: "20"},
    {percent: "10"},
    {percent: "5"}
  ],
  "color.warning": [
    {percent: "120"},
    {percent: "70", opacity: true},
    {percent: "50", opacity: true},
    {percent: "20", opacity: true},
    {percent: "10", opacity: true},
    {percent: "8", opacity: true},
  ],
  "color.orange": [
    {percent: "60", opacity: true},
    {percent: "30", opacity: true},
    {percent: "20", opacity: true},
    {percent: "15", opacity: true},
    {percent: "10", opacity: true},
    {percent: "8", opacity: true},
  ],
  "table.header": [
    {percent: "95"},
    {name: "header-color", isByBrightness: true, percent: "85", opacity: true}
  ],
  "table.bg": [
    {percent: "105"},
    {percent: "98"},
    {percent: "97"},
    {percent: "96"},
    {percent: "95"},
    {percent: "93"},
    {percent: "92"},
    {percent: "90"},
    {percent: "85"},
    {percent: "80"},
    {name: "color", isByBrightness: true},
    {name: "color", isByBrightness: true, percent: "80"},
    {name: "color", isByBrightness: true, percent: "60"},
    {name: "color", isByBrightness: true, percent: "40"},
    {name: "color", isByBrightness: true, percent: "30"},
    {name: "color", isByBrightness: true, percent: "20"},
    {name: "color", isByBrightness: true, percent: "10"},
    {name: "color", isByBrightness: true, percent: "5"}
  ]
}
function prepareThemes(themes) {
  themes.forEach(theme => {
    theme.colors = theme.items.reduce((colors, item) => {
      let group = colors.find(({ id }) => id === item.groupId)
      if (!group) {
        group = {
          id: item.groupId,
          items: [],
          isHidden: item.groupId === "color"
        }
        if (item.groupId === "menu") group.space = "#nav-wrapper, #sidebar-menu-wrapper"
        colors.push(group)
      }
      group.items.push({
        id: item.id,
        value: item.value,
        variants: variants[`${item.groupId}.${item.id}`] || undefined
      })
      return colors
    }, [])
  })
  return themes
}
export {
  colorHexToNumber,
  checkColorIsDark,
  setTheme,
  isLight,
  darken,
  getActiveTheme,
  prepareThemes
}