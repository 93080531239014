import Vue from "vue";
import Router from "vue-router";
import store from "./store.js";
// import Home from "./views/Home.vue";

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    // {
    //   path: '/',
    //   name: 'home',
    //   component: Home
    // },
    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    // }
    {
      path: "/(#/)?",
      component: resolve => require(["./layout.vue"], resolve),
      meta: {
        auth: true
      },
      children: [
        {
          path: "shop",
          redirect: '/sales',
        },
        {
          path: "store",
          redirect: 'store/remains',
        },
        {
          path: "finance",
          redirect: 'finance/cashes',
        },
        {
          path: "compendiums",
          redirect: 'compendiums/nomenclatures',
        },
        {
          path: "analytics",
          redirect: 'analytics/reports',
        },
        {
          path: "settings",
          redirect: 'settings/general/company',
        },
        {
          name: "home",
          path: "",
          component: resolve =>
            require(["./components/livesklad/dashboard.vue"], resolve),
          meta: {
            title: "Компания",
            auth: true,
            nameCompany: true,
            notSubmenu: true
          }
        },
        {
          path: "settings",
          component: resolve =>
            require([
              "./components/livesklad/settings/settings.vue"
            ], resolve),
          meta: {
            title: "Общие",
            auth: true
          },
          children: [
            {
              name: "general",
              path: "general/:tab?/:fieldId?",
              component: resolve =>
                require([
                  "./components/livesklad/settings/general.vue"
                ], resolve),
              meta: {
                title: "Общие",
                auth: true,
                withSubmenu: true
              }
            },
            {
              name: "locations",
              path: "shops/:shopId?",
              component: resolve =>
                require([
                  "./components/livesklad/settings/shops.vue"
                ], resolve),
              meta: {
                title: "Локации",
                auth: true,
                withSubmenu: true
              }
            },
            {
              name: "users",
              path: "customers/:tab?/:itemId?",
              component: resolve =>
                require([
                  "./components/livesklad/settings/customers.vue"
                ], resolve),
              meta: {
                title: "Сотрудники и роли",
                auth: true,
                withSubmenu: true
              }
            },
            {
              name: "prices",
              path: "prices",
              component: resolve =>
                require([
                  "./components/livesklad/settings/prices.vue"
                ], resolve),
              meta: {
                title: "Цены",
                auth: true,
                withSubmenu: true
              }
            },
            {
              name: "statuses",
              // + в параметрах указать какой столбец столбец нажат у статуса
              path: "statuses/:tab?/:statusId?",
              component: resolve =>
                require([
                  "./components/livesklad/settings/statuses.vue"
                ], resolve),
              meta: {
                title: "Статусы",
                auth: true,
                withSubmenu: true
              }
            },
            {
              name: "template-editor",
              path: "template-editor/:documentId?",
              component: resolve =>
                require([
                  "./components/livesklad/settings/template_editor.vue"
                ], resolve),
              meta: {
                title: "Печатные формы",
                auth: true,
                withSubmenu: true
              }
            },
            {
              name: "sms-settings",
              path: "sms",
              component: resolve =>
                require([
                  "./components/livesklad/settings/sms_template.vue"
                ], resolve),
              meta: {
                title: "Настройки SMS",
                auth: true,
                withSubmenu: true
              }
            },
            {
              name: "notifications",
              path: "notifications/:tab?/:notificationId?",
              component: resolve =>
                require([
                  "./components/livesklad/settings/notifications.vue"
                ], resolve),
              meta: {
                title: "Уведомления",
                auth: true,
                withSubmenu: true
              }
            },
            {
              name: "order-status-widget",
              path: "widget",
              component: resolve =>
                require([
                  "./components/livesklad/settings/widget.vue"
                ], resolve),
              meta: {
                title: "Виджет статуса заказов",
                auth: true,
                withSubmenu: true
              }
            },
            {
              name: "cash-registers",
              path: "online-cashbox/:tab?",
              component: resolve =>
                require([
                  "./components/livesklad/settings/online-cashbox.vue"
                ], resolve),
              meta: {
                title: "Онлайн-кассы",
                auth: true,
                withSubmenu: true
              }
            },
            {
              name: "form-editor",
              path: "form-editor/:tab?/:typeId?",
              component: resolve =>
                require([
                  "./components/livesklad/settings/form-editor.vue"
                ], resolve),
              meta: {
                title: "Редактор форм",
                auth: true,
                withSubmenu: true
              }
            },
            {
              name: "telephony",
              path: "telephony/:tab?",
              component: resolve =>
                require([
                  "./components/livesklad/settings/telephony.vue"
                ], resolve),
              meta: {
                title: "Телефония",
                auth: true,
                withSubmenu: true
              }
            },
            {
              name: "api",
              path: "api/:itemId?",
              component: resolve =>
                require([
                  "./components/livesklad/settings/settings-api.vue"
                ], resolve),
              meta: {
                title: "Доступ к API",
                auth: true,
                withSubmenu: true
              }
            },
            {
              name: "amocrm",
              path: "amo",
              component: resolve =>
                require([
                  "./components/livesklad/settings/amocrm.vue"
                ], resolve),
              meta: {
                title: "AmoCRM",
                auth: true,
                withSubmenu: true
              }
            },
            {
              name: "safety",
              path: "safety/:tab?",
              component: resolve =>
                require([
                  "./components/livesklad/settings/Safety.vue"
                ], resolve),
              meta: {
                title: "Безопасность",
                auth: true,
                withSubmenu: true
              }
            },
            {
              name: "webhook",
              path: "webhooks/:itemId?",
              component: resolve =>
                require([
                  "./components/livesklad/settings/Webhooks.vue"
                ], resolve),
              meta: {
                title: "Webhook",
                auth: true,
                withSubmenu: true,
              }
            },
            {
              name: "pricing",
              path: "license",
              component: resolve =>
                require(["./components/livesklad/pricing.vue"], resolve),
              meta: {
                title: "Подписка",
                auth: true,
                withSubmenu: true
              }
            },
          ]
        },
        {
          name: "sales",
          // id модификации или /payment или /sales/:saleId
          path: "sales/:history?/:saleId?",
          component: resolve =>
            require(["./components/livesklad/shop/shop.vue"], resolve),
            // require(["./components/sales.vue"], resolve),
          meta: {
            title: "Продажи",
            auth: true,
            notSubmenu: true,
            withShop: true
          }
        },
        {
          name: "orders",
          // /form, /materials, /materials/add
          path: "orders/:orderId?/:tab?/:add?",
          component: resolve =>
            require(["./components/livesklad/orders/orders.vue"], resolve),
          meta: {
            title: "Заказы",
            auth: true,
            notPaddingBottom: true,
            notSubmenu: true,
            withShop: true
          }
        },
        {
          name: "remains",
          // remains/ID?storeId=123
          path: "store/remains/:modifyId?",
          // path: "remains/:store?",
          component: resolve =>
            require(["./components/Modifies.vue"], resolve),
          meta: {
            title: "Остатки",
            auth: true,
            withShop: true
          }
        },
        {
          name: "store-settings",
          path: "store/settings/:storeId?",
          component: resolve =>
            require([
              "./components/livesklad/stores/warehouse_settings.vue"
            ], resolve),
          meta: {
            title: "Настройки складов",
            auth: true,
            withShop: true
          }
        },
        {
          // posting?storeId=123
          // posting/ID
          name: "documents",
          path: "store/(posting|moves|cancellations|sales|returns/sales|returns/purchase|inventory)?/:id?",
          // path: "(posting|moves|cancellations|sales|returns/sales|returns/purchase|sales|inventory)?/:id?",
          component: resolve =>
            require([
              "./components/documents.vue"
            ], resolve),
          meta: {
            title: {
              "posting": "Поступления",
              "moves": "Перемещения",
              "cancellations": "Списания",
              "returns/sales": "Возвраты от клиентов",
              "returns/purchase": "Возвраты поставщикам",
              // "sales": "Продажи",
              "inventory": "Инвентаризация"
            },
            // bgc: "#f2f2f2",
            auth: true,
            withShop: true
          }
        },
        {
          name: "sellers",
          path: "sellers/:sellerId?",
          component: resolve =>
            require([
              "./components/livesklad/compendiums/sellers.vue"
            ], resolve),
          meta: {
            title: "Прайсы поставщиков",
            auth: true,
            notSubmenu: true
          }
        },
        {
          name: "cart",
          path: "cart",
          component: resolve =>
            require(["./components/livesklad/cart.vue"], resolve),
          meta: {
            title: "Корзина",
            auth: true,
            notSubmenu: true,
            withShop: true
          }
        },
        {
          name: "modifies",
          path: "compendiums/nomenclatures/:modifyId?/:tab?",
          component: resolve =>
            require([
              "./components/Modifies.vue"
            ], resolve),
          meta: {
            title: "Товары",
            auth: true,
            withShop: true
          }
        },
        {
          name: "works",
          path: "compendiums/works/:modifyId?/:tab?",
          component: resolve =>
            require([
              "./components/Modifies.vue"
            ], resolve),
          meta: {
            title: "Работы",
            auth: true,
            withShop: true
          }
        },
        {
          name: "counteragents",
          path: "compendiums/counteragents/:counteragentId?/:tab?",
          component: resolve =>
            require([
              "./components/livesklad/compendiums/counteragents.vue"
            ], resolve),
          meta: {
            title: "Контрагенты",
            notPaddingBottom: true,
            auth: true
          }
        },
        {
          name: "type-devices",
          path: "compendiums/type-devices/:type?/:brand?",
          component: resolve =>
            require([
              "./components/livesklad/compendiums/type_devices.vue"
            ], resolve),
          meta: {
            title: "Типы устройств, марки и модели",
            auth: true
          }
        },
        {
          name: "complete-sets",
          path: "compendiums/complete-sets",
          component: resolve =>
            require([
              "./components/livesklad/compendiums/compendium.vue"
            ], resolve),
          meta: {
            title: "Комплектации",
            auth: true
          }
        },
        {
          name: "problems",
          path: "compendiums/problems",
          component: resolve =>
            require([
              "./components/livesklad/compendiums/compendium.vue"
            ], resolve),
          meta: {
            title: "Неисправности",
            auth: true
          }
        },
        {
          name: "cash-items",
          path: "compendiums/cash-items/:tab?/:itemId?",
          component: resolve =>
            require([
              "./components/livesklad/compendiums/cash_items.vue"
            ], resolve),
          meta: {
            title: "Статьи движения денежных средств",
            auth: true
          }
        },
        {
          name: "units",
          path: "compendiums/units",
          component: resolve =>
            require([
              "./components/livesklad/compendiums/units.vue"
            ], resolve),
          meta: {
            title: "Единицы измерения",
            auth: true
          }
        },
        {
          name: "reports",
          path: "analytics/reports/:report?",
          component: resolve =>
            require([
              "./components/livesklad/reports.vue"
            ], resolve),
          meta: {
            title: "Отчеты",
            auth: true,
            withShop: true
          }
        },
        {
          name: "calls",
          path: "analytics/calls",
          component: resolve =>
            require(["./components/livesklad/statistic.vue"], resolve),
          meta: {
            title: "Звонки",
            auth: true
          }
        },
        {
          name: "how-know",
          path: "analytics/how-knows/:type?",
          component: resolve =>
            require([
              "./components/HowKnow.vue"
            ], resolve),
          meta: {
            title: "Реклама",
            auth: true
          }
        },
        {
          name: "wage",
          path: "finance/wage/:tab?/:customerId?/:windowTab?",
          component: resolve =>
            require(["./components/livesklad/finance/wage.vue"], resolve),
          meta: {
            title: "Зарплата",
            auth: true
          }
        },
        {
          name: "cashes",
          path: "finance/cashes/:cashId?",
          component: resolve =>
            require(["./components/livesklad/finance/cashes.vue"], resolve),
          meta: {
            title: "Кассы",
            auth: true,
            withShop: true
          }
        },
        {
          name: "transactions",
          path: "finance/transactions/:transactionId?",
          component: resolve =>
            require([
              "./components/livesklad/finance/transactions.vue"
            ], resolve),
          meta: {
            title: "Транзакции",
            notPaddingBottom: true,
            auth: true,
            withShop: true
          }
        },
        {
          name: "invoices",
          path: "finance/invoices/:invoiceId?/:tab?/:addTab?",
          component: resolve =>
            require([
              "./components/livesklad/invoices/invoices.vue"
            ], resolve),
          meta: {
            title: "Счета",
            auth: true,
            withShop: true
          }
        },
        {
          name: "cash-flow",
          path: "finance/cash-flow",
          component: resolve =>
            require([
              "./components/livesklad/finance/cash_flow.vue"
            ], resolve),
          meta: {
            title: "Денежный поток",
            auth: true
          }
        },
      ]
    },
    {
      path: "/pay/:type",
      component: resolve =>
        require(["./components/livesklad/after-payment.vue"], resolve),
      meta: {
        title: "Оплата совершена",
      }
    },
    {
      path: "/sms/:type",
      component: resolve =>
        require(["./components/livesklad/after-payment.vue"], resolve),
      meta: {
        title: "Оплата совершена",
      }
    },
    {
      path: "/kkm/:type",
      component: resolve =>
        require(["./components/livesklad/after-payment.vue"], resolve),
      meta: {
        title: "Оплата совершена",
      }
    },
    {
      name: "500",
      path: "/500",
      component: resolve => require(["./components/500.vue"], resolve),
      meta: {
        title: "500"
      }
    },
    {
      name: "login",
      path: "/login",
      component: resolve =>
        require(["./components/livesklad/login.vue"], resolve),
      meta: {
        title: "Вход"
      }
    },
    {
      name: "register",
      path: "/register/:success?",
      component: resolve =>
        require(["./components/livesklad/register.vue"], resolve),
      meta: {
        title: "Регистрация"
      }
    },
    {
      name: "reset-password",
      path: "/reset_password",
      component: resolve =>
        require(["./components/livesklad/reset_pass.vue"], resolve),
      meta: {
        title: "Восстановление пароля"
      }
    },
    {
      name: "change-password",
      path: "/changePassword",
      component: resolve =>
        require(["./components/livesklad/change_pass.vue"], resolve),
      meta: {
        title: "Изменение пароля"
      }
    },
    {
      path: "*",
      component: resolve => require(["./components/404.vue"], resolve),
      meta: {
        title: "404"
      }
    }
  ],
  linkActiveClass: "active"
});

router.beforeEach((to, from, next) => {
  if (
    store.state.isNavigationBlocked &&
    (to.name !== from.name || to.params?.[1] !== from.params?.[1])
  ) {
    store.commit('prepareNavigation', to.fullPath)
  } else if (
    to.meta.auth &&
    !store.state.user.accessToken &&
    !store.state.user.accessToken.length &&
    store.state.user.accessToken.length === 0
  ) {
    next({
      path: "/login",
      query: {
        redirect: to.fullPath
      }
    });
  } else {
    next();
  }
});

router.afterEach(to => {
  if (typeof to?.meta?.title === 'object') {
    const index = Object.keys(to.meta.title).findIndex(key => to.fullPath.includes(key))
    var title = ~index
      ? Object.values(to.meta.title)[index]
      : Object.values(to.meta.title)[0]
  } else {
    title = to.meta.title
  }
  document.title = title;
});

export default router;
