<template>
  <div
    ref="combobox"
    class="select"
    :class="compClasses"
  >
    <div v-if="disabled" class="select__title--disabled">
      <div v-if="isMultiple && Array.isArray(selected)" class="st__tags">
        <template v-if="selected.length">
          <div class="st__tag" v-for="(item, i) in selected" :key="i">
            {{ item | tolabel(itemOrig, itemDescription) }}
          </div>
        </template>
      </div>
      <template v-else>
        <div v-if="selected" class="disabled-label">
          {{ selected | tolabel(itemOrig, itemDescription) }}
        </div>
        <div v-else-if="currentPlaceholder" class="disabled-label">{{currentPlaceholder}}</div>
        <!-- КНОПКА СПРАВА ДЛЯ СОЗДАНИЯ, ИЗМЕНЕНИЯ ИЛИ УДАЛЕНИЯ ПОЗИЦИИ (ПО СИТУАЦИИ) -->
        <template v-if="selected && typeof selected === 'object'">
          <button
            v-if="withEditBtn"
            class="st__btn"
            tabindex="-1"
            @mousedown.prevent.stop.left="clickBtn"
          >
            <span class="st__btn__icon ti-pencil"></span>
          </button>
        </template>
      </template>
    </div>
    <div v-else class="select__title" :class="compTitleClasses" @mousedown.stop="onClick">
      <!-- ЕСЛИ МНОЖЕСТВЕННЫЙ КОМБОБОКС И ПОСЛЕ ВЫБОРА ЗНАЧЕНИЕ СОХРАНЯТЬ, ПОКАЗАТЬ ТЕГИ -->
      <template v-if="isMultiple">
        <template v-if="Array.isArray(selected) && selected.length">
          <div class="st__tag" v-for="(item, i) in selected" :key="i">
            <span>{{ item | tolabel(itemOrig, itemDescription) }}</span>
            <span
              class="st__tag__delete"
              @mousedown.prevent.stop.left="deselectItem(i)"
              >×</span
            >
          </div>
        </template>
        <span v-if="requiredLeft && !selected" class="st__req" :style="{left: requiredLeft + 'px'}">*</span>
      </template>
      <template v-else>
        <!-- КНОПКА СПРАВА ДЛЯ СОЗДАНИЯ, ИЗМЕНЕНИЯ ИЛИ УДАЛЕНИЯ ПОЗИЦИИ (ПО СИТУАЦИИ) -->
        <template v-if="selected && typeof selected === 'object'">
          <button
            v-if="withEditBtn"
            class="st__btn"
            tabindex="-1"
            @mousedown.prevent.stop.left="clickBtn"
          >
            <span class="st__btn__icon ti-pencil"></span>
          </button>
        </template>
        <template
          v-else-if="
            (searchPlaceholder || showEmptyCreating) &&
              (!selected || (typeof selected === 'string' && !isStrings))
          "
        >
          <button
            v-if="withAddBtn"
            class="st__btn"
            tabindex="-1"
            @mousedown.prevent.stop.left="clickBtn"
          >
            +
          </button>
        </template>
        <span v-if="requiredLeft && !selected" class="st__req" :style="{left: requiredLeft + 'px'}">*</span>
        <!-- В ОДИНОЧНОМ РЕЖИМЕ ЕСТЬ КРЕСТИК ОЧИСТКИ ПОИСКОВОГО ЗАПРОСА (И ТЕКУЩЕГО ВЫБРАННОГО ЗНАЧЕНИЯ) -->
        <span
          v-if="!isHideDelete && withClearBtn && (search || selected)"
          class="st__clear"
          @mousedown.left.prevent.stop="clearSearch"
          >×</span
        >
        <!-- <span class="st__arrow"></span> -->
      </template>
      <input
        v-if="withFixedValue"
        type="text"
        ref="search"
        :value="fixedSelectedPlaceholder"
        :placeholder="currentPlaceholder"
        :readonly="true"
        class="st__input st--fixed"
        :class="toNextOnEnter ? 'tabable' : ''"
        @keydown.up.prevent="typeTopKey"
        @keydown.down.prevent="typeBottomKey"
        @keydown.enter="typeEnterKey"
        @keydown.esc="typeEscKey"
        @focus="focusBtn"
        @blur="blurBtn"
      />
      <template v-else>
        <!-- ПОИСКОВЫЙ ИНПУТ ПОКАЗЫВАЕМ ВСЕГДА -->
        <masked-input
          v-if="isMaskFunction"
          type="text"
          ref="search"
          class="st__input masked-input"
          :class="toNextOnEnter ? 'tabable' : ''"
          :value="searchPlaceholder"
          :placeholder="currentPlaceholder"
          :style="compInputStyle"
          :mask="maskFunction"
          :guide="false"
          placeholderChar="_"
          autocomplete="off"
          @input="inputText($event)"
          @keydown.native.up.prevent="typeTopKey"
          @keydown.native.down.prevent="typeBottomKey"
          @keydown.native.right="typeRightKey"
          @keydown.native.enter.prevent="typeEnterKey"
          @keydown.native.tab="typeTabKey"
          @keydown.native.delete="typeDeleteKey"
          @keydown.native.esc="typeEscKey"
          @keydown.native="typeAnyKey"
          @focus="focus"
          @blur="blur"
        ></masked-input>
        <input
          v-else
          type="text"
          ref="search"
          :value="searchPlaceholder"
          :placeholder="currentPlaceholder"
          class="st__input"
          :class="toNextOnEnter ? 'tabable' : ''"
          :style="compInputStyle"
          autocomplete="off"
          :maxLength="maxLength"
          @input="inputText($event.target.value)"
          @keydown.up.prevent="typeTopKey"
          @keydown.down.prevent="typeBottomKey"
          @keydown.right="typeRightKey"
          @keydown.enter="typeEnterKey"
          @keydown.tab="typeTabKey"
          @keydown.delete="typeDeleteKey"
          @keydown.esc="typeEscKey"
          @keydown="typeAnyKey"
          @focus="focus"
          @blur="blur"
          @paste="inputText($event.target.value)"
        />
        <template v-if="withSuggestions">
          <div class="st__suggestion" :style="compInlineSuggestionStyle">
            <input
              class="st__input st__suggestion--hint"
              type="text"
              :placeholder="hintMessage"
              disabled="disabled"
              aria-hidden="true"
              tabindex="0"
            />
          </div>
        </template>
        <div ref="hidden" class="st__input--hidden" :class="isAutoWidth ? 'st__input--hidden--big' : 'st__input--hidden--normal'" v-html="searchHiddenHtml"></div>
      </template>
      <div class="st__background"></div>
    </div>
    <!-- ////////////////////////////////////////////////////////////////////// -->
    <!-- ////////////////////// ДАЛЬШЕ ВЫПАДАЮЩИЙ СПИСОК ////////////////////// -->
    <!-- ////////////////////////////////////////////////////////////////////// -->
    <transition name="disappear">
      <div
        v-if="open"
        class="select__list"
        :class="compListClasses"
        :style="compListStyle"
      >
        <div v-if="withDropdownSearch" style="position: relative;">
          <span
            class="sl__search ti-search"
            @mousedown.left.prevent.stop="inputText('')"
            ></span
          >
          <input
            type="text"
            ref="dsearch"
            :value="searchPlaceholder"
            :placeholder="currentPlaceholder"
            class="sl__input"
            autocomplete="off"
            :maxLength="maxLength"
            @input="inputText($event.target.value)"
            @keydown.up.prevent="typeTopKey"
            @keydown.down.prevent="typeBottomKey"
            @keydown.right="typeRightKey"
            @keydown.enter="typeEnterKey"
            @keydown.tab="typeTabKey"
            @keydown.delete="typeDeleteKey"
            @keydown.esc="typeEscKey"
            @keydown="typeAnyKey"
            @focus="focusInternal"
            @blur="blurInternal"
          />
        </div>
        <v-scroll-2
          :topPosition="topPosition"
          @mousedown.prevent.stop
          @scrollTop="topPosition = $event"
          @scroll-to-bottom="nextPart"
        >
          <ul ref="dropdown" :style="compDropdownStyle">
            <template v-if="withSuggestions">
              <li>
                <p
                  class="group-description"
                  :class="displayedSuggestions.length ? '' : 'group-description--empty'"
                  @mouseenter="toFirstPointer"
                >
                  Подсказки
                </p>
                <div v-if="displayedSuggestions.length">
                  <ul class="select__suggestions">
                    <li
                      v-for="(suggestion, x) in displayedSuggestions"
                      :key="x"
                      class="select__sug"
                      :class="{ highlight: activePointer === `sug.${x}` }"
                      @mouseenter="setPointer(`sug.${x}`)"
                      @mousedown.left.prevent.stop="
                        chooseSuggestion(suggestion)
                      "
                    >
                      <slot
                        name="sug"
                        :description="suggestion.htmlText"
                        :suggestion="suggestion"
                      >
                        <span v-html="suggestion.htmlText"></span>
                      </slot>
                    </li>
                  </ul>
                </div>
              </li>
              <!-- <hr v-if="suggestions.length" class="group--hr" /> -->
            </template>
            <template v-if="!hideOptions">
              <li v-if="!displayed.length">
                <p v-if="optionsName" class="group-description group-description--empty">
                  {{ optionsName }}
                </p>
                <ul>
                  <li
                    :class="{ highlight: activePointer === 'noResult' }"
                    class="no-result-option select__item"
                    @mousedown.prevent.stop
                  >
                    <slot name="noResult" :text="search">
                      {{ compNoResultText }}
                    </slot>
                  </li>
                </ul>
              </li>
              <!-- for groups -->
              <template v-else-if="isGroups">
                <template v-for="(group, x) in displayed">
                  <li :key="x">
                    <slot
                      name="group"
                      :group="group"
                      :number="x"
                      :items="displayed"
                      :activePointer="activePointer"
                      :setPointer="setPointer"
                      :selectItem="selectItem"
                    >
                      <slot name="groupLabel" :group="group">
                        <p class="group-description" @mousedown.left.prevent.stop="changeOpen(group, x)">
                          <span
                            class="group-description__icon"
                            :class="group._isHidden ? '' : 'group-description__icon--open'"
                          ></span>
                          {{ group | togrouplabel(groupDescription) }}
                        </p>
                      </slot>
                      <ul v-if="group[groupValues].length">
                        <li
                          v-for="(item, y) in group[groupValues]"
                          :key="y"
                          class="select__item"
                          :class="[
                            { highlight: activePointer === `${x}.${y}` },
                            item._isSelected ? 'select__item--selected' : '',
                          ]"
                          @mouseenter="setPointer(`${x}.${y}`)"
                          @mousedown.left.prevent.stop="selectItem(item)"
                        >
                          <slot :item="item" :description="item[itemDescription]" :group="group">
                            <span v-html="item[itemDescription]"></span>
                          </slot>
                        </li>
                      </ul>
                    </slot>
                  </li>
                  <li
                    v-if="group.collapsedLength"
                    :key="x + '-more'"
                    class="select__item"
                    :class="{ highlight: activePointer === `${x}.more` }"
                    @mouseenter="setPointer(`${x}.more`)"
                    @mousedown.prevent.stop="showMore(x)"
                  >
                    <div class="suggestions--more">
                      Показать ещё позиции ({{ group.collapsedLength }})
                    </div>
                  </li>
                  <!-- <hr
                    v-if="x !== displayed.length - 1"
                    :key="x + '-hr'"
                    class="group--hr"
                  /> -->
                </template>
              </template>
              <!-- for objects or strings -->
              <template v-else>
                <li>
                  <p v-if="optionsName" class="group-description">
                    {{ optionsName }}
                  </p>
                  <ul>
                    <li
                      v-for="(item, x) in displayed"
                      :key="x"
                      class="select__item"
                      :class="[
                        { highlight: activePointer === x },
                        item._isSelected ? 'select__item--selected' : '',
                      ]"
                      @mouseenter="setPointer(x)"
                      @mousedown.left.prevent.stop="selectItem(item)"
                    >
                      <slot :item="item" :description="item[itemDescription]">
                        <span v-html="item[itemDescription]"></span>
                      </slot>
                    </li>
                  </ul>
                </li>
              </template>
            </template>
          </ul>
        </v-scroll-2>
        <template v-if="isShowLastOption">
          <div
            :class="{ highlight: activePointer === 'last' }"
            class="select__item select__item--last"
            @mouseenter="setPointer('last')"
            @mousedown.prevent.stop="onLastOptionClick"
          >
            <slot name="lastOption" :text="search">
              <div class="options last-option">
                {{ compLastOptionText }}
              </div>
            </slot>
          </div>
        </template>
      </div>
    </transition>
    <slot name="modal"></slot>
  </div>
</template>

<script>
import _ from "lodash";
import { checkSymbol, toSerialCode } from '../../../../services/barcode-functions'
export default {
  props: {
    // ОСНОВНЫЕ
    value: {},
    placeholder: {
      type: String,
      default: "",
    },
    isMultiple: Boolean,
    disabled: Boolean,
    activeDisabled: Boolean,
    // ЗНАЧИМЫЕ ПОЛЯ В ОБЪЕКТАХ
    itemDescription: {
      type: String,
      default: "name",
    },
    itemValue: {
      type: String,
      default: "id",
    },
    itemOrig: {
      type: String,
      default: "origName",
    },
    // ГРУППИРОВКА
    isGroups: Boolean, // если не активно, значения ниже не используются
    isAutoGrouping: Boolean, // если активно, то данные приходят из апи без группировки - их надо сгруппировать автоматически
    groupDescription: {
      type: String,
      default: "name",
    },
    groupValues: {
      type: String,
      default: "elements",
    },
    groupId: {
      type: String,
      default: "id",
    },
    // РАБОТА СО СПИСКОМ
    items: Array,
    link: String,
    internalSearch: Boolean,
    timeDelay: {
      type: Number,
      default: 250,
    },
    optionsName: String,
    isStrings: Boolean,
    isPagination: {
      type: Boolean,
      default: true,
    },
    filterKey: {
      type: String,
      default: "filter",
    },
    searchParams: Object,
    minCount: {
      type: Number,
      default: 0,
    },
    maxGroupCount: {
      type: Number,
      default: 1000,
    },
    isRight: Boolean,
    // ПОВЕДЕНИЕ
    resetAfter: Boolean,
    // (только в единственном) есть ли вообще поисковая строка, или только выбор из предложенных
    searchable: {
      type: Boolean,
      default: true,
    },
    // (только если searchable) можно ли оставить строку поискового запроса как текущее значение
    isFixedValue: {
      type: Boolean,
      default: true,
    },
    // (только в единственном) есть ли крестик чтобы очистить текущее значение
    withClearBtn: {
      type: Boolean,
      default: true,
    },
    isHideDelete: Boolean,
    isShowValidation: Boolean,
    isValidationAutofocus: {
      type: Boolean,
      default: true,
    },
    autofocus: Boolean,
    isAutofocusHidden: Boolean,
    getItemsCallback: Function,
    getItemsFunc: Function,
    maskFunction: Function,
    hideOptions: Boolean,
    // Кнопка снизу
    showLastOption: Boolean,
    showEmptyLastOption: Boolean,
    saveLink: String,
    saveMethod: {
      type: String,
      default: "PUT",
    },
    saveField: {
      type: String,
      default: "name",
    },
    // НАЧАЛЬНОЕ ЗНАЧЕНИЕ
    firstIsActive: Boolean,
    withDefault: Boolean, // важнее предыдущего
    // ПОДСКАЗКИ
    suggestionsLink: String,
    // КНОПКА СПРАВА
    showEmptyCreating: Boolean,
    withEditBtn: Boolean,
    withAddBtn: Boolean,
    // Параметры селекта
    maxHeight: {
      type: Number,
      default: 200,
    },
    dropdownWidth: {
      type: [String, Number],
      default: "auto",
    },
    position: {
      type: String,
      default: "bottom",
    },
    // Строковые переменные
    noResultText: {
      default: "Нет результатов по запросу",
    },
    lastOptionText: {
      type: [Array, String],
      default: "Создать",
    },
    toNextOnEnter: Boolean,
    requiredLeft: {
      type: Number,
      default: 0
    },
    initOpen: Boolean,
    maxLength: Number,
    maxCount: Number,
    isPreventEnter: Boolean,
    withAutoCode: Boolean,
    isAutoWidth: Boolean,
    isReload: Boolean
  },
  data() {
    let search = !this.isFixedValue && this.value && typeof this.value === "string"
      ? this.value
      : "";
    return {
      searchHiddenHtml: "",
      allItems: [],
      suggestions: [],
      displayed: [],
      displayedSuggestions: [],
      selected: this.value || null,
      search: search,
      isChangedText: !this.searchable,
      // Для работы с подсказками и управления раскрытием групп
      searchTextWidth: 0,
      // ПАРАМЕТРЫ ПОИСКА
      total: 0,
      pageSize: 15,
      pageNumber: 1,
      // ФУНКЦИИ ПОИСКА
      isInternalSearch: this.internalSearch || !this.link,
      isLoading: false,
      getItemsWithDelay: _.debounce(function(callback) {
        return this.getAllData(callback);
      }, this.timeDelay),
      isSearchChanged: false,
      isBegin: true,
      withSuggestions: !!this.suggestionsLink,
      isSearchCleared: false,
      // ЕСТЬ МАСКА У ИНПУТА
      isMaskFunction: this.maskFunction,
      open: false,
      isAnimate: false,
      activePointer: null,
      // ШТРИХКОДЫ
      code: "",
      timestamp: null,
      scannerSensitivity: 100,
      // ПЕРЕСЧЕТ ВЫСОТЫ СЕЛЕКТА
      isRerendering: false,
      currentPosition: this.position,
      height: 0,
      // Для того чтобы управлять областью прокрутки при перемещении стрелочными клавишами
      topPosition: 0,
      isBlur: false,
      isExactMatch: false,
      isFocusHidden: false
    };
  },
  created() {
    if (this.items) this.allItems = this.prepareItems(this.items);
    if (!this.isFixedValue && this.selected && typeof this.selected === "object") {
      this.changeSearchText(this.getOptionDescription(this.selected) || "");
    }
    if (!this.selected && (this.withDefault || this.firstIsActive)) {
      if (!this.allItems.length && this.link) {
        this.reloadNow(null, true);
        return;
      } else {
        // здесь выбор дефолтного значения
      }
    }
    if (this.initOpen) {
      if (this.link && !this.items.length) this.reloadNow();
      else if (!this.hideOptions || this.suggestions.length) {
        this.setDisplayedOptions();
        this.openIfTextChanged();
      }
    }
  },
  mounted() {
    if (!this.isFixedValue && this.selected && typeof this.selected === "string") {
      this.changeSearchText(this.search);
    }
    if (this.autofocus && !this.disabled) {
      let elem = this.maskFunction ? this.$refs.search.$el : this.$refs.search;
      if (this.isAutofocusHidden) {
        this.isFocusHidden = true;
        setTimeout(() => {
          this.isFocusHidden = false;
        }, 50)
        if (!this.isMultiple && this.isFixedValue && this.link) this.isSearchCleared = true;
      }
      window.$(elem).focus();
    }
  },
  computed: {
    isShowLastOption() {
      return (
        this.showLastOption &&
        (this.showEmptyLastOption || this.search && !this.isExactMatch) &&
        (this.isMultiple || !this.selected || typeof this.selected === "string" || this.withDropdownSearch)
      );
    },
    /////////////////////////////////////////////////////////////////////////////////////////
    // КЛАССЫ СЕЛЕКТА
    /////////////////////////////////////////////////////////////////////////////////////////
    compClasses() {
      return [
        this.isShowValidation ? "select--validation" : "",
        this.disabled ? "select--disabled" : "",
        this.isMultiple ? "select--multiple" : "",
        this.withAddBtn && (this.searchPlaceholder || this.showEmptyCreating) &&
          (!this.selected || (typeof this.selected === 'string' && !this.isStrings)) ||  
          this.withEditBtn
          ? ''
          : 'select--with-arrow',
        !this.isHideDelete && this.withClearBtn && (this.search || this.selected) && !this.isMultiple
          ? 'select--with-clear-btn'
          : '',
        this.open ? 'select--opened' : '',
        !this.selected && !this.search ? 'select--empty' : ''
      ];
    },
    compTitleClasses() {
      return this.open || this.isAnimate ? 'select__title--opened' : ''
    },
    compListClasses() {
      return [this.currentPosition === 'top' ? 'select__list--top' : '', this.isRight ? 'select__list--right' : 'select__list--left', `mh-${this.maxHeight}`]
    },
    compListStyle() {
      return typeof this.dropdownWidth === 'number'
        ? { width : this.dropdownWidth + 2 + 'px' }
        : undefined
    },
    compDropdownStyle() {
      return {
        maxHeight: `${this.maxHeight}px`,
        width: typeof dropdownWidth === 'string' ? this.dropdownWidth : `${this.dropdownWidth}px`,
        paddingBottom: this.optionsName ? '5px' : ''
      };
    },
    compInputStyle() {
      return { width: this.searchTextWidth + 40 + (this.isAutoWidth ? 3 : 0) + "px" };
    },
    compInlineSuggestionStyle() {
      return this.withSuggestions
        ? { clip: `rect(auto, auto, auto, ${this.searchTextWidth + 9}px)` }
        : {};
    },
    /////////////////////////////////////////////////////////////////////////////////////////
    // Текстовые переменные
    /////////////////////////////////////////////////////////////////////////////////////////
    compNoResultText() {
      return Array.isArray(this.noResultText)
        ? `${this.noResultText[0] || ""} "${this.search}" ${this
            .noResultText[1] || ""}`
        : this.noResultText;
    },
    compLastOptionText() {
      if (!this.isShowLastOption) return;
      else if (Array.isArray(this.lastOptionText))
        return `${this.lastOptionText[0] || ""} ${this.search ? `"${this.search}"` : ''} ${this
          .lastOptionText[1] || ""}`;
      else return `${this.lastOptionText || ""} ${this.search ? `"${this.search}"` : ''}`;
    },
    /////////////////////////////////////////////////////////////////////////////////////////
    // ФУНКЦИЯ, ФОРМИРУЮЩАЯ PLACEHOLDER ДЛЯ ВВОДА
    /////////////////////////////////////////////////////////////////////////////////////////
    currentPlaceholder() {
      return !this.isMultiple || !this.selected?.length ? this.placeholder : "";
    },
    fixedSelectedPlaceholder() {
      return this.selected
        ? this.isStrings || typeof this.selected === "string"
          ? this.selected
          : this.getOptionDescription(this.selected)
        : "";
    },
    /////////////////////////////////////////////////////////////////////////////////////////
    // ТЕКСТ В ТЕКСТОВОМ ПОЛЕ (МОЖЕТ ИЗМЕНЯТЬСЯ ПРИ НАВЕДЕНИИ НА ПОДСКАЗКУ)
    /////////////////////////////////////////////////////////////////////////////////////////
    searchPlaceholder() {
      return this.open &&
        typeof this.activePointer === "string" &&
        ~this.activePointer.indexOf("sug") &&
        (!this.selected || typeof this.selected === "string")
        ? this.getOptionByPointer(this.activePointer) &&
            this.getOptionByPointer(this.activePointer).value
        : // : !this.isMultiple && this.selected
          // ? this.getOptionDescription(this.selected)
          this.search;
    },
    /////////////////////////////////////////////////////////////////////////////////////////
    // ТЕКСТ ПОДСКАЗКИ НА ЗАДНЕМ ФОНЕ
    /////////////////////////////////////////////////////////////////////////////////////////
    hintMessage() {
      return this.open &&
        this.withSuggestions &&
        this.activePointer === null &&
        (!this.selected || typeof this.selected === "string") &&
        this.displayedSuggestions?.[0]?.value?.indexOf(this.search) === 0
        ? this.displayedSuggestions[0].value
        : "";
    },
    withFixedValue() {
      return (
        !this.isMultiple &&
        ((this.isFixedValue && this.selected) || !this.searchable)
      );
    },
    withDropdownSearch() {
      return (
        !this.isMultiple &&
        this.isFixedValue &&
        this.selected &&
        this.searchable
      );
    },
  },
  watch: {
    isReload() {
      if (this.isReload) {
        this.isSearchCleared = true
        this.$emit("update:isReload", false)
      }
    },
    /////////////////////////////////////////////////////////////////////////////////////////
    // В СЛУЧАЕ НЕ ПРОХОЖДЕНИЯ ВАЛИДАЦИИ, ПЕРЕДАТЬ ФОКУС НА СЕЛЕКТ
    /////////////////////////////////////////////////////////////////////////////////////////
    isShowValidation(value) {
      if (value && this.$props.isValidationAutofocus) {
        let elem = this.maskFunction ? this.$refs.search.$el : this.$refs.search;
        this.isFocusHidden = true;
        setTimeout(() => {
          this.isFocusHidden = false;
        }, 50)
        if (!this.isMultiple && this.isFixedValue && this.link) this.isSearchCleared = true;
        window.$(elem).focus();
      }
    },
    value() {
      if (
        (this.value && this.value !== this.selected) ||
        (!this.value && this.selected)
      ) {
        this.selected = this.value;
        if (!this.isMultiple && !this.isFixedValue) {
          let newSearch = typeof this.selected === "string"
            ? this.selected
            : this.getOptionDescription(this.selected) || "";
          if (newSearch !== this.search) {
            this.changeSearchText(newSearch || "");
          }
          this.isSearchChanged = true;
        }
        if (!this.link && !this.isAutoGrouping) this.allItems = this.prepareItems(this.items);
      }
    },
    selected() {
      if (
        (this.value && this.value !== this.selected) ||
        (!this.value && this.selected)
      ) {
        this.$emit("input", this.selected)
      }
    },
    link(newLink) {
      this.isInternalSearch = this.internalSearch || !newLink;
      if (newLink) {
        if (!this.isStrings) this.selected = null;
        this.allItems = [];
        this.$emit("update:items", this.allItems);
        if (!this.isBegin) this.isBegin = true;
        if (this.withDefault || this.firstIsActive || newLink.includes("brand-devices") && newLink.includes("model-devices")) this.reloadNow(null, true)
      } else {
        this.allItems = [];
        this.$emit("update:items", this.allItems);
        if (this.open) this.setDisplayedOptions();
      }
    },
    searchParams(newValue) {
      if (newValue && !this.open && this.link) {
        this.isSearchCleared = true;
      }
    },
    items(newValue) {
      if (newValue) this.allItems = this.prepareItems(this.items);
    },
    withDefault() {
      if (this.withDefault && !this.selected && this.isBegin) {
        this.reloadNow(null, true)
      }
    },
    autofocus(newValue) {
      if (newValue && !this.disabled) {
        let elem = this.maskFunction ? this.$refs.search.$el : this.$refs.search;
        if (this.isAutofocusHidden) {
          this.isFocusHidden = true;
          setTimeout(() => {
            this.isFocusHidden = false;
          }, 50)
          if (!this.isMultiple && this.isFixedValue && this.link) this.isSearchCleared = true;
        }
        window.$(elem).focus();
      }
    },
    suggestionsLink() {
      this.withSuggestions = !!this.suggestionsLink
    }
  },
  filters: {
    tolabel(item, field, defaultField) {
      return item?.[field] ?? item?.[defaultField] ?? item ?? "";
    },
    togrouplabel(item, field) {
      return item?.[field] ?? item ?? "";
    },
  },
  methods: {
    openIfTextChanged() {
      if (
        this.isChangedText ||
        this.isFixedValue && this.selected ||
        this.displayed.length ||
        this.displayedSuggestions.length
      ) this.toggleDropdown();
    },
    // ПРОВЕРЯЕТ, НАХОДИТСЯ ЛИ ФОКУС НА ЭТОМ ЭЛЕМЕНТЕ
    checkFocused() {
      let elem = this.$refs.search ? this.maskFunction ? this.$refs.search.$el : this.$refs.search : false;
      return elem && (document.activeElement === elem ||
        document.activeElement === this.$refs.dsearch);
    },
    reloadNow(callback, notOpen) {
      if (this.isSearchCleared) this.isSearchCleared = false;
      this.getAllData(() => {
        if (!this.hideOptions || this.suggestions.length) {
          this.setDisplayedOptions();
          if (!this.open && !notOpen && this.checkFocused()) this.openIfTextChanged();
        }
        callback?.();
      }, true)
    },
    onClick() {
      if (this.isSearchCleared) {
        setTimeout(() => {
          if (this.isSearchCleared && this.checkFocused()) {
            this.reloadNow(() => {
              if (this.open && this.withDropdownSearch) {
                setTimeout(() => {
                  window.$(this.$refs.dsearch).select();
                }, 100);
              }
            })
          }
        }, 100);
      } else if (this.checkFocused()) {
        if (
          this.search ||
          this.allItems.length
        ) {
          if (!this.hideOptions || this.suggestions.length) {
            if (!this.open) {
              if (this.allItems.length || this.hideOptions) {
                this.setDisplayedOptions();
              }
              this.openIfTextChanged();
              if (this.withDropdownSearch) {
                setTimeout(() => {
                  window.$(this.$refs.dsearch).select();
                }, 50);
              }
            } else {
              this.toggleDropdown();
            }
          }
        }
      }
    },
    focus() {
      if (this.isFocusHidden) {
        this.isFocusHidden = false;
        if (!this.isInternalSearch || this.withSuggestions) this.isSearchCleared = true;
        this.$emit("focus");
        return;
      }
      setTimeout(() => {
        if (!this.open && !this.isLoading && this.checkFocused()) {
          if (
            (this.isBegin && !this.allItems.length && (this.link || this.withSuggestions)) ||
            this.isSearchCleared
          ) {
            this.reloadNow()
          } else if (
            this.search ||
            this.allItems.length
          ) {
            if (!this.hideOptions || this.suggestions.length) {
              if (this.allItems.length || this.hideOptions) {
                this.setDisplayedOptions();
              }
              this.openIfTextChanged();
              if (this.withDropdownSearch && document.activeElement !== this.$refs.dsearch) {
                setTimeout(() => {
                  window.$(this.$refs.dsearch).select();
                }, 50);
              }
            }
          }
        }
      }, 150);
      this.$emit("focus");
    },
    blur() {
      if (this.open) this.toggleDropdown();
      if (this.search && this.isMultiple && this.isStrings) {
        this.addOption();
      } else if (this.search && (this.isFixedValue || this.isMultiple)) {
        this.changeSearchText("");
        this.suggestions = [];
        if (!this.isInternalSearch || this.withSuggestions) this.isSearchCleared = true;
      }
      this.$emit("blur");
    },
    focusBtn() {
      if (this.isFocusHidden) {
        this.isFocusHidden = false;
        if (!this.isInternalSearch || this.withSuggestions) this.isSearchCleared = true;
        this.$emit("focus");
        return;
      } else if (!this.isBlur) {
        setTimeout(() => {
          if (!this.open && !this.isLoading && this.checkFocused()) {
            if (
              (this.isBegin && !this.allItems.length && (this.link || this.withSuggestions)) ||
              this.isSearchCleared
            ) {
              this.reloadNow(() => {
                if (this.searchable)
                  setTimeout(() => {
                    window.$(this.$refs.dsearch).select();
                  }, 50);
              })
            } else if (
              this.search ||
              this.allItems.length
            ) {
              if (this.allItems.length) {
                this.setDisplayedOptions();
              }
              this.toggleDropdown();
              setTimeout(() => {
                window.$(this.$refs.dsearch).select();
              }, 50);
            }
          }
        }, 150);
      }
      this.$emit("focus");
    },
    blurBtn() {
      if (!this.searchable && this.open) this.toggleDropdown();
      this.$emit("blur");
    },
    focusInternal() {
      this.$emit("focus");
    },
    blurInternal() {
      if (this.open) this.toggleDropdown();
      if (this.search) {
        this.changeSearchText("");
        this.suggestions = [];
        if (!this.isInternalSearch || this.withSuggestions) this.isSearchCleared = true;
      }
      this.isBlur = true;
      setTimeout(() => {
        this.isBlur = false;
      }, 20)
      this.$emit("blur");
    },
    /////////////////////////////////////////////////////////////////////////////////////////
    // ОТКРЫТИЕ И ЗАКРЫТИЕ, ПЕРЕСЧЕТ ВЫСОТЫ
    /////////////////////////////////////////////////////////////////////////////////////////
    toggleDropdown() {
      if (this.open) {
        this.open = false;
        this.displayed = [];
        this.displayedSuggestions = [];
        this.activePointer = null;
        this.height = 0;
        this.isAnimate = true;
        this.$emit('close')
        setTimeout(() => {
          this.isAnimate = false;
        }, 100)
      } else {
        const combobox = this.$refs.combobox;
        if (combobox) {
          const clientHeight = document.documentElement.clientHeight;
          const distanceTo = combobox.getBoundingClientRect();
          const distanceToTop = distanceTo.top;
          const distanceToBottom = clientHeight - distanceTo.bottom;
          const isFitBottom = this.maxHeight < distanceToBottom;
          const isFitTop = this.maxHeight < distanceToTop;
          if (!isFitBottom && distanceToTop > distanceToBottom) {
            this.currentPosition = "top";
          } else if (!isFitTop && distanceToBottom > distanceToTop) {
            this.currentPosition = "bottom";
          } else {
            this.currentPosition = this.position;
          }
        }
        this.$emit('open', this.currentPosition)
        this.open = true;
      }
    },
    /////////////////////////////////////////////////////////////////////////////////////////
    // БЛОК РАБОТЫ С ПОИСКОВЫМ ЗАПРОСОМ
    /////////////////////////////////////////////////////////////////////////////////////////

    // ИЗМЕНЕНИЕ ПОИСКА, ОТПРАВКА СОБЫТИЯ И СБРОС АКТИВНОГО
    inputText(search) {
      if (search === this.search) return;
      this.isChangedText = true;
      this.changeSearchText(search);
      if (typeof this.activePointer === "string" && ~this.activePointer.indexOf('sug')) {
        this.activePointer = null;
      }
      if (!this.isMultiple && !this.isFixedValue) this.selected = search;
      if (this.isBegin || !search || (!this.withSuggestions && this.isInternalSearch)) {
        this.getAllData(() => {
          if (this.hideOptions && !this.suggestions.length) {
            if (this.open) this.toggleDropdown();
          } else {
            this.setDisplayedOptions()
            if (!this.open && this.checkFocused()) this.toggleDropdown();
          }
        }) 
      } else {
        this.getItemsWithDelay(() => {
          if (this.hideOptions && !this.suggestions.length) {
            if (this.open) this.toggleDropdown();
          } else {
            this.setDisplayedOptions()
            if (!this.open && this.checkFocused()) this.toggleDropdown();
          }
        })
      }
    },
    // ИЗМЕНЕНИЕ ТЕКСТА ПОИСКОВОГО ЗАПРОСА, И ОТСТУПА ДЛЯ ТЕКСТА ПОДСКАЗКИ
    changeSearchText(value) {
      this.search = value;
      this.$emit("search", value);
      if (this.withSuggestions || this.isMultiple || this.isAutoWidth) {
        const el = this.$refs.hidden;
        if (el) {
          if (this.search || this.search === "") this.searchHiddenHtml = '<span>' + this.search.replaceAll(" ", "&nbsp;") + '</span>'
          this.$nextTick(() => {
            this.searchTextWidth = el.clientWidth;
          })
        }
      }
    },
    // ОЧИСТКА ПОИСКОВОГО ЗАПРОСА И ЗНАЧЕНИЯ (ТОЛЬКО В ОДИНОЧНОМ РЕЖИМЕ)
    clearSearch() {
      if (this.selected) {
        this.clearSelected(this.selected);
        this.selected = null;
      }
      if (this.search) {
        if (this.open) {
          this.inputText("");
        } else {
          this.changeSearchText("");
          this.isSearchCleared = true;
        }
      }
    },
    /////////////////////////////////////////////////////////////////////////////////////////
    // БЛОК РАБОТЫ С ДАННЫМИ
    /////////////////////////////////////////////////////////////////////////////////////////
    setDisplayedOptions(isSavePointer) {
      // Выбираем функцию для фильтрации данных
      let hidden = this.hideSelected
        ? this.isMultiple && this.selected?.length > 0
          ? this.selected
          : !this.isMultiple && this.selected
          ? [this.selected]
          : null
        : null;
      if (hidden && !this.isStrings)
        hidden = hidden.map((item) => item[this.itemValue]);
      let isShowOption,
        isInternal =
          this.isInternalSearch &&
          this.searchable &&
          // && !(!this.isMultiple && this.selected && this.isObjects)
          this.search;
      if (hidden && isInternal) {
        isShowOption = (option) =>
          this.getOptionDescription(option)
            .toLowerCase()
            .includes(this.search.toLowerCase()) &&
          !~hidden.indexOf(option[this.itemValue]);
      } else if (hidden) {
        isShowOption = (option) => !~hidden.indexOf(option[this.itemValue]);
      } else if (isInternal) {
        isShowOption = (option) =>
          this.getOptionDescription(option)
            .toLowerCase()
            .includes(this.search.toLowerCase());
      }
      // Функция для подсветки выделенного
      let highlightHtml = (item) => {
        if (!item[this.itemOrig])
          item[this.itemOrig] = item[this.itemDescription];
        let label = this.getOptionDescription(item);
        item[this.itemDescription] = !this.search
          ? label
          : label.replace(
              new RegExp(this.search, "ig"),
              (val) => `<b class="find-text">${val}</b>`
            );
        return item;
      };
      // Обработка списка элементов
      let displayed = this.allItems;
      if (this.isGroups) {
        displayed = displayed
          .map((group) => {
            let filtered = group[this.groupValues];
            // Отфильтровал позиции по запросу, если нужно
            if (isShowOption) filtered = filtered.filter(isShowOption);
            // Убрал скрытые, если они есть
            let length = filtered.length,
              collapsedLength = !group.isExtended && length - this.maxGroupCount > 0
                  ? length - this.maxGroupCount
                  : 0;
            if (!group.isExtended && length > this.maxGroupCount)
              filtered = filtered.slice(0, this.maxGroupCount);
            // Подсветил оставшиеся
            if (this.isInternalSearch && this.searchable) filtered = filtered.map(highlightHtml);
            let newGroup = {
              ...group,
              [this.groupValues]: filtered,
              collapsedLength: collapsedLength
            };
            return newGroup;
          })
          .filter((group) => group[this.groupValues].length)
          .map(group => {
            if (group._isHidden) group[this.groupValues] = [];
            return group;
          });
      } else {
        if (isShowOption)
          displayed = this.allItems.filter(isShowOption);
        if (this.isInternalSearch && this.searchable)
          displayed = displayed.map(highlightHtml);
      }
      if (this.suggestions.length) this.displayedSuggestions = this.suggestions;
      else this.displayedSuggestions = [];
      if (this.open) {
        this.isRerendering = true;
        setTimeout(() => {
          this.isRerendering = false;
        }, 20);
      }
      this.displayed = displayed;
      if (!this.showEmptyLastOption && this.showLastOption && this.search) {
        if (this.isGroups) {
          let isExactMatch;
          displayed.find(group => {
            isExactMatch = !!group.find(item => this.getOptionDescription(item) === this.search)
            if (isExactMatch) return true;
          })
          this.isExactMatch = isExactMatch || false;
        } else {
          this.isExactMatch = !!displayed.find(item => this.getOptionDescription(item) === this.search)
        }
      }
      if (!isSavePointer) this.toFirstPointer();
    },
    changeOpen(displayedGroup, groupInd) {
      let group = this.allItems.find(group => group[this.groupId] === displayedGroup[this.groupId]);
      if (group) {
        let isHidden = !displayedGroup._isHidden;
        if (group) this.$set(group, '_isHidden', isHidden);
        this.setDisplayedOptions();
      }
      this.$emit("openGroup", displayedGroup, groupInd);
    },
    nextPart() {
      if (this.isInternalSearch || this.isLoading) return;
      else if (this.pageNumber !== -1) {
        this.isLoading = true;
        let result = this.getItems(true);
        if (result) {
          result.then(() => {
            this.isLoading = false;
            if (this.open) {
              this.setDisplayedOptions(true);
            }
          })
          .catch(() => {
            this.isLoading = false;
          })
        } else this.isLoading = false;
      }
    },
    prepareItems(items) {
      if (!items?.length) return items;
      let allItems = items;
      // Если нужно строки превратить в объекты
      if (typeof allItems[0] === "string")
        allItems = allItems.map((item) => ({
          [this.itemDescription]: item,
          [this.itemValue]: item,
        }));
      // Если выбрана автогруппировка, применим её
      if (this.isAutoGrouping) {
        allItems = Object.entries(
          allItems.reduce((res, item) => {
            let itemGroupId = item[this.groupId] || "all";
            if (!res[itemGroupId]) {
              res[itemGroupId] = {
                description: item[this.groupDescription] || "Все",
                values: [],
              };
            }
            res[itemGroupId].values.push(item);
            return res;
          }, {})
        )
          .map(([groupId, { description, values }]) => ({
            [this.groupDescription]: description,
            [this.groupId]: groupId,
            [this.groupValues]: values,
            total: values.length,
            isExtended: false,
            collapsedLength:
              values.length > this.maxGroupCount
                ? values.length - this.maxGroupCount
                : 0,
          }))
          .sort(
            ({ description: description1 }, { description: description2 }) => {
              return description1.toUpperCase() > description2.toUpperCase()
                ? 1
                : description2.toUpperCase() > description1.toUpperCase()
                ? -1
                : 0;
            }
          );
      } else if (this.isGroups) {
        allItems.forEach((group) => {
          let length = group[this.groupValues].length;
          group.total = length;
          let oldGroup = this.allItems?.find(oldGroup => oldGroup[this.groupId] === group[this.groupId]);
          if (oldGroup) {
            if (oldGroup.isExtended) group.isExtended = true;
            if (oldGroup._isHidden) group._isHidden = true;
          }
        });
      }
      // Пометить в списке выбранные элементы
      if (this.selected) {
        let selected = this.isMultiple
          ? this.selected.slice()
          : [this.selected];
        if (!this.isStrings) selected = selected.map((selItem) => selItem[this.itemValue]);
        if (this.isGroups) {
          allItems.forEach((group) => {
            let isEnd = group[this.groupValues].find((item) => {
              let value = this.isStrings
                ? this.getOptionDescription(item)
                : item[this.itemValue];
              let index = selected.indexOf(value);
              if (~index) {
                item._isSelected = true;
                selected.splice(index, 1);
                if (!selected.length) return;
              }
            });
            if (isEnd) return;
          });
        } else {
          allItems.find((item) => {
            let value = this.isStrings
              ? this.getOptionDescription(item)
              : item[this.itemValue];
            let index = selected.indexOf(value);
            if (~index) {
              item._isSelected = true;
              selected.splice(index, 1);
              if (!selected.length) return;
            } else if (item._isSelected) {
              delete item._isSelected;
            }
          });
        }
      }
      return allItems;
    },
    getAllData(callback, isReload) {
      if (this.isLoading) {
        if (!this.isSearchChanged) this.isSearchChanged = true;
        return;
      } else {
        let items, sugs, result;
        this.isLoading = true;
        if (!this.hideOptions && (isReload ? this.link : !this.isInternalSearch || this.link && this.isBegin && !this.items?.length)) {
          this.pageNumber = 1;
          this.total = 0;
          items = this.getItems()
        }
        if (this.withSuggestions) sugs = this.getSuggestions()
        if (items && sugs) result = Promise.allSettled([items, sugs])
        else if (items && !sugs) result = items;
        else if (!items && sugs) result = sugs;
        if (result) {
          result.then(() => {
            if (this.open) {
              if (items) {
                this.setDisplayedOptions();
              } else {
                this.displayedSuggestions = this.suggestions;
              }
            }
            callback?.(true);
            this.isLoading = false;
            if (this.isSearchChanged) {
              this.isSearchChanged = false;
              this.getItemsWithDelay();
            }
          })
          .catch(() => {
            this.isLoading = false;
          })
        } else if (this.isInternalSearch) {
          if (this.open) {
            this.setDisplayedOptions();
          }
          callback?.(false)
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
      }
    },
    getItems(isNext) {
      if (isNext && !this.isPagination)
        return
      else if (this.search.length < this.minCount) {
        if (this.allItems.length) {
          this.allItems = [];
          this.$emit("update:items", this.allItems);
          if (this.open) this.setDisplayedOptions();
        }
        return;
      } else if (this.link) {
        let paginationParams = {
          pageSize: this.isPagination ? this.pageSize : 200,
          page: this.pageNumber,
          [this.filterKey]: this.search,
          ...(this.searchParams || {}),
        };
        return this.getItemsFunc ? this.getItemsFunc({filter: this.search}, isNext).then((body) => {
          if (body.isEnd) this.pageNumber = -1;
          if (body.data) {
            this.allItems = this.prepareItems(body.data)
            if (this.isBegin) this.isBegin = false;
          }
        }) : this.callAPI(
          "get",
          this.link,
          paginationParams,
          ({ body }) => {
            if (typeof body?.total === "number") this.total = body.total;
            // Если есть внешний коллбек к данным, используем его
            if (this.getItemsCallback)
              body.data = this.getItemsCallback(body.data || [], this.isBegin);
            if (!body?.data?.length && isNext) {
              // Если не пришли позиции, значит прошлая пачка была последняя
              this.pageNumber = -1;
            } else {
              let allItems = body.data;
              if (
                allItems.length < this.pageSize ||
                (this.total && this.pageNumber * this.pageSize >= this.total)
              ) {
                this.pageNumber = -1;
              } else {
                this.pageNumber++;
              }
              allItems = this.prepareItems(allItems);
              if (isNext) this.allItems = this.allItems.concat(allItems);
              else this.allItems = allItems;
              this.$emit("update:items", this.allItems);
              if (allItems?.length) {
                if (this.isBegin) {
                  let isChecked = this.checkFocused();
                  if (this.withDefault) {
                    // Можно использовать для более сложных в совокупности с getItemsCallback:
                    // в коллбеке прописать нужной позиции isDefault: true
                    if (this.isMultiple) {
                      let selected = this.allItems.filter(
                        ({ isDefault }) => isDefault
                      );
                      this.selected = selected.length ? selected : null;
                      if (this.selected) this.selected.forEach(item => this.$set(item, "_isSelected", true));
                    } else {
                      this.selected = this.allItems.find(
                        ({ isDefault }) => isDefault
                      ) || null;
                      if (this.selected) {
                        if (this.isFixedValue && isChecked) {
                          setTimeout(() => {
                            window.$(this.$refs.search).select();
                          }, 50);
                        }
                        this.$set(this.selected, "_isSelected", true);
                      }
                    }
                  } else if (this.firstIsActive) {
                    this.selected = this.isGroups
                      ? this.allItems[0]?.[this.groupValues]?.[0]
                      : this.allItems[0];
                    if (this.selected) {
                      if (this.isFixedValue && isChecked) {
                        setTimeout(() => {
                          window.$(this.$refs.search).select();
                        }, 50);
                      }
                      this.$set(this.selected, "_isSelected", true);
                    }
                  }
                  this.isBegin = false;
                }
              }
            }
          },
          () => {
            this.pageNumber = -1;
          }
        );
      }
    },
    getSuggestions() {
      if (this.search.length < 3) {
        this.suggestions = [];
        return;
      } else {
        let query =
          this.search && this.suggestionsRegExp
            ? this.search
                .replace(/\\/g, "/")
                .replace(new RegExp(this.suggestionsRegExp, "gi"), "")
            : this.search;
        return this.callAPI(
          "get",
          this.suggestionsLink,
          { query: query },
          (response) => {
            let suggestions = Array.isArray(response?.body?.suggestions)
              ? Object.values(
                  response.body.suggestions
                    .slice(0, 3)
                    .reduce((res, sug) => {
                      if (sug?.value) res[sug.value] = sug;
                      return res;
                    }, {})
                )
              : [];
            suggestions.forEach(sug => sug.htmlText = sug.value);
            this.suggestions = suggestions;
          }
        );
      }
    },
    /////////////////////////////////////////////////////////////////////////////////////////
    // БЛОК РАБОТЫ С УКАЗАТЕЛЕМ
    /////////////////////////////////////////////////////////////////////////////////////////
    toFirstPointer() {
      this.activePointer = null;
      if (!this.withSuggestions && !this.isMultiple && this.search && (this.displayed.length || this.isShowLastOption && this.isFixedValue)) {
        this.toNextOrPreviousPointer("next");
      }
    },
    setPointer(pointer) {
      if (!this.isRerendering) this.activePointer = pointer;
    },
    getOptionByPointer(pointer) {
      const pointers = [null, "first", "noResult", "last"];
      let option;
      if (typeof pointer === "string" && ~pointer.indexOf("sug.")) {
        const x = pointer.toString().split(".")[1];
        option = this.suggestions[x];
      } else if (
        pointers.indexOf(pointer) === -1 &&
        (typeof pointer !== "string" || !~pointer.indexOf("more"))
      ) {
        if (this.isGroups) {
          const [x, y] = pointer.toString().split(".");
          option = this.displayed[x][this.groupValues][y];
        } else {
          option = this.displayed[pointer];
        }
      }
      return option;
    },
    /////////////////////////////////////////////////////////////////////////////////////////
    // ВЫБОР / СНЯТИЕ ВЫБОРА ЗНАЧЕНИЙ
    /////////////////////////////////////////////////////////////////////////////////////////
    clearSelected(selected) {
      let option = selected;
      if (typeof option === "object") option = option[this.itemValue];
      // Нужно установить системное поле _isSelected в false
      let testFunction = this.isStrings
        ? (item) => this.getOptionDescription(item, true) === option
        : (item) => item[this.itemValue] === option;
      if (this.isGroups) {
        this.allItems.find((group) => {
          let item = group[this.groupValues].find(testFunction);
          if (item) this.$set(item, "_isSelected", false);
          return !!item;
        });
      } else {
        let item = this.allItems.find(testFunction);
        if (item?._isSelected) this.$set(item, "_isSelected", false);
      }
    },
    selectItem(option) {
      if (this.activeDisabled) return
      // const option = this.getOptionByPointer(pointer || this.activePointer),
      //   isSelected = this.isSelected(option);
      // if (!option) return;
      let item = option;
      if (this.isStrings) item = this.getOptionDescription(item, true);     
      if (this.resetAfter) this.$emit("select", item);
      else if (this.isMultiple && option._isSelected && !this.isRepeatable) {
        // В этом случае вместо селекта нужно сделать наоборот деселект
        if (this.selected)
          this.selected = this.selected.filter((selected) =>
            this.isStrings
              ? item !== selected
              : item[this.itemValue] !== selected[this.itemValue]
          );
        // if (this.selected) {
        //   let index = this.selected.findIndex(
        //     (item) =>
        //       this.isStrings ? item !== option : item[this.itemValue] !==
        //       option[this.itemValue]
        //   );
        //   if (~index) this.selected.splice(index, 1);
        // }

        this.$set(option, "_isSelected", false);
        this.$emit("updateValue", this.selected)
      } else if (
        this.isMultiple ||
        !this.selected ||
        (this.isStrings
          ? this.selected !== item
          : this.selected[this.itemValue] !== item[this.itemValue])
      ) {
        // В этом случае нужно выбрать элемент
        if (this.isMultiple && this.selected) {
          if (this.maxCount && this.selected.length >= this.maxCount) {
            this.showMessage(`Нельзя добавить больше ${this.maxCount} элементов`, "", "warn")
          }
          else {
            this.selected.push(item);
          }
        } else if (this.isMultiple) this.selected = [item];
        else {
          if (this.selected) this.clearSelected(this.selected);
          this.selected = item;
        }
        this.$set(option, "_isSelected", true);
        this.$emit("updateValue", this.selected)
      }
      if (this.isStrings) this.$emit("select-original", option);
      if (this.open) this.toggleDropdown();
      if (this.isFixedValue || this.isMultiple) {
        // Чтобы обновить данные при октрытии, а сейчас просто закрыть селект
        if (this.search && (!this.isInternalSearch || this.withSuggestions)) {
          this.isSearchCleared = true;
        }
        this.changeSearchText("");
        this.suggestions = [];
      } else {
        let newSearch = (this.isStrings ? this.selected : this.getOptionDescription(this.selected)) || "";
        if (newSearch !== this.search) {
          this.changeSearchText(newSearch || "");
          this.suggestions = [];
          if (!this.isInternalSearch || this.withSuggestions) this.isSearchCleared = true;
        }
      }
      if (!this.isMultiple && this.$refs.search) this.$refs.search.blur?.();
      if (this.toNextOnEnter && !this.isMultiple) this.toNextTabableElement();
      else if (!this.toNextOnEnter && !this.isMultiple) {
        let elem = this.maskFunction ? this.$refs.search.$el : this.$refs.search;
        elem.blur();
      }
    },
    deselectItem(index) {
      this.clearSelected(this.selected[index]);
      // Сама очистка
      if (this.selected.length > 1) {
        this.selected.splice(index, 1);
        this.$emit("updateValue", this.selected)
      } else this.selected = null;
    },
    /////////////////////////////////////////////////////////////////////////////////////////
    // ДРУГИЕ ДЕЙСТВИЯ
    /////////////////////////////////////////////////////////////////////////////////////////

    // КОГДА НАЖИМАЕШЬ НА ОДНУ ИЗ ПОДСКАЗОК
    chooseSuggestion(suggestion) {
      this.$emit("change:suggestion", suggestion);
      if (this.hideOptions) {
        if (this.open) this.toggleDropdown();
        this.changeSearchText(suggestion.value);
        this.isSearchCleared = true;
        if (!this.isFixedValue && !this.isMultiple) this.selected = suggestion.value;
      } else if (this.open) {
        this.inputText(suggestion.value);
      } else {
        this.changeSearchText(suggestion.value);
        this.isSearchCleared = true;
      }

      // this.toFirstPointer();
    },
    onLastOptionClick() {
      if (this.saveLink) {
        // this.callAPI(this.saveMethod, this.saveLink, {[this.saveField]: this.search}, response => {
        //   if (response.body)
        // })
      } else {
        this.$emit("last", this.search);
        if (this.open && (this.isMultiple || !this.selected || this.isFixedValue)) {
          this.toggleDropdown();
          if (this.search) {
            this.changeSearchText("");
            this.isSearchCleared = true;
            this.suggestions = [];
          }
        }
      }
    },
    showMore(groupInd) {
      let groupId = this.displayed[groupInd][this.groupId];
      let group = this.allItems.find(group => group[this.groupId] === groupId);
      if (group) {
        this.$set(group, "isExtended", true);
        this.setDisplayedOptions(true);
        this.setPointer(`${groupInd}.${this.maxGroupCount}`)
      }
    },
    clickBtn() {
      this.$emit("clickBtn", this.selected, this.searchPlaceholder.trim());
      if (this.open && !this.selected) {
        this.toggleDropdown();
        if (this.search) {
          this.changeSearchText("");
          this.isSearchCleared = true;
          this.suggestions = [];
        }
      }
    },
    /////////////////////////////////////////////////////////////////////////////////////////
    // БЛОК РАБОТЫ С КЛАВИШАМИ
    /////////////////////////////////////////////////////////////////////////////////////////
    typeTopKey() {
      this.typedArrowKey("previous");
    },
    typeBottomKey() {
      this.typedArrowKey("next");
    },
    typedArrowKey(type) {
      if (!this.open && this.isSearchCleared) {
        this.reloadNow(() => {
          if (this.withDropdownSearch && document.activeElement !== this.$refs.dsearch) {
            setTimeout(() => {
              window.$(this.$refs.dsearch).select();
            }, 50);
          }
        })
      } else if (!this.open) {
        if (!this.hideOptions || this.suggestions.length) {
          this.setDisplayedOptions();
          this.openIfTextChanged();
          if (this.withDropdownSearch && document.activeElement !== this.$refs.dsearch) {
            setTimeout(() => {
              window.$(this.$refs.dsearch).select();
            }, 50);
          }
        }
      } else {
        this.toNextOrPreviousPointer(type);
      }
    },
    toNextOrPreviousPointer(type) {
      let pointer;
      if (type === "next") {
        if (this.activePointer === "last") {
          return;
        } else if (this.activePointer === null) {
          pointer =
            this.withSuggestions && this.suggestions.length
              ? "sug.0"
              : this.displayed.length === 0 || (this.isGroups && !this.displayed.find(group => !group._isHidden))
              ? this.isShowLastOption
                ? "last"
                : undefined
              : this.isGroups
              ? `${this.displayed.findIndex(group => !group._isHidden)}.0`
              : 0;
        } else if (
          typeof this.activePointer === "string" &&
          ~this.activePointer.indexOf("sug")
        ) {
          let index = Number(this.activePointer.replace("sug.", ""));
          pointer =
            index < this.suggestions.length - 1
              ? `sug.${index + 1}`
              : this.displayed.length === 0 || (this.isGroups && !this.displayed.find(group => !group._isHidden))
              ? this.isShowLastOption
                ? "last"
                : undefined
              : this.isGroups
              ? `${this.displayed.findIndex(group => !group._isHidden)}.0`
              : 0;
        } else if (this.activePointer === "noResult") {
          if (this.isShowLastOption) pointer = "last";
        } else if (typeof this.activePointer === "number") {
          pointer =
            this.activePointer < this.displayed.length - 1
              ? this.activePointer + 1
              : this.isShowLastOption
              ? "last"
              : undefined;
        } else if (
          typeof this.activePointer === "string" &&
          ~this.activePointer.indexOf(".more")
        ) {
          let index = Number(this.activePointer.replace(".more", ""));
          pointer =
            index < this.displayed.length - 1 && this.displayed.slice(index + 1).find(group => !group._isHidden)
              ? `${index + 1 + this.displayed.slice(index + 1).findIndex(group => !group._isHidden)}.0`
              : this.isShowLastOption
              ? "last"
              : undefined;
        } else if (typeof this.activePointer === "string") {
          let [x, y] = this.activePointer.split(".").map(Number);
          pointer =
            !this.displayed[x].isExtended &&
            y === this.maxGroupCount - 1 &&
            this.displayed[x][this.groupValues].length > this.maxGroupCount
              ? `${x}.more`
              : y < this.displayed[x][this.groupValues].length - 1 && !this.displayed[x]._isHidden
              ? `${x}.${y + 1}`
              : x < this.displayed.length - 1 && this.displayed.slice(x + 1).find(group => !group._isHidden)
              ? `${x + 1 + this.displayed.slice(x + 1).findIndex(group => !group._isHidden)}.0`
              : this.isShowLastOption
              ? "last"
              : undefined;
        }
      } else {
        if (this.activePointer === null) {
          return;
        } else if (
          typeof this.activePointer === "string" &&
          ~this.activePointer.indexOf("sug")
        ) {
          let index = Number(this.activePointer.replace("sug.", ""));
          pointer = index > 0 ? `sug.${index - 1}` : null;
        } else if (this.activePointer === "noResult") {
          pointer =
            this.withSuggestions && this.suggestions.length
              ? `sug.${this.suggestions.length - 1}`
              : null;
        } else if (typeof this.activePointer === "number") {
          pointer =
            this.activePointer > 0
              ? this.activePointer - 1
              : this.withSuggestions && this.suggestions.length
              ? `sug.${this.suggestions.length - 1}`
              : null;
        } else if (
          typeof this.activePointer === "string" &&
          ~this.activePointer.indexOf(".more")
        ) {
          let index = Number(this.activePointer.replace(".more", ""));
          pointer = `${index}.${this.maxGroupCount - 1}`;
        } else if (this.activePointer === "last") {
          let index = this.displayed.length && this.isGroups
            ? this.displayed.reverse().findIndex(group => !group._isHidden)
            : -1;
          if (~index) index = this.displayed.length - 1 - index;
          pointer =
            ~index
              ? this.displayed[index].isExtended ||
                this.displayed[index][this.groupValues]
                  .length <= this.maxGroupCount
                ? `${index}.${this.displayed[
                    index
                  ][this.groupValues].length - 1}`
                : `${index}.more`
              : this.displayed.length && !this.isGroups
              ? this.displayed.length - 1
              : this.withSuggestions && this.suggestions.length
              ? `sug.${this.suggestions.length - 1}`
              : null;
        } else if (typeof this.activePointer === "string") {
          let [x, y] = this.activePointer.split(".").map(Number);
          let index = y === 0 && x > 0
            ? this.displayed.slice(0, x).reverse().findIndex(group => !group._isHidden)
            : -1;
          if (~index) index = this.displayed.slice(0, x).length - 1 - index;
          pointer =
            y > 0
              ? `${x}.${y - 1}`
              : x > 0 && ~index
              ? this.displayed[index].isExtended ||
                this.displayed[index][this.groupValues].length <=
                  this.maxGroupCount
                ? `${index}.${this.displayed[index][this.groupValues].length -
                    1}`
                : `${index}.more`
              : this.withSuggestions && this.suggestions.length
              ? `sug.${this.suggestions.length - 1}`
              : null;
        }
      }
      if (pointer !== undefined) {
        this.activePointer = pointer;
        this.scrollTo(type);
      }
    },
    // СКРОЛЛ К НОВОМУ ВЫБРАННОМУ ЭЛЕМЕНТУ
    scrollTo(type) {
      this.isRerendering = true;
      this.$nextTick(() => {
        const el = this.$refs.dropdown?.querySelector("li.highlight");
        if (this.activePointer === null && this.topPosition) {
          this.topPosition = 0;
          return;
        } else if (!el || this.activePointer === "last") return;
        // При переходе снизу к первой позиции, нужно поднять скроллинг в самый верх, захвитив заговки
        let height = window.$(this.$refs.dropdown).height();
        if (
          type === "previous" &&
          this.topPosition != 0 &&
          (this.activePointer === null || this.activePointer === "sug.0" ||
            ((!this.withSuggestions || !this.suggestions.length) &&
              this.activePointer === (this.isGroups ? "0.0" : 0)))
        ) {
          this.topPosition = 0;
        } else if (
          (type === "next" &&
            this.topPosition < el.offsetTop + el.offsetHeight - height) ||
          (type === "previous" &&
            this.topPosition + height < el.offsetTop + el.offsetHeight)
        ) {
          this.topPosition = el.offsetTop + el.offsetHeight - height;
        } else if (type === "previous" && this.topPosition > el.offsetTop) {
          this.topPosition = el.offsetTop;
        }
        setTimeout(() => (this.isRerendering = false), 50);
      });
    },
    // ДОПОЛНИТЬ ТЕКСТ ДО АКТИВНОЙ ПОДСКАЗКИ; РАЗВЕРНУТЬ ГРУППУ, СПИСОК ПОДСКАЗОК ИЛИ ОПЦИЙ
    typeRightKey() {
      if (this.hintMessage) {
        this.inputText(this.hintMessage);
      } else if (
        typeof this.activePointer === "string" &&
        ~this.activePointer.indexOf("more")
      ) {
        const groupInd = Number(this.activePointer.replace(".more", ""));
        this.showMore(groupInd);
      } else if (this.searchPlaceholder !== this.search) {
        // КОГДА ПОИНТЕР НА ПОДСКАЗКЕ В СПИСКЕ
        this.inputText(this.searchPlaceholder);
      }
    },
    typeEnterKey(ev) {
      if (this.isPreventEnter) ev.preventDefault()
      if (
        this.code.length > 3 &&
        Date.now() - this.timestamp < this.scannerSensitivity
      ) {
        const code = toSerialCode(this.code)
        this.$emit("barcode", this.code, code);
        this.code = "";
        if (this.resetAfter) this.changeSearchText("");
        else if (this.withAutoCode) this.inputText(code)
        return;
      }
      if (this.activePointer === null) {
        let search = this.search;
        if (this.search && this.isMultiple) this.addOption();
        if (this.open) this.toggleDropdown();
        // if (this.isMultiple && !this.isFixedValue) this.addOption();
        // else if (!this.isMultiple && this.open) this.toggleDropdown();
        // !!!
        if (this.toNextOnEnter && (!this.isMultiple || !search)) {
          this.toNextTabableElement();
        } else if (!this.toNextOnEnter && !this.isMultiple) {
          let elem = this.maskFunction ? this.$refs.search.$el : this.$refs.search;
          elem.blur();
        }
      } else if (
        typeof this.activePointer === "string" &&
        ~this.activePointer.indexOf("more")
      ) {
        const groupInd = Number(this.activePointer.replace(".more", ""));
        this.showMore(groupInd);
      } else if (
        typeof this.activePointer === "string" &&
        ~this.activePointer.indexOf("sug")
      ) {
        let suggestion = this.getOptionByPointer(this.activePointer);
        this.toggleDropdown();
        this.chooseSuggestion(suggestion);
      } else if (this.activePointer === "last") {
        this.onLastOptionClick();
        if (this.toNextOnEnter && !this.isMultiple) this.toNextTabableElement();
        else if (!this.toNextOnEnter && !this.isMultiple) {
          let elem = this.maskFunction ? this.$refs.search.$el : this.$refs.search;
          elem.blur();
        }
      } else {
        this.selectItem(this.getOptionByPointer(this.activePointer));
      }
      // if (this.toNextOnEnter && this.)
    },
    addOption() {
      if (this.isMultiple) {
        let option = this.isStrings
          ? this.search
          : {
              [this.itemDescription]: this.search,
              [this.itemOrig]: this.search,
              [this.itemValue]: this.search,
            };
        if (this.selected) {
          if (this.maxCount && this.selected.length >= this.maxCount) {
            this.showMessage(`Нельзя добавить больше ${this.maxCount} элементов`, "", "warn")
          }
          else {
            this.selected.push(option);
          }
        } else this.selected = [option];
        this.changeSearchText("");
        this.suggestions = [];
        if (!this.isInternalSearch && this.withSuggestions) this.isSearchCleared = true;
        this.$emit("updateValue", this.selected)
      }
    },
    typeTabKey() {},
    typeDeleteKey() {
      if (this.isMultiple) {
        if (!this.search && this.selected?.length) {
          this.deselectItem(this.selected.length - 1);
        }
      }
      // else if (
      //   this.selected &&
      //   !this.isFixedValue &&
      //   typeof selected === "object"
      // ) {
      //   this.deselect();
      // }
    },
    typeEscKey() {
      let open = this.open;
      if (open) this.toggleDropdown();
      if ((this.isMultiple || !this.selected) && this.search) {
        this.changeSearchText("");
        this.suggestions = [];
        if (open && (!this.isInternalSearch || this.withSuggestions)) this.isSearchCleared = true;
      }
    },
    typeAnyKey(event) {
      if (!event || event.key === "Enter" || event.key === "Escape" || event.key === "Shift" || event.key === "Alt" || event.key === "Meta") {
        return;
      }
      let timestamp = Date.now();
      if (event.key && checkSymbol(event.key)) {
        if (
          this.code &&
          this.timestamp &&
          timestamp - this.timestamp < this.scannerSensitivity
        ) this.code += event.key;
        else this.code = event.key;
        this.timestamp = timestamp;
      } else {
        this.code = ""
        this.timestamp = null
      }
    },
    /////////////////////////////////////////////////////////////////////////////////////////
    // ПЕРЕХОД К СЛЕДУЮЩЕМУ СЕЛЕКТУ ПРИ ВЫБОРЕ ЗНАЧЕНИЯ
    /////////////////////////////////////////////////////////////////////////////////////////
    toNextTabableElement() {
      let elem = this.$refs.search
        ? this.maskFunction
          ? this.$refs.search.$el
          : this.$refs.search
        : null;
      if (!elem) return;
      let tabbables = document.querySelectorAll(
        ".tabable:enabled, .date-input .field-input"
      );
      let oldIndex = [...tabbables].findIndex(el => el === elem);
      setTimeout(() => {
        tabbables = document.querySelectorAll(
          ".tabable:enabled, .date-input .field-input"
        );
        elem = this.$refs.search
          ? this.maskFunction
            ? this.$refs.search.$el
            : this.$refs.search
          : null;
        if (elem) {
          for (var i = 0; i < tabbables.length - 1; i++) {
            if (tabbables[i] == elem) {
              window.$(tabbables[i + 1]).focus();
              return;
            }
          }
        } else if (~oldIndex) {
          window.$(tabbables[oldIndex]).focus();
        }
      }, 150);
    },
    getOptionDescription(option, isSelect) {
      return isSelect && this.maxLength
        ? (option[this.itemOrig] || option[this.itemDescription]).slice(0, this.maxLength)
        : option[this.itemOrig] || option[this.itemDescription];
    },
  },
};
</script>

<style lang="scss" scoped>
.select {
  position: relative;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  &__sug,
  &__item {
    cursor: pointer;
    padding: 4px;
    padding-left: 20px;
    &.first,
    &--last {
      border-top: 1px solid var(--aside-bg-80);
      padding-bottom: 5px;
    }
    .first-option,
    .last-option {
      color: #186999;
    }
    // &.highlight .first-option,
    // &.highlight .last-option {
    //   color: #f88001;
    // }
    &--selected {
      // color: #186999;
    }
  }
  li.highlight, div.highlight {
    background-color: var(--other-dropdown-row-bg-hover);
  }
  li.no-result-option {
    color: #999;
  }

  &__title {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    // flex-direction: row;
    align-items: center;
    // height: 100%;
    width: 100%;
    .st__req {
      color: var(--color-danger);
      position: absolute;
      top: 6px;
      left: 3px;
      z-index: 1;
      font-size: 13px;
    }
    &:hover {
      .st__clear {
        display: flex;
      }
      .st__clear + .st__arrow {
        display: none;
      }
    }
    .st {
      &__tag {
        position: relative;
        border: 1px solid var(--color-info);
        background-color: var(--color-info-15-op);
        border-radius: 4px;
        margin: 4px 0px 4px 6px;
        // margin-left: 6px;
        padding: 1px 0 1px 0.25em;
        line-height: 21px;
        max-width: calc(100% - 12px);
        z-index: 3;
        display: inline-flex;
        word-wrap: break-word;
        white-space: pre-line;
        align-items: flex-start;
        &__delete {
          display: inline-block;
          line-height: 19.5px;
          font-size: 23px;
          align-items: center;
          cursor: pointer;
          color: #fa4f4f;
          z-index: 3;
          padding: 0 3px;
          padding-top: 1.5px;
        }
      }
      &__input {
        // line-height: 1.42857143;
        // line-height: 1em;
        // font-size: 1em;
        // height: var(--input-height);
        border: 0;
        outline: none;
        margin: 0;
        // padding: 5px 9px 5px 12px;
        background: none;
        box-shadow: none;
        z-index: 2;
        font-weight: var(--input-fw);
        width: 10px;
        max-width: 100%;
        flex: 1 1;
        order: 1;
        // flex-grow: 1;
        // flex-shrink: 1;
        // &::-webkit-input-placeholder,
        // &::-moz-placeholder,
        // &:-ms-input-placeholder,
        // &:-moz-placeholder,
        // &::placeholder {
        //   color: rgb(150, 150, 150);
        //   font-weight: normal;
        //   font-size: 13px;
        // }
        &:hover ~ .st__background {
          border-color: var(--input-hover-border);
          background-color: var(--input-hover-bg);
        }
        &:focus ~ .st__background {
          border-color: var(--input-focus-border);
          background-color: var(--input-focus-bg);
        }
        &--hidden {
          // display: inline-block;
          visibility: hidden;
          position: absolute;
          height: 0;
          border: 0;
          padding: 0;
          &--normal {
            width: auto;
            max-width: 100%;
            overflow: auto;
          }
        }
        &:focus + .st__suggestion {
          display: block;
        }
      }
      &__suggestion {
        position: absolute;
        display: none;
        z-index: 1;
        left: 0;
        height: 100%;
        width: 100%;
        overflow: hidden;
        &--hint.st__input {
          width: 100%;
          overflow: hidden;
          margin: 0;
          padding: 0;
          border: 0;
          line-height: var(--input-height);
          &::-webkit-input-placeholder,
          &::-moz-placeholder,
          &:-ms-input-placeholder,
          &:-moz-placeholder,
          &::placeholder {
            // font-weight: 600;
            // font-size: 1em;
          }
        }
      }
      &__btn {
        flex: 0 0 25px;
        display: flex;
        align-self: stretch;
        align-items: center;
        justify-content: center;
        padding: 0 6px;
        background-color: transparent;
        border: 0;
        outline: 0;
        font-size: 18px;
        z-index: 2;
        order: 2;
        cursor: pointer;
        &__icon {
          font-size: 14px;
        }
        & + .st__clear {
          margin-right: 25px;
        }
        & ~ .st__arrow {
          display: none;
        }
        & + .st__arrow + .st__input {
          padding-right: 9px;
        }
        & ~ .st__input {
          max-width: calc(100% - 25px);
        }
        // &:focus:active {
        //   border-color: #3ec5ff;
        //   background-color: #ecfcff;
        // }
      }
      &__clear {
        margin-right: 2px;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        display: none;
        align-items: center;
        cursor: pointer;
        font-size: 18px;
        color: #fa4f4f;
        z-index: 3;
        padding: 0 6px;
        & + .st__input {
          padding-right: 20px;
        }
      }
      &__arrow {
        width: 0;
        height: 0;
        display: block;
        position: absolute;
        z-index: 3;

        right: 7px;
        top: calc(50% - 2px);
        bottom: 0;
        margin-right: 2px;

        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid #909090;
        & + .st__input {
          padding-right: 20px;
        }
      }
      &__background {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        // background-color: var(--input-bg);
        // border: 1px solid #bfbfbf;
        // border: 1px solid var(--input-border);
        // border-radius: var(--input-border-radius);
      }
      &--fixed {
        cursor: pointer;
        & + .st__background,
        &:focus + .st__background {
          background-color: var(--input-selected-bg);
          border-color: var(--input-selected-border);
          box-shadow: var(--input-selected-shadow);
        }
        &:hover + .st__background, &:hover:focus + .st__background  {
          // background-color: #e6ebf1;
          // background-image: linear-gradient(-180deg,#f0f3f6,#e6ebf1 90%);
          background-color: var(--input-hover-selected-bg);
          border-color: var(--input-hover-selected-border);
        }
      }
    }
    &--disabled {
      width: 100%;
      min-height: var(--input-height);
      background-color: var(--input-disabled-bg);
      border: 1px solid var(--input-disabled-border);
      box-shadow: var(--input-disabled-shadow);
      font-weight: var(--input-disabled-fw);
      border-radius: var(--input-border-radius);
      cursor: not-allowed;
      display: flex;
      align-items: center;
      .st__tags {
        width: 100%;
      }
      .st__tag {
        position: relative;
        border: 1px solid var(--color-info);
        background-color: var(--color-info-15-op);
        border-radius: 4px;
        margin: 3px 0px 3px 6px;
        padding: 1px 0.25em;
        padding-right: 20px;
        line-height: 21px;
        max-width: calc(100% - 12px);
        z-index: 3;
        flex: 0 0;
        display: inline-block;
        // flex-grow: 0;
        // flex-shrink: 0;
      }
      .disabled-label {
        width: 100%;
        height: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        // padding: 0 9px 0 12px;
        padding: var(--input-padding);
        white-space: nowrap;
      }
      .st__btn {
        flex: 0 0 25px;
        display: flex;
        align-self: stretch;
        align-items: center;
        justify-content: center;
        padding: 0 6px;
        background-color: transparent;
        border: 0;
        outline: 0;
        font-size: 18px;
        z-index: 2;
        order: 2;
        cursor: pointer;
        &__icon {
          font-size: 14px;
        }
        & + .st__clear {
          margin-right: 25px;
        }
        & ~ .st__arrow {
          display: none;
        }
        & ~ .st__input {
          max-width: calc(100% - 25px);
        }
        // &:focus:active {
        //   border-color: #3ec5ff;
        //   background-color: #ecfcff;
        // }
      }
    }
  }
  &--opened .select__title .st--fixed + .st__background, &--opened .select__title .st--fixed:hover + .st__background  {
    background-color: var(--input-focus-selected-bg);
    border-color: var(--input-focus-selected-border);
  }
  &__list {
    position: absolute;
    // overflow: auto;
    background-color: var(--dropdown-bg);
    z-index: 999;
    border: var(--main-dropdown-border);
    border-radius: var(--input-border-radius);
    padding: 2px 0;
    min-width: 100%;
    // box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.2);
    box-shadow: var(--other-dropdown-shadow-1);
    text-align: left;
    // top: calc(100% - 1px);
    font-size: 13px;
    display: flex;
    flex-direction: column;
    // ??
    // styles.borderTop = "none";
    // styles.borderBottom = "none";
    // styles.boxShadow = "none";
    &--top {
      bottom: 100%;
      flex-direction: column-reverse;
    }
    &--left {
      left: 0;
    }
    &--right {
      right: 0;
    }
    // .sl-scroll-wrapper {
    &.mh-80::v-deep .__panel {
      max-height: 80px;
    }
    &.mh-100::v-deep .__panel {
      max-height: 100px;
    }
    &.mh-190::v-deep .__panel {
      max-height: 190px;
    }
    &.mh-200::v-deep .__panel {
      max-height: 200px;
    }
    &.mh-250::v-deep .__panel {
      max-height: 250px;
    }
    // }
    .sl__input {
      // line-height: 1em;
      // font-size: 1em;
      // height: var(--input-height);
      border: 0;
      margin: 0;
      padding: 5px 9px 5px 7px;
      font-weight: 600;
      width: 100%;
      border-radius: 0;
      outline: none;
      // Как в планфиксе
      border: 1px solid #bbb;
      background-color: var(--input-bg);
      border: 1px solid var(--input-border);
      border-radius: var(--input-border-radius);
      margin: 5px 12px;
      width: calc(100% - 24px);
      // &::-webkit-input-placeholder,
      // &::-moz-placeholder,
      // &:-ms-input-placeholder,
      // &:-moz-placeholder,
      // &::placeholder {
      //   color: rgb(150, 150, 150);
      //   font-weight: normal;
      //   font-size: 13px;
      // }
    }
    // .sl__clear {
    //   margin-right: 2px;
    //   position: absolute;
    //   right: 0;
    //   top: 3px;
    //   bottom: 0;
    //   align-items: center;
    //   cursor: pointer;
    //   font-size: 18px;
    //   color: #fa4f4f;
    //   z-index: 3;
    //   padding: 0 4px;
    //   & + .sl__input {
    //     padding-right: 20px;
    //   }
    // }
    .sl__search {
      margin-right: 2px;
      position: absolute;
      right: 12px;
      top: 13px;
      align-items: center;
      cursor: pointer;
      font-size: 15px;
      color: #bbb;
      z-index: 3;
      padding: 0 4px;
      & + .sl__input {
        padding-right: 25px;
      }
    }
    .group-description {
      position: sticky;
      top: 0;
      // color: var(--main-color);
      margin-bottom: 0;
      padding: 7px 25px 0 7px;
      font-weight: 700;
      text-transform: uppercase;
      background-color: var(--dropdown-bg);
      z-index: 1;
      &.group-description--empty {
        color: #999;
      }
      cursor: pointer;
      &__icon {
        position: absolute;
        top: 12px;
        right: 17px;
        cursor: pointer;
        width: 0;
        height: 0;
        display: block;
        z-index: 10;
        border-right: none;
        border-left: 4px solid #909090;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        &--open {
          top: 14px;
          right: 15px;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 4px solid #909090;
          border-bottom: none;
        }
      }
    }
    .suggestions--more {
      margin-top: 3px;
      margin-bottom: 6px;
      color: var(--color-primary);
      border-bottom: 1px solid var(--color-primary);
      display: inline-block;
      cursor: pointer;
      white-space: nowrap;
    }
    .group--hr {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
  &.select--validation {
    .select__title {
      .st__background {
        border-color: var(--input-validate-border);
      }
      .st__input {
        &::-webkit-input-placeholder,
        &::-moz-placeholder,
        &:-ms-input-placeholder,
        &:-moz-placeholder,
        &::placeholder {
          color: var(--input-validate-color);
        }
        &:focus {
          & ~ .st__background {
            border-color: var(--input-validate-border);
          }
        }
      }
      .right-btn {
        color: var(--input-validate-color);
      }
    }
  }
}
.disappear-leave-active {
  transition: opacity .1s;
}
.disappear-leave-to {
  opacity: 0;
}
</style>
